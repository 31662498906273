import React from 'react';
import PageContainer from 'components/molecules/PageContainer';
import AccountSettings from 'components/templates/AccountSettings';
import PannelHelp from 'components/templates/PannelHelp';

export default () => (
  <PageContainer>
    <AccountSettings />
    <PannelHelp />
  </PageContainer>
);
