import styled from 'styled-components';
import colors from 'modules/colors';
import { Flex } from '@rebass/grid/emotion';

export const Footer = styled.footer`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;

  width: 100%;
  color: ${() => colors.white};
  background-color: ${() => colors.blueAliro};
`;

export const Col6 = styled(Flex)`
  padding: 33px 20px;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 435px;
`;

export const Nav = styled.nav`
  align-self: flex-start;
`;

export const List = styled.ul`
  display: flex;
  width: 100%;
`;

export const ItemList = styled.li`
  padding-right: 24px;
`;

export const Link = styled.a`
  color: #fff;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
`;

export const Text = styled.p`
  color: #fff;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
`;