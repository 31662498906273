import React from 'react';
import AccountContainer from 'components/organisms/AccountContainer';
import PageTitle from 'components/molecules/PageTitle';
import AccountInformation from 'components/organisms/AccountInformation';

const AccountSettings = () => (
  <AccountContainer>
    <PageTitle title="Account Settings" icon="package" />
    <AccountInformation />
  </AccountContainer>
);

export default AccountSettings;
