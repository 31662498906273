import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  width: 100%;
  max-width: 433px;
  margin: 0 auto;

  @media (max-width: 39.99em) {
    justify-content: flex-start;
  }
`;
