import styled from 'styled-components';
import colors from 'modules/colors';

export const input = () => `
  display: block;
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 0;
  padding: 0 10px;
  font-weight: 500;
  line-height: 30px;
  background: rgba(255, 223, 142, 0.1);
  // background: lightgreen;
  margin-right: 11px;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 5.5px;
  border-bottom: 1px solid ${() => colors.grey};
  margin-bottom: ${({ customStyle }) => (customStyle && customStyle.margin ? customStyle.margin : '10px')};

  &:last-child {
    margin-bottom: 0;
  }

  > div, .react-tel-input {
    width: 100%;
  }

  .algolia-places {
    margin-right: 11px;
    display: block !important;
  }

  .algolia-places__input, .react-tel-input input[type="tel"], .input-date {
    ${input}
    color: ${() => colors.black};
    font-size: ${({ customStyle }) => (customStyle && customStyle.fontSize ? customStyle.fontSize : '14px')};
    border-color: ${() => colors.red};
    border-width: 1px;
    border-style: ${({ errors, name }) => (errors && errors[name] ? 'solid' : 'none')};
  }

  .react-tel-input input[type="tel"] {
    padding-left: 45px;
    border-radius: 0;
    margin-right: 11px;
  }

  .react-tel-input {
    margin-right: 11px
  }

  .react-tel-input .flag-dropdown {
    background-color: transparent;
    border: none;
  }

  .ap-icon-pin {
    display: none
  }

  .algolia-places input {
    ${input}
    padding: 0 30px 0 10px;
    white-space: nowrap;
    overflow: hidden;
    border-color: ${() => colors.red};
    border-width: 1px;
    border-style: ${({ errors, name }) => (errors && errors[name] ? 'solid' : 'none')};
  }

  > div {
    margin-right: 11px;
  }

  .ap-icon-pin {
    display: none
  }

  &.isCode {
    label {
      font-size: 12px;
      width: auto;
      min-width: auto;
    }

    span {
      margin-left: 7px;
      margin-right: 9px;
    }

    input {
      font-size: 12px;
      line-height: 15px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Label = styled.label`
  font-size: ${({ customStyle }) => (customStyle && customStyle.fontSize ? customStyle.fontSize : '14px')};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: ${({ customStyle }) => (customStyle && customStyle.lineHeight ? customStyle.lineHeight : '17px')};
  color: ${() => colors.blueDark};
  width: ${({ customStyle }) => (customStyle && customStyle.width ? customStyle.width : '162px')};
  min-width: 108px;
  text-align: left;
  padding-right: ${({ customStyle }) => (customStyle && customStyle.paddingRight ? customStyle.paddingRight : 0)};

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Border = styled.span`
  height: 17px;
  width: 1px;
  border-left: 1px solid ${() => colors.greyDark};
  margin-left: ${({ customStyle }) => (customStyle && customStyle.marginLeft ? customStyle.marginLeft : '21px')};
  margin-right: 11px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const FormInputYellow = styled.input`
  ${input}
  color: ${() => colors.black};
  font-size: ${({ customStyle }) => (customStyle && customStyle.fontSize ? customStyle.fontSize : '14px')};
  border-color: ${() => colors.red};
  border-width: 1px;
  border-style: ${({ errors, name }) => (errors && errors[name] ? 'solid' : 'none')};
`;

export const Btn = styled.button`
  height: 23px;
  min-width: 53px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background-color: ${() => colors.yellow};
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: ${() => colors.blackLight};

  &:disabled {
    background-color: ${() => colors.grey};
  }
`;

export const Error = styled.div`
  position: absolute;
  left: calc(100% + 10px);
  width: fit-content !important;
  max-width: 160px;
  color: ${() => colors.error};
  background: ${() => colors.white};
  padding: 10px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid ${() => colors.grey};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  transition: 0.25s;

  @media (max-width: 1280px) {
    left: auto;
    top: calc(100% + 10px);
    right: 0;
    margin-right: 0 !important;
  }

  &:before {
    content: '';
    position: absolute;
    left: -5px;
    top: 12px;
    width: 8px;
    height: 8px;
    background-color: ${() => colors.white};
    border: 1px solid ${() => colors.grey};
    border-top-color: transparent;
    border-right-color: transparent;
    transform: rotate(45deg);

    @media (max-width: 1280px) {
      top: -5px;
      right: 10px;
      left: auto;
      border-bottom-color: transparent;
      border-top-color: ${() => colors.grey};
    }
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;
