import styled from 'styled-components';
import colors from 'modules/colors';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  padding-left: 30px;
`;

export const List = styled.ul`
  width: 100%;
  padding-left: 12px;
  list-style: none;
`;

export const SubList = styled.div`
  width: 100%;
  padding-left: 12px;
  list-style: none;
  padding-left: 30px;
`;

export const ListItem = styled(NavLink)`
  display: block;
  margin-top: 19px;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  color: ${({ locked }) => (locked === 'true' ? '#ccc' : colors.black)};
  cursor: pointer;
  font-weight: ${({ active }) => (active === 'true' ? '700' : '500')};
  padding-left: ${({ sub }) => (sub === 'true' ? '30px' : '12px')};
  pointer-events: ${({ locked }) => (locked === 'true' ? 'none' : 'auto')};

  .icon, .icon-status {
    margin-left: 10px;
    padding-top: 1px;
  }

`;

export const OnlyMobile = styled.div`
  position: absolute;
  top: 33.5px;
  right: 26px;

  @media (min-width: 64em) {
    display: none;
  }
`;
