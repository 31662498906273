import React, { useState } from 'react';
import InputAnswer from 'components/molecules/Form/InputAnswer';
import SingleChoice from 'components/molecules/Form/SingleChoice';
import ZipCode from 'components/molecules/Form/ZipCode';
import { InputGroup } from './style';

const USAddress = ({ data, type }) => {
  const defaultAddress = [
    {
      choices: [
        { value: '' },
        { value: '' },
        { value: '' },
        { value: '' },
        { value: '' },
        { value: '' },
        { value: '' },
        { value: '' },
        { value: '' },
        { value: '' },
        { value: '' },
        { value: '' },
        { value: '' },
      ],
    },
  ];
  const [address, setAddress] = useState(
    type === 'mailing'
      ? localStorage.getItem('mailingAddress')
        ? JSON.parse(localStorage.getItem('mailingAddress'))
        : defaultAddress
      : localStorage.getItem('address')
        ? JSON.parse(localStorage.getItem('address'))
        : defaultAddress,
  );

  let [updateView, setUpdateView] = useState(0);

  const handleClear = () => {
    setAddress(defaultAddress);
    setUpdateView(updateView++);
  }

  const handleChange = (val, index, suggestion) => {
    const { choices } = address[0];
    const choice = choices[index];
    choice.value = val;

    if (suggestion) {
      choices[5].value = suggestion.city;
      choices[6].value = suggestion.county;
      choices[7].value = suggestion.administrative;
      choices[8].value = suggestion.postcode;
    }

    setAddress(address);
    setUpdateView(updateView++);

    if (type === 'mailing') {
      localStorage.removeItem('mailingAddress');
      localStorage.setItem('mailingAddress', JSON.stringify(address));

      const localAddress = localStorage.getItem('address');
      if (!localAddress) localStorage.setItem('address', JSON.stringify([{
        choices: [
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
        ],
      }]));



    } else {
      localStorage.removeItem('address');
      localStorage.setItem('address', JSON.stringify(address));

      const localMailingAddress = localStorage.getItem('mailingAddress');
      if (!localMailingAddress) localStorage.setItem('mailingAddress', JSON.stringify([{
        choices: [
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
        ],
      }]));

    }

    setAddress(address);
    setUpdateView(updateView++);
  };

  return (
    <>
      <InputAnswer
        label="Street Number and Name"
        type="text"
        isAddress
        defaultValue={address && address[0] ? address[0].choices[0].value : ''}
        onChange={({ suggestion }) => {
          handleClear();
          handleChange(suggestion.name, 0, suggestion);
        }}
        onClear={() => {
          handleClear();
        }}
        customBorderStyle={{
          marginLeft: '14px',
        }}
        options={{
          appId: 'plCZD21OAEFL',
          apiKey: 'f47907e615cfbb4d0d7565be8e12546d',
          countries: ['us'],
          type: 'address',
          templates: {
            value: function(suggestion) {
              return suggestion.name;
            }
          },
        }}
        className="algolia-places__input"
        onSuggestions={(e) => {
          handleChange(e.query, 0);
        }}
        placeholder=""
      />
      <InputGroup pt={2} pb={12} mb={10}>
        <SingleChoice
          name={`type_${data.uid}`}
          value="apartment"
          title="Apartment"
          onChange={e => handleChange(e.target.value, 1)}
          optionSelected={address && address[0] ? address[0].choices[1].value : ''}
        />
        <SingleChoice
          name={`type_${data.uid}`}
          value="suite"
          title="Suite"
          onChange={e => handleChange(e.target.value, 2)}
          optionSelected={address && address[0] ? address[0].choices[2].value : ''}
        />
        <SingleChoice
          name={`type_${data.uid}`}
          value="floor"
          title="Floor"
          onChange={e => handleChange(e.target.value, 3)}
          optionSelected={address && address[0] ? address[0].choices[3].value : ''}
        />
      </InputGroup>
      <InputAnswer
        label="Unit"
        type="text"
        value={address && address[0] ? address[0].choices[4].value : ''}
        onChange={e => handleChange(e.target.value, 4)}
      />
      <InputAnswer
        label="City or Town"
        type="text"
        value={address && address[0] ? address[0].choices[5].value : ''}
        onChange={e => handleChange(e.target.value, 5)}
      />
      <InputAnswer
        label="County"
        type="text"
        value={address && address[0] ? address[0].choices[6].value : ''}
        onChange={e => handleChange(e.target.value, 6)}
      />
      <InputAnswer
        label="State"
        type="text"
        value={address && address[0] ? address[0].choices[7].value : ''}
        onChange={e => handleChange(e.target.value, 7)}
      />
      <ZipCode
        label="Zip Code +4"
        value={address && address[0] ? address[0].choices[8].value : ''}
        onInputChange={e => handleChange(e, 8)}
      />
    </>
  );
};

export default USAddress;
