import styled from 'styled-components';
import colors from 'modules/colors';
import { Link } from 'react-router-dom';
import { Flex } from '@rebass/grid';

export const Forgot = styled(Link)`
  display: block;
  text-align: right;
  margin-top: 13px;
  font-family: Helvetica;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: ${() => colors.white};
  margin-bottom: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

export const Text = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: ${() => colors.white};
  margin-bottom: 5px;
  text-align: left;
`;

export const Sign = styled(Flex)`
  &.sign {
    display: none;

    @media (max-width: 51.99em) {
      display: block;
    }
  }

  &.sign--modal {
    display: block;
  }
`;

export const SignUp = styled.p`
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  color: ${({ yellow }) => (yellow ? colors.yellow : colors.white)};
  cursor: pointer;
`;
