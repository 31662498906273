import React, { useState } from 'react';
import PannelList from 'components/molecules/Pannel/List';
import PannelListItem from 'components/molecules/Pannel/List/Item';
import { isPreviewPage } from 'modules/utils';
import { Container } from './style';

const ApplicationList = ({ applicationSlug, list }) => {
  const [isPreview] = useState(isPreviewPage());

  function handleIcon(status, locked) {
    let name;
    let width;
    let height;

    if (!status) {
      return null;
    }

    if (locked) return null;

    if (status === 'DONE') {
      name = 'progress-done';
      width = 16;
      height = 15;
    } else if (status !== 'UNSTARTED') {
      name = 'progress-wip';
      width = 12;
      height = 12;
    }

    return { name, width, height };
  }

  return (
    <Container>
      <PannelList name="Application" arrow>
        {list.map((group) => {
          const { search } = window.location;
          const { pathname } = window.location;

          const splitPath = pathname.split('/')[3];

          const groupSlug = group.description
            ? isPreview
              ? `/applications/${applicationSlug}/${group.slug}/preview${search}`
              : `/applications/${applicationSlug}/${group.slug}`
            : isPreview
              ? `/applications/${applicationSlug}/${group.slug}/${group.items[0]
                && group.items[0].slug}/preview${search}`
              : `/applications/${applicationSlug}/${group.slug}/${group.items[0]
                && group.items[0].slug}`;

          return (
            <div key={group.slug}>
              <PannelListItem
                to={groupSlug}
                value={group.title}
                active={`${pathname}${search}` === groupSlug || group.slug === splitPath}
                icon={handleIcon(group.status, group.locked)}
                locked={String(group.locked)}
                logic={group.logic}
              />

              {splitPath === group.slug
                && group.items.map((subGroup) => {
                  const subGroupSlug = isPreview
                    ? `/applications/${applicationSlug}/${group.slug}/${subGroup.slug}/preview${search}`
                    : `/applications/${applicationSlug}/${group.slug}/${subGroup.slug}`;

                  return (
                    !subGroup.locked && (
                      <PannelListItem
                        key={subGroup.slug}
                        to={subGroupSlug}
                        value={subGroup.title}
                        active={`${pathname}${search}` === subGroupSlug}
                        locked={String(subGroup.locked)}
                        sub
                        icon={handleIcon(subGroup.status)}
                      />
                    )
                  );
                })}
            </div>
          );
        })}
      </PannelList>
    </Container>
  );
};

export default ApplicationList;
