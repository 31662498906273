import styled from 'styled-components';

export const Svg = styled.svg`
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
  overflow: visible;

  &:hover {
    + .helper-text {
      opacity: 1;
      visibility: visible;
    }
  }
`;
