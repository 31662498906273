import styled from 'styled-components';
import colors from 'modules/colors';

export const Container = styled.div`
  width: 100%;
  max-width: 444px;
  margin: 0 auto;
`;

export const Title = styled.h2`
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: ${() => colors.black};
`;

export const SubTitle = styled.h2`
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: ${() => colors.blackLight};
`;

export const Description = styled.p`
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: ${() => colors.black};
`;

export const Email = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: ${() => colors.blueAliro};
`;

export const CodeContainer = styled.div`
  width: 100%;
  max-width: 357px;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
`;

export const CodeItem = styled.div`
  width: 65px;
  height: 65px;
  border: 2px solid ${() => colors.black};
  background-color: ${() => colors.white};
`;

export const CodeInput = styled.input`
  width: 61px;
  height: 61px;
  border: none;
  background-color: ${() => colors.white};
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: ${() => colors.black};
`;

export const SmallText = styled.p`
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${() => colors.black};
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    color: ${() => colors.blue};
    cursor: pointer;
  }
`;
