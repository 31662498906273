import React from 'react';
import Icon from 'components/atoms/Icon';
import { Flex } from '@rebass/grid/emotion';
import { Item } from './style';

const List = ({ name }) => (
  <Flex alignItems="center" width={[1, 1 / 2]} pb={17.5}>
    <Icon width={25} height={22} name="checkmark-yellow" />
    <Item>{name}</Item>
  </Flex>
);

export default List;
