import styled from 'styled-components';
import AmericanFlag from 'assets/images/american-flag.png';
import colors from 'modules/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 238px;
  height: 238px;
  background-image: ${() => `url(${AmericanFlag})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 39.99em) {
    display: none;
  }
`;

export const Title = styled.h1`
  font-size: 52px;
  font-weight: 700;
  line-height: 63px;
  text-align: center;
  text-transform: uppercase;
  color: ${() => colors.white};
  padding: 0 20px;

  @media (max-width: 39.99em) {
    font-size: 32px;
    line-height: 43px;
  }
`;
