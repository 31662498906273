import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from 'context/appContext';
import PageTitle from 'components/molecules/PageTitle';
import ContainerApp from 'components/molecules/ContainerApp';
import { Redirect } from 'react-router-dom';
import { isAuthenticated, getUrlGroups } from 'modules/utils';
import { Flex, Box } from '@rebass/grid/emotion';
import WhiteBox from 'components/molecules/WhiteBox';
import { useQuery, useMutation } from 'react-apollo-hooks';
import {
  GET_DOCUMENTS,
  GET_USER_DOCUMENTS,
  GET_APPLICATION_PANNEL,
} from 'graphql/application/queries';
import {
  UPLOAD_FILES,
  DELETE_FILES,
  LOCK_DOCUMENTS,
  GENERATE_APPLICATIONS,
} from 'graphql/application/mutations';
import Loader from 'components/atoms/Loader';
import { Contact } from 'components/organisms/Modals';
import Error from 'components/molecules/Error';
import Icon from 'components/atoms/Icon';
import { Heading, Desc, OpenModal } from '../Review/style.js';
import { SmallDesc } from './style.js';
import Button from '../../molecules/Button';
import MultipleChoice from '../../molecules/Form/MultipleChoice';
import colors from '../../../modules/colors';

const Documents = () => {
  const { dispatch } = useContext(AppContext);
  const path = getUrlGroups();
  const { data, refetch } = useQuery(GET_USER_DOCUMENTS, {
    variables: { slug: { inquirySlug: path.application } },
  });
  const { data: dataDocs, loading: loadingDocs } = useQuery(GET_DOCUMENTS, {
    variables: { slug: { inquirySlug: path.application } },
  });

  const { data: dataInquiry } = useQuery(GET_APPLICATION_PANNEL, {
    variables: { slug: path.application },
  });

  const [loading, setLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [generate, setGenerate] = useState(false);
  const [inputs, setInputs] = useState({});
  const [file, setFile] = useState(null);
  const [doc, setDoc] = useState({});
  const [uid, setUid] = useState('');
  const [error, setError] = useState(false);
  const [fileName, setFileName] = useState({});
  const [inputFile, setInputFile] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showError, setShowError] = useState(false);
  let inquiryState;

  const [uploadFiles] = useMutation(UPLOAD_FILES, {
    update: async (cache, { data }) => {
      await refetch();
      console.log('set uid => ', data.uploadDocument.uid);
      
      setUid(data.uploadDocument.uid);
      setLoading(false);
    },
  });

  const [deleteFiles] = useMutation(DELETE_FILES, {
    update: async (cache, { data }) => {
      await refetch();
    },
  });

  const [lockDocs] = useMutation(LOCK_DOCUMENTS, {
    update: async (cache, { data }) => {
      setIsSubmitted(true);
    },
  });

  const [generateApplication] = useMutation(GENERATE_APPLICATIONS, {
    update: async (cache, { data }) => {
      setGenerate(data);
    },
  });

  async function preUpload(id) {
    
    try {
      setLoading(true);
      await uploadFiles({ variables: { document: doc, file } });

      setFileName({
        id,
        name: '',
      });

      const filterInput = inputFile
        .filter(lock => lock.logicEnabled)
        .map(inp => (inp.id === id
          ? {
            ...inp,
            inputs: {
              ...inp.inputs,
              button: loading ? <Loader /> : 'Add Another',
              hasSubmitted: true,
            },
          }
          : {
            ...inp,
            inputs: {
              ...inp.inputs,
              hasFile: false,
            },
          }));

      setInputFile(filterInput);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  async function preSubmit(e) {
    try {
      e.preventDefault();
      await lockDocs({ variables: { slug: { inquirySlug: path.application } } });
    } catch (error) {
      console.log(error);
    }
  }

  const openFile = (id) => {
    document.getElementById(id).click();
  };

  useEffect(() => {
    if (inputs.length) {
      setInputs(inputs);
    }
  }, [inputFile, inputs]);

  if (dataInquiry) inquiryState = dataInquiry.inquiry.application.state;

  useEffect(() => {
    if (!loadingDocs) {
      if (dataDocs.inquiryDocuments) {
        dataDocs.inquiryDocuments.map((inq, id) => {
          inq.id = id;
          inq.inputs = {
            button: 'Select File...',
            hasFile: false,
            hasSubmitted: false,
          };
          return inq;
        });

        const filterInput = dataDocs.inquiryDocuments.filter(lock => lock.logicEnabled);

        setInputFile(filterInput);
      } else {
        setError(true);
      }
    }
  }, [error, loadingDocs, dataDocs]);

  const handleFile = (e, id) => {
    if (e.target.files[0]) {
      setFileName({
        id,
        name: e.target.files[0].name,
      });
      const filterInput = inputFile
        .filter(lock => lock.logicEnabled)
        .map(inp => (inp.id === id
          ? {
            ...inp,
            inputs: {
              ...inp.inputs,
              button: 'Submit',
            },
          }
          : {
            ...inp,
            inputs: {
              ...inp.inputs,
              hasFile: true,
            },
          }));

      setInputFile(filterInput);
      setFile(e.target.files[0]);
      setDoc({
        type: inputFile.find(input => input.id === id).fileName,
        inquirySlug: path.application,
      });
    }
  };

  async function preGenerate() {
    setGenerateLoading(true);
    try {
      await generateApplication({
        variables: { slug: { inquirySlug: path.application } },
      });
    } finally {
      setGenerateLoading(false);
    }
  }

  async function preDelete(id, _uid) {
    if (_uid) {
      await deleteFiles({
        variables: {
          document: {
            inquirySlug: path.application,
            uid: _uid,
          },
        },
      });
    }

    let filterInput = inputFile.map(inp => (inp.id === id
      ? {
        ...inp,
        inputs: {
          ...inp.inputs,
          button: _uid ? 'Add Another' : 'Select File...',
          // values: data.getUserDocuments.filter(o => o.original),
        },
      }
      : {
        ...inp,
        inputs: {
          ...inp.inputs,
          hasFile: false,
          // values: data.getUserDocuments.filter(o => o.original),
        },
      }));

      if (_uid)  {
        filterInput = inputFile.map(inp => (
        {
          ...inp,
          inputs: {
            ...inp.inputs,
            hasFile: false,
            // values: data.getUserDocuments.filter(o => o.original),
          },
        }));
      }

    setFileName({
      id,
      name: '',
    });

    setInputFile(filterInput);
    setFile('');
    setDoc({});
  }

  const handleCheckbox = () => {
    setIsChecked(!isChecked);
    const checkDocs = inputFile.map(i => data.getUserDocuments.filter(inp => inp.type === i.fileName));
    const isEmpty = checkDocs.filter(c => c.length === 0);

    setShowError(isEmpty.length > 0);
  };

  if (!isAuthenticated()) {
    return <Redirect to="/login" />;
  }

  if (!dataDocs || !data) return false;

  if (generate) return <Redirect to="./packet" />;

  const inquiryDocumentsList = [];

  for (let i in dataDocs.inquiryDocuments) {
    inquiryDocumentsList.push({
      description: dataDocs.inquiryDocuments[i].description,
      helper: dataDocs.inquiryDocuments[i].helper
    });
  }

  return (!loadingDocs && dataInquiry) ? (
    <>
      <PageTitle title="SUPPORTING DOCUMENTS" icon="documents" iconWidth={60} iconHeight={60} />
      {error
      || inputFile.length === 0
      || (inquiryState !== 'SUBMITTED'
        && inquiryState !== 'SUBMITTED_DOCS'
        && inquiryState !== 'FINISHED') ? (
          <>
            <ContainerApp mw="510px">
              <Heading>Please complete your application.</Heading>
              <Desc>
              USCIS requires that you submit some additional documents with your application.
              Complete your application to find out which supporting documents you need to submit.
              </Desc>
            </ContainerApp>
            <Flex mt={56}>
              <WhiteBox
                title="You May Need to Submit"
                list={inquiryDocumentsList}
              />
            </Flex>
          </>
        ) : isSubmitted || inquiryState === 'SUBMITTED_DOCS' || inquiryState === 'FINISHED' ? (
          <ContainerApp>
            <Heading>Complete.</Heading>
            <Flex justifyContent="center" mt={-18}>
              <Icon name="checkmark-bigger" width={100} height={100} />
            </Flex>
            <Desc>
            Thank you for submitting your documents. If you need to make any additional changes to
            your answers, please
              {' '}
              <OpenModal
                onClick={() => dispatch({
                  type: 'setModalOpened',
                  showModal: Contact,
                  modalTitle: 'Revision Request',
                  subject: 'I would like to revise my application answers.',
                  hubspot: 'REVISION_REQUEST',
                })
              }
              >
              contact support
              </OpenModal>
            .
            </Desc>
            <Flex justifyContent="center" pt={[40, 60, 80]}>
              {/* <Link to="./packet"> */}
              <Button
                value={generateLoading ? <Loader /> : 'Generate Application'}
                padding="12.5px 26.5px"
                fontColor="#000"
                extraClass={`${generateLoading ? '--disabled --no-interaction' : ''}`}
                bgColor={colors.yellow}
                onClick={preGenerate}
              />
              {/* </Link> */}
            </Flex>
          </ContainerApp>
        ) : (
          <ContainerApp>
            <WhiteBox
              title="Please submit the following documents:"
              list={inputFile}
              fileName={fileName}
              handleFile={handleFile}
              preDelete={preDelete}
              preUpload={preUpload}
              openFile={openFile}
              data={data}
              uid={uid}
              values={data.getUserDocuments}
            />
          </ContainerApp>
        )}
      <ContainerApp>
        <SmallDesc>
          Please note: all documents must be in English. If one of your documents is in a language
          other than English, you need to get it translated before submitting it to USCIS.
        </SmallDesc>
        {!isSubmitted && inquiryState === 'SUBMITTED' && inquiryState && (
          <Flex mt={[20, 40, 50]} justifyContent="center" flexDirection="column">
            {showError && <Error>Please submit all required documents</Error>}
            <Flex justifyContent="center">
              <Box width={5 / 7}>
                <MultipleChoice
                  value
                  onChange={handleCheckbox}
                  optionSelected={isChecked}
                  label="I have uploaded all the supporting documents required for my application."
                />
              </Box>
            </Flex>
            <Flex py={25}>
              <Button
                value="Lock and Submit"
                padding="12.5px 26.5px"
                fontColor="#000"
                bgColor={colors.yellow}
                extraClass={`${isChecked ? '' : '--disabled'}`}
                disabled
                onClick={e => isChecked && preSubmit(e)}
              />
            </Flex>
          </Flex>
        )}
      </ContainerApp>
    </>
  ) : (
    ''
  );
};

export default Documents;
