import React from 'react';
import Icon from 'components/atoms/Icon';
import { Flex } from '@rebass/grid/emotion';
import Button from 'components/molecules/Button';
import HelperText from 'components/molecules/HelperText';

import {
  Box,
  BoxTitle,
  Ul,
  Li,
  File,
  InputContainer,
  Input,
  InputFile,
  Clear,
  ClearCurrent,
} from './style.js';

const WhiteBox = ({
  title, list, fileName, handleFile, preDelete, preUpload, openFile, data, uid
}) => {

  return (
    <Box>
      <BoxTitle>{title}</BoxTitle>
      <Ul>
        {list && list.map((l, i) => (
          <Li hasFile={l.inputs && l.inputs.hasFile} key={i}>
            <span>{l.name || l.description || l}</span>
            {(l.helperText || l.helper) && (
              <HelperText
                customClass="helper-text-container"
                text={
                  l.helperText || l.helper
                }
              />
            )}
            {l.inputs && (
              <Flex width={1} flexDirection="column">
                <Flex
                  width={[1, 9 / 10]}
                  mt={20}
                  mb={11}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <File>File</File>
                  <InputContainer>
                    <Input
                      value={fileName.id === l.id ? fileName.name : ''}
                      // onChange={e => handleFile(e, i)}
                      disabled
                    />
                    {fileName.id === l.id && fileName.name && (
                      <ClearCurrent onClick={() => preDelete(l.id)}>
                        <Icon width={16} height={16} name="close" />
                      </ClearCurrent>
                    )}
                  </InputContainer>
                  <Button
                    value={l.inputs && l.inputs.button}
                    extraClass="secondary"
                    padding="9px"
                    margin="0"
                    fixedWidth="105px"
                    onClick={() => !l.inputs.hasFile
                      && (l.inputs.button === 'Submit' ? preUpload(l.id) : openFile(`input-${i}`))
                    }
                  />
                  <InputFile
                    type="file"
                    value={fileName.name === '' ? fileName.name : ''}
                    onChange={e => handleFile(e, l.id)}
                    id={`input-${i}`}
                  />
                </Flex>
                {data.getUserDocuments.map(
                  (inp, index) => inp.type === l.fileName && (
                  <Flex key={index} width={[1, 9 / 10]} mt={11} alignItems="center">
                    <span>{inp.original}</span>
                    <Clear onClick={() => preDelete(index, inp.uid)}>
                      <Icon width={16} height={16} name="close" />
                    </Clear>
                  </Flex>
                  ),
                )}
              </Flex>
            )}
          </Li>
        ))}
      </Ul>
    </Box>
  );
};

export default WhiteBox;
