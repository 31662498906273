import React, { Fragment, useContext, useState } from 'react';
import { AppContext } from 'context/appContext';
import { Flex, Box } from '@rebass/grid/emotion';
import Title from 'components/molecules/Checkbox/Title';
import Button from 'components/molecules/Button';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { GET_DISCOUNT } from 'graphql/application/queries';
import { APPLY_PAYMENT } from 'graphql/application/mutations';
import Loader from 'components/atoms/Loader';
import Error from 'components/molecules/Error';
import { getSlugUrlGroups } from 'modules/utils';
import InputAnswer from '../../../../molecules/Form/InputAnswer';

import { Bold, Link, Container } from './style';
import { Text } from '../style';

const OrderInfo = (props) => {
  const { history } = props;
  const { state, dispatch } = useContext(AppContext);
  const [code, setCode] = useState('');
  const [finalCode, setFinalCode] = useState(undefined);
  const [finalPrice, setFinalPrice] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const selected = state.packages.filter(p => p.isSelected);
  const { slug } = getSlugUrlGroups();


  const { data: dataCode } = useQuery(GET_DISCOUNT, {
    variables: {
      packageId: selected.length > 0 ? selected[0]._id : null,
      code,
    },
  });

  const [applyPayment] = useMutation(APPLY_PAYMENT, {
    update: (cache, { data }) => {
      if (data.applyPayment.status === 'NOT_PAID') {
        setPaymentErrorMessage(data.applyPayment.error || 'Error occured on proccessing payment.');

        setTimeout(() => {
          setPaymentErrorMessage('');
        }, 4000);
      } else {
        dispatch({ type: 'setPaid', paid: true });
        window.location.reload();
      }
    },
  });

  if (selected.length === 0) {
    history.push(`/applications/${slug}/payment`);
  }

  async function preSubmit(e) {
    try {
      e.preventDefault();
      setLoading(true);
      const { choices } = state.billingAddress[0];
      const choicesMailing = state.mailingAddress[0].choices;

      await applyPayment({
        variables: {
          packageId: selected[0]._id,
          payment: {
            fullName: state.name,
            email: state.email,
            creditCard: state.creditCard,
            billingAddress: {
              address: choices[0].value,
              city: choices[5].value,
              state: choices[7].value,
              zip: choices[8].value,
              country: 'United States of America',
            },
            mailingAddress: {
              address: choicesMailing[0].value,
              city: choicesMailing[5].value,
              state: choicesMailing[7].value,
              zip: choicesMailing[8].value,
              country: 'United States of America',
            },
            discountCode: finalCode,
          },
        },
      });
    } catch (error) {
      console.log(error);
      setLoading(false);

      if (error.graphQLErrors[0]) {
        const errorMessage = error.graphQLErrors[0].extensions.exception.details[0].message.replace(/"/g, '');
        const reference = error.graphQLErrors[0].extensions.exception.details[0].path[0];
        const addressError = error.graphQLErrors[0].extensions.exception.details[0].path[1];

        let capitalizeErrorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);

        if (reference === 'mailingAddress' && addressError === 'city') capitalizeErrorMessage = '"Mailing Adress City" is not allowed to be empty';
        if (reference === 'billingAddress' && addressError === 'city') capitalizeErrorMessage = '"Billing Adress City" is not allowed to be empty';

        if (reference === 'mailingAddress' && addressError === 'address') capitalizeErrorMessage = '"Mailing Adress" is not allowed to be empty';
        if (reference === 'billingAddress' && addressError === 'address') capitalizeErrorMessage = '"Billing Adress" is not allowed to be empty';

        if (reference === 'mailingAddress' && addressError === 'state') capitalizeErrorMessage = '"Mailing Adress State" is not allowed to be empty';
        if (reference === 'billingAddress' && addressError === 'state') capitalizeErrorMessage = '"Billing Adress State" is not allowed to be empty';

        if (reference === 'mailingAddress' && addressError === 'zip') capitalizeErrorMessage = '"Mailing Adress Zip" is not allowed to be empty';
        if (reference === 'billingAddress' && addressError === 'zip') capitalizeErrorMessage = '"Billing Adress Zip" is not allowed to be empty';

        if (reference === 'mailingAddress' && addressError === 'country') capitalizeErrorMessage = '"Mailing Adress Country" is not allowed to be empty';
        if (reference === 'billingAddress' && addressError === 'country') capitalizeErrorMessage = '"Billing Adress Country" is not allowed to be empty';

        setPaymentErrorMessage(capitalizeErrorMessage);

        setTimeout(() => {
          setPaymentErrorMessage('');
        }, 4000);
      }
    }
  }

  function hideError() {
    setErrorMessage('');
  }

  async function preCode(e) {
    try {
      e.preventDefault();
      const { checkDiscountCode } = dataCode;

      if (!checkDiscountCode) {
        setErrorMessage('Invalid code!');

        setTimeout(() => {
          hideError();
        }, 4000);
        return;
      }

      setFinalCode(code);
      setCode('Applied!');
      setDisabled(true);
      setFinalPrice(checkDiscountCode.finalPrice);
    } catch (error) {
      console.log(error);
    }
  }

  const handleCode = (val) => {
    setCode(val);

    if (val.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const editPackage = () => {
    dispatch({ type: 'setPaymentPage', payment: false });
    history.push(`/applications/${slug}/payment`);
  };

  return (
    <Container>
      <Title title="ORDER INFO" />
      {selected.map((s, i) => (
        <Fragment key={i}>
          <Box alignItems="flex-end" flexDirection="column" pt={25} pb={33}>
            <Box width={9.2 / 10}>
              <Flex justifyContent="space-between">
                <Bold width={6 / 10}>{state.packageName}</Bold>
                <Bold width={3.7 / 10}>
USD $
                  {s.price}
                </Bold>
              </Flex>
              <Bold width={6 / 10}>{s.name}</Bold>
              <Flex flexDirection="column" mt={13}>
                <Box width={1} mb={1}>
                  <Text>Package includes:</Text>
                </Box>
                <Box width={9.2 / 10} alignSelf="flex-end">
                  {s.services.map((p, i) => (
                    <Text key={i}>{p}</Text>
                  ))}
                </Box>
              </Flex>
            </Box>
            <Link onClick={editPackage}>edit package</Link>
          </Box>
          <Title title="DISCOUNT CODE" />
          <Flex alignItems="flex-end" flexDirection="column" pt={17} pb={19}>
            <Box width={9.2 / 10}>
              <InputAnswer
                label="Code"
                type="text"
                onChange={(e) => {
                  handleCode(e.target.value);
                }}
                onSubmit={e => preCode(e)}
                value={code}
                isCode
                setDisabled={disabled}
              />
              {errorMessage && <Error>{errorMessage}</Error>}
            </Box>
          </Flex>

          <Flex alignItems="flex-end" flexDirection="column" pt={17} pb={44}>
            <Box width={9.2 / 10}>
              {/* <Flex justifyContent="space-between">
                <PriceText width={6 / 10}>Total Before Taxes</PriceText>
                <PriceText width={3.7 / 10}>
          $
                  {s.price}
                </PriceText>
              </Flex>
              <Flex justifyContent="space-between">
                <PriceText width={6 / 10}>Estimated Taxes</PriceText>
                <PriceText width={3.7 / 10}>$10.00</PriceText>
              </Flex> */}
              <Flex justifyContent="space-between">
                <Bold width={6 / 10}>TOTAL</Bold>
                <Bold width={3.7 / 10}>

                  USD ${finalPrice || s.price}
                </Bold>
              </Flex>
            </Box>
          </Flex>

          <Flex justifyContent="flex-end">
            <Box width={9.2 / 10} className="box-button">
              {state.isCardValid ? (
                <Button
                  value={loading ? <Loader /> : 'Confirm Payment'}
                  extraClass={`continue-payment--submit ${loading && '--disabled'}`}
                  onClick={!loading ? preSubmit : () => {}}
                  disabled={loading}
                />
              ) : (
                <Button
                  value="Confirm Payment"
                  extraClass="continue-payment--submit --bg-yellow --disabled continue-payment--confirm-disabled"
                  disabled
                />
              )}
            </Box>
          </Flex>
          <Flex mt={40}>{paymentErrorMessage && <Error>{paymentErrorMessage}</Error>}</Flex>
        </Fragment>
      ))}
    </Container>
  );
};

export default OrderInfo;
