import styled from 'styled-components';
import colors from 'modules/colors';

export const Container = styled.button`
  background-color: ${({ bgColor }) => bgColor || colors.blueAliro};
  border-radius: 5px;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: ${({ fixedWidth }) => fixedWidth || 'auto'};
  display: ${({ fixedWidth }) => (fixedWidth ? 'block' : 'flex')};
  align-items: center;
  justify-content: center;
  margin: ${({ margin }) => margin || '0 auto'};
  padding: ${({ padding }) => padding || '11px 30px'};

  &.secondary {
    background-color: ${() => colors.white};
    border: 1px solid ${() => colors.blueAliro};

    .button-text {
      font-size: 12px;
      line-height: 15px;
      color: ${() => colors.blueDark};
    }
  }

  &.--no-interaction {
    pointer-events: none;
  }

  &.--bg-yellow {
    background-color: ${() => colors.yellow};
  }

  &.continue-payment {
    padding: 18px 24px;
    margin-right: 0;
    width: 100%;

    &--submit {
      background-color: ${() => colors.yellow};
      padding: 12.5px;
      max-width: 176px;
      width: 100%;
      margin: 0 auto;

      span {
        color: ${() => colors.black};
      }
    }

    &--confirm-disabled {
      span {
        color: ${() => colors.black};
        font-weight: 600;
      }
    }
  }

  &.button--delete {
    background-color: ${() => colors.red};

    span {
      margin-right: 2px;
    }
  }

  &.--disabled {
    cursor: default;
    background-color: ${() => colors.grey}!important;
  }
`;

export const Text = styled.span`
  color: ${({ fontColor }) => fontColor || colors.white};
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
`;

export const ContainerIcon = styled.span`
  display: inline-block;
  margin-left: ${({ orientation }) => (orientation === 'right' ? '8px' : '0')};
  margin-right: ${({ orientation }) => (orientation === 'left' ? '8px' : '0')};
`;
