import React, { useContext } from "react";
import { AppContext } from "context/appContext";
import { Modal } from "./style";

const Modals = () => {
  const { state, dispatch } = useContext(AppContext);
  
  /*
  const hideModal = () => {
    dispatch({ type: 'setModalClosed' });
  };
  */
 
  return (
    <>{state.isModalOpened && <Modal onClick={()=>{}}>{state.showModal}</Modal>}</>
  );
};

export default Modals;
