import styled from 'styled-components';
import colors from 'modules/colors';

export const Label = styled.span`
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: ${() => colors.black};

  @media (max-width: 63.99em) {
    display: none;
  }
`;
