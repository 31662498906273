import styled from 'styled-components';
import colors from 'modules/colors';

export const Container = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-bottom: 25px;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${({ color }) => color || colors.white};
`;

export const List = styled.ul`
  padding-left: 15px;
  margin-top: 16px;
  list-style: none;
`;

export const ListItem = styled.li`
  font-size: 12px;
  line-height: 15px;
  color: ${({ color }) => color || colors.white};
  margin-bottom: 5px;
  text-align: left;
  display: flex;
  align-items: center;

  &:before {
    content: '•';
    color: #fff;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.--valid {
    color: #0feec2;
  }

  &.--invalid {
    color: #ff0000;
  }

  svg {
    margin-left: 5px;
  }
`;
