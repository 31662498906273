import React, { useReducer } from 'react';

const initialState = {
  name: '',
  email: '',
  packageName: '',
  paid: false,
  isModalOpened: false,
  isLeaving: false,
  showModal: null,
  modalTitle: '',
  payment: false,
  checkout: false,
  isCardValid: false,
  registerInvalid: false,
  passwordLength: 0,
  setShowLogin: false,
  billingAddress: localStorage.getItem('address')
    ? JSON.parse(localStorage.getItem('address'))
    : [
      {
        choices: [
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
          { value: '' },
        ],
      },
    ],
  mailingAddress: localStorage.getItem('mailingAddress')
    ? JSON.parse(localStorage.getItem('mailingAddress'))
    : localStorage.getItem('address')
      ? JSON.parse(localStorage.getItem('address'))
      : [
        {
          choices: [
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
            { value: '' },
          ],
        },
      ],
  creditCard: {
    nameOnCard: '',
    cardNumber: '',
    expiration: '',
    cvv: '',
  },
  hubspot: 'EMAIL_US',
  newApplication: false,
  subject: '',
  packages: [],
  progress: 0,
  inquiry: sessionStorage.getItem('inquiry')
    ? JSON.parse(sessionStorage.getItem('inquiry'))
    : false,
  bodyStatus: [],
  documents: [
    {
      id: 0,
      description:
        'A photocopy of both sides of your Permanent Resident Card (otherwise known as your Green Card).',
      helper: 'Helper 1',
      inputs: {
        length: 0,
        values: [''],
      },
    },
    {
      id: 1,
      description: 'A photocopy of a marriage certificate',
      helper: 'Helper 2',
      inputs: {
        length: 0,
        values: [''],
      },
    },
    {
      id: 2,
      description: 'A photocopy of a certificate of divorce',
      helper: 'Helper 3',
      inputs: {
        length: 0,
        values: [''],
      },
    },
    {
      id: 3,
      description: 'A photocopy of an annulment decree',
      helper: 'Helper 4',
      inputs: {
        length: 0,
        values: [''],
      },
    },
    {
      id: 4,
      description: 'A photocopy of a death certificate of a former spouse',
      helper: 'Helper 5',
      inputs: {
        length: 0,
        values: [''],
      },
    },
  ],
  creditCardValid: {},
  fnModal: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'setFnModal':
      if (state.fnModal) return state;
      return {
        ...state,
        fnModal: action.fnModal || undefined
      }
    case 'setModalOpened':
      return {
        ...state,
        isModalOpened: true,
        showModal: <action.showModal {...action.modalProps} />,
        hubspot: action.hubspot || 'EMAIL_US',
        modalTitle: action.modalTitle,
        isLeaving: action.isLeaving || false,
        subject: action.subject || '',
        modalUid: action.modalUid || '',
      };
    case 'setModalClosed':
      return { ...state, isModalOpened: false };
    case 'setPackages':
      return {
        ...state,
        packages: action.packages,
      };
    case 'setPackageSelected':
      return {
        ...state,
        packages: state.packages.map(p => (p._id === action.id
          ? {
            ...p,
            isSelected: true,
          }
          : {
            ...p,
            isSelected: false,
          })),
      };

    case 'setPaymentPage':
      return {
        ...state,
        payment: action.payment,
      };
    case 'setCheckoutPage':
      return {
        ...state,
        checkout: action.checkout,
      };
    case 'setConfirmPayment':
      return {
        ...state,
        isCardValid: action.isCardValid,
      };
    case 'setCreditCard':
      return {
        ...state,
        creditCard: {
          nameOnCard: action.name
            ? action.name
            : action.name === ''
              ? ''
              : state.creditCard.nameOnCard,
          cardNumber: action.cardNumber
            ? action.cardNumber
            : action.cardNumber === ''
              ? ''
              : state.creditCard.cardNumber,
          expiration: action.expiration
            ? action.expiration.replace(' / ', '')
            : action.expiration === ''
              ? ''
              : state.creditCard.expiration.replace(' / ', ''),
          cvv: action.cvv ? action.cvv : action.cvv === '' ? '' : state.creditCard.cvv,
        },
      };

    case 'setCardValidationName':
      return {
        ...state,
        creditCardValid: {
          ...state.creditCardValid,
          name: action.name,
        }
      }
    case 'setCardValidationNumber':
      return {
        ...state,
        creditCardValid: {
          ...state.creditCardValid,
          number: action.number,
        }
      }

    case 'setCardValidationCvv':
      return {
        ...state,
        creditCardValid: {
          ...state.creditCardValid,
          cvv: action.cvv,
        }
      }

    case 'setCardValidationExpiration':
      return {
        ...state,
        creditCardValid: {
          ...state.creditCardValid,
          expiration: action.expiration,
        }
      }
    case 'setNewName':
      return {
        ...state,
        name: action.name,
      };
    case 'setEmail':
      return {
        ...state,
        email: action.email,
      };
    case 'setAvailableApplications':
      return {
        ...state,
        newApplication: action.showAvailableApplication,
      };
    case 'setProgress':
      return {
        ...state,
        progress: action.progress,
      };
    case 'setSavingStatus':
      return {
        ...state,
        savingStatus: action.savingStatus,
      };
    case 'setInquiry':
      return {
        ...state,
        inquiry: action.inquiry,
      };
    case 'setDocName':
      return {
        ...state,

        documents: state.documents.map(doc => (doc.id === action.id
          ? {
            ...doc,
            inputs: {
              ...doc.inputs,
              values: [...doc.inputs.values, action.documents],
            },
          }
          : {
            ...doc,
          })),
      };
    case 'setPaid':
      return {
        ...state,
        paid: action.paid,
      };
    case 'setPasswordLength':
      return {
        ...state,
        passwordLength: action.passwordLength,
      };
    case 'setBillingAddress':
      return {
        ...state,
        billingAddress: action.billingAddress,
      };
    case 'setMailingAddress':
      return {
        ...state,
        mailingAddress: action.mailingAddress,
      };
    case 'setPackageName':
      return {
        ...state,
        packageName: action.packageName,
      };
    case 'setRegisterInvalid':
      return {
        ...state,
        registerInvalid: action.registerInvalid,
      };
    case 'setShowLogin':
      return {
        ...state,
        showLogin: action.showLogin,
      };
    default:
      return { ...state };
  }
};

const AppContext = React.createContext(initialState);

function AppProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
}

export { AppContext, AppProvider };
