import styled from 'styled-components';
import colors from 'modules/colors';

export const Text = styled.label`
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.fontColor};
  padding: 10px 15px 10px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;

  &:last-child {
    padding-right: 0;
  }

  &.address-radio {
    justify-content: flex-start;
    align-items: center;
  }
`;

export const HelperContainer = styled.div`
  display: flex;
`;
