export const hostName = () => {
  const { hostname } = window.location;
  const url = hostname.split('.')[1];

  return { url, hostname };
};

export const host = () => {
  const { hostname } = window.location;
  const url = hostname.split('.')[1];

  // return {
  //   api: `https://aliro-form-builder.subservers.nodo.cc/api/graphql`,
  //   admin: `https://aliro-form-builder.subservers.nodo.cc`,
  // };

  if (hostname === 'localhost' || hostname === 'aliro.vm') {
    return { api: 'http://api.aliro.vm/api/graphql', admin: 'http://admin.aliro.vm' };
  }

  if (hostname === 'app.legalpilot.io') {
    return {
      api: `https://api.legalpilot.io/api/graphql`,
      admin: `https://manager.legalpilot.io`,
    };
  }

  return {
    api: `https://aliro-form-builder.${url}.nodo.cc/api/graphql`,
    admin: `https://aliro-form-builder.${url}.nodo.cc`,
  };
};

export const verifyPasswordStrength = (password) => {
  if (password.length === 0 || password.length > 64) return 0;

  const sensitiveCases = {
    six: password.length > 6,
    upper: new RegExp('[A-Z]').test(password),
    number: new RegExp('[0-9]').test(password),
    symbol: new RegExp('[$@$!%*#?&]').test(password),
  };

  return sensitiveCases;
};

export const signin = (token) => {
  window.location.href = '/your-account';
  localStorage.setItem('token', token);
  localStorage.removeItem('USAddress');
  localStorage.removeItem('address');
  localStorage.removeItem('mailingAddress');
};

export const isAuthenticated = () => !!localStorage.getItem('token');

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('USAddress');
  localStorage.removeItem('address');
  localStorage.removeItem('mailingAddress');
  window.location.href = '/login';
};

export const slugify = (string) => {
  if (!string) return '';
  const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
  const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return (
    string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(p, c => b.charAt(a.indexOf(c)))
      .replace(/&/g, '-and-')
      // eslint-disable-next-line
      .replace(/[^\w\-]+/g, '')
      // eslint-disable-next-line
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '')
  );
};

export const getUrlGroups = () => {
  const pathname = window.location.pathname.replace('/applications/', '').split('/');
  const application = pathname[0];
  const group = pathname[1];
  const subGroup = pathname[2];
  return { application, group, subGroup };
};

// TODO: remove duplicated code
export const getSlugUrlGroups = () => {
  const pathname = window.location.pathname.replace('/applications/', '').split('/');
  const slug = pathname[0];
  const slugGroup = pathname[1];
  const slugSubGroup = pathname[2];

  return { slug, slugGroup, slugSubGroup };
};

export const getAllUrlParams = (url) => {
  // get query string from url (optional) or window
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  const obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    const arr = queryString.split('&');

    for (let i = 0; i < arr.length; i++) {
      // separate the keys and the values
      const a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      const paramName = a[0];
      const paramValue = typeof a[1] === 'undefined' ? true : a[1];

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        const key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          const index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
};

export const removeHubSpot = () => {
  let time = null;

  time = setInterval(() => {
    const el = document.querySelector('#hubspot-messages-iframe-container');
    if (el) {
      el.remove();
      clearInterval(time);
    }
  }, 500);
};

export const isPreviewPage = () => {
  const { pathname } = window.location;
  const paths = pathname.split('/');
  const isPreviewPage = paths[paths.length - 1] === 'preview';

  return isPreviewPage;
};

export const isPreviewAnswerPage = () => {
  const { search } = window.location;
  const params = search.replace('?', '').split('&');

  return params.includes('answerId');
};

export const getPageType = () => {
  const { pathname } = window.location;
  const paths = pathname.split('/');
  const isPreviewPage = paths[paths.length - 1] === 'preview';

  const { search } = window.location;
  const paramsWithValues = search.replace('?', '').split('&');
  const params = paramsWithValues.map(param => param.split('=')[0]);

  if (params.includes('answerId') && isPreviewPage) return 'previewAnswerPage';

  if (isPreviewPage) return 'previewPage';

  return 'defaultPage';
};

export const hideIfIsPreview = (component) => {
  const isPreview = isPreviewPage();

  if (!isPreview) return component;

  return '';
};

export const acceptValues = [
  'armed forces americas',
  'armed forces africa',
  'armed forces canada',
  'armed forces europe',
  'armed forces middle east',
  'alaska',
  'alabama',
  'armed forces pacific',
  'arkansas',
  'american samoa',
  'arizona',
  'california',
  'colorado',
  'connecticut',
  'district of columbia',
  'delaware',
  'florida',
  'federated stated of micronesia',
  'georgia',
  'guam',
  'hawaii',
  'iowa',
  'idaho',
  'illinois',
  'indiana',
  'kansas',
  'kentucky',
  'louisiana',
  'massachusetts',
  'maryland',
  'maine',
  'marshall islands',
  'michigan',
  'minnesota',
  'missouri',
  'northern mariana islands',
  'mississippi',
  'montana',
  'north carolina',
  'north dakota',
  'nebraska',
  'new hampshire',
  'new jersey',
  'new mexico',
  'nevada',
  'new york',
  'ohio',
  'oklahoma',
  'oregon',
  'pennsylvania',
  'puerto rico',
  'palau',
  'rhode island',
  'south carolina',
  'south dakota',
  'tennessee',
  'texas',
  'utah',
  'virginia',
  'virgin islands',
  'vermont',
  'washington',
  'wisconsin',
  'west virginia',
  'wyoming',
  'aa',
  'ae',
  'ae',
  'ae',
  'ae',
  'ak',
  'al',
  'ap',
  'ar',
  'as',
  'az',
  'ca',
  'co',
  'ct',
  'dc',
  'de',
  'fl',
  'fm',
  'ga',
  'gu',
  'hi',
  'ia',
  'id',
  'il',
  'in',
  'ks',
  'ky',
  'la',
  'ma',
  'md',
  'me',
  'mh',
  'mi',
  'mn',
  'mo',
  'mp',
  'ms',
  'mt',
  'nc',
  'nd',
  'ne',
  'nh',
  'nj',
  'nm',
  'nv',
  'ny',
  'oh',
  'ok',
  'or',
  'pa',
  'pr',
  'pw',
  'ri',
  'sc',
  'sd',
  'tn',
  'tx',
  'ut',
  'va',
  'vi',
  'vt',
  'wa',
  'wi',
  'wv',
  'wy',
];