import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './style';

const PageContainer = (props) => {
  const { children, display } = props;

  return (
    <Container display={display} flexDirection={['column', 'column', 'column', 'row']} flex={1}>
      {children}
    </Container>
  );
};

PageContainer.propTypes = {
  display: PropTypes.string,
};

export default PageContainer;
