import styled from 'styled-components';
import colors from 'modules/colors';
import { Box } from '@rebass/grid/emotion';

export const PannelContainer = styled(Box)`
  /* width: 100%; */
  /* max-width: 350px; */
  height: 100%;
  min-height: 100%;
  background-color: ${() => colors.greyMedium};
  /* padding: ${({ padding }) => padding || '35px 45px'}; */
  overflow: auto;

  @media (max-width: 63.99em) {
    min-height: auto;
    height: auto;

    &:first-child {
      max-height: ${({ open }) => (open ? '100%' : '82px')};
      flex: ${({ open }) => (open ? '1 1 auto' : '1')};
    }
  }
`;
