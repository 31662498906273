import React from 'react';
import Hero from 'components/templates/Hero';
import LoginRegister from 'components/templates/LoginRegister';
import { Redirect } from 'react-router-dom';
import { isAuthenticated } from 'modules/utils';

export default () => {
  if (isAuthenticated()) {
    return <Redirect to="/account-settings" />;
  }

  return (
    <>
      <Hero />
      <LoginRegister />
    </>
  );
};
