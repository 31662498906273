import styled from 'styled-components';
import colors from 'modules/colors';

export const Container = styled.div`
  padding: 73px 0;
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @media (max-width: 39.99em) {
    height: 100%;
  }
`;

export const ContainerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const BottomLabel = styled.span`
  display: block;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${() => colors.black};
  margin-top: 45px;
  text-align: center;
`;
