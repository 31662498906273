import styled from 'styled-components';
import colors from 'modules/colors';

export const InputElement = styled.input`
  display: block;
  width: 100%;
  height: 30px;
  background: ${() => colors.white};
  border-radius: 5px;
  border: none;
  padding: 0 22px;
  font-family: Helvetica;
  font-size: 14px;
  line-height: 16px;
`;
