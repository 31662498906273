import React from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "@rebass/grid/emotion";
import { Container, Label, UpdateButton, Info } from "./style";

const PersonalInfo = props => {
  const { label, value, edit } = props;

  return (
    <Flex flexDirection="column">
      <Container>
        <Label>{label}</Label>
        <UpdateButton onClick={edit}>edit</UpdateButton>
      </Container>
      <Flex justifyContent="center" pb={26}>
        <Box width={6 / 10}>
          <Info>{value}</Info>
        </Box>
      </Flex>
    </Flex>
  );
};

PersonalInfo.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  edit: PropTypes.func.isRequired,
};

export default PersonalInfo;
