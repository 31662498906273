import React from 'react';
import Pannel from 'components/molecules/Pannel';
import QuestionsList from 'components/organisms/QuestionsList';
import CartList from 'components/organisms/CartList';
import SaveProgress from 'components/organisms/SaveProgress';

const PannelHelp = (props) => {
  const { pathname } = window.location;

  return (
    <Pannel>
      {pathname.match(/payment$/) && <CartList history={props.history} />}
      <QuestionsList />
      {pathname.includes('applications') && <SaveProgress />}
    </Pannel>
  );
};

export default PannelHelp;
