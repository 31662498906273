import styled from 'styled-components';
import colors from 'modules/colors';

export const Name = styled.h3`
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  margin: 0;
  text-transform: uppercase;
  color: ${() => colors.black};

  &:after {
    content: '';
    display: block;
    width: 154px;
    height: 3px;
    background-color: ${() => colors.blueAliro};
    margin-top: 11px;
  }
`;
