import styled from 'styled-components';
import colors from 'modules/colors';

export const Container = styled.div`
  position: relative;
  cursor: pointer;
  margin-left: 5px;
  height: 15px;

  @media (max-width: 767px) {
    right: 0px;
    margin-right: 20px;
  }
`;

export const Helper = styled.div`
  position: absolute;
  top: 30px;
  left: -9px;
  width: max-content;
  max-width: 200px;
  background: ${() => colors.white};
  padding: 13px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid ${() => colors.grey};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: .25s;

  @media (max-width: 767px) {
    left: auto;
    right: -10px;
  }

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    left: 10px;
    width: 8px;
    height: 8px;
    background-color: ${() => colors.white};
    border: 1px solid ${() => colors.grey};
    border-right-color: transparent;
    border-bottom-color: transparent;
    transform: rotate(45deg);

    @media (max-width: 767px) {
      left: auto;
      right: 10px;
    }
  }
`;