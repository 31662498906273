import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import BoxSign from 'components/molecules/LoginRegisterBox';
import Title from 'components/molecules/LoginRegisterBox/Title';
import Form from 'components/molecules/LoginRegisterBox/Form';
import FormItem from 'components/molecules/LoginRegisterBox/FormItem';
import Input from 'components/molecules/LoginRegisterBox/Input';
import PasswordStrength from 'components/molecules/LoginRegisterBox/PasswordStrength';
import { verifyPasswordStrength } from 'modules/utils';
import Error from 'components/molecules/Error';
import { Box } from '@rebass/grid/emotion';
import Button from 'components/molecules/Button';

import { useMutation } from 'react-apollo-hooks';
import { RESET_PASSWORD } from 'graphql/user/mutations';

const LoginBox = () => {
  const [password, setPassword] = useState('');
  const [resetToken, setResetToken] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPassword, setNewPassword] = useState(false);
  const [sensitiveCases, setSensitiveCases] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const { href } = window.location;

    setResetToken(href.split('resetToken=')[1]);
  }, []);

  const passwordChanged = (password) => {
    setPassword(password);
    setSensitiveCases(verifyPasswordStrength(password));
  };

  const [reset] = useMutation(RESET_PASSWORD, {
    update: (cache, { data }) => {
      setNewPassword(data.resetPassword);
    },
  });

  function hideError() {
    setErrorMessage('');
  }

  async function preReset(e) {
    try {
      e.preventDefault();
      if (password === confirmPassword) {
        await reset({ variables: { resetToken, password } });
      } else {
        setErrorMessage('Password does not match!');
        setTimeout(() => {
          hideError();
        }, 4000);
      }
    } catch (error) {
      setErrorMessage(error.message.replace('GraphQL error: ', ''));

      setTimeout(() => {
        hideError();
      }, 4000);
    }
  }

  return (
    <>
      {newPassword ? (
        <Redirect to="/login" />
      ) : (
        <BoxSign>
          <Title title="Reset Password" />
          {errorMessage && <Error>{errorMessage}</Error>}

          <Form onSubmit={preReset}>
            <Box width={1}>
              <FormItem>
                <Input
                  type="password"
                  placeholder="New Password"
                  value={password}
                  onChange={ev => passwordChanged(ev.target.value)}
                />
              </FormItem>

              <FormItem>
                <Input
                  type="password"
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={ev => setConfirmPassword(ev.target.value)}
                />
              </FormItem>
              <PasswordStrength sensitiveCases={sensitiveCases} />

              <Button
                value="Reset Password"
                extraClass="secondary --bg-yellow"
                fixedWidth="187px"
                type="submit"
              />
            </Box>
          </Form>
        </BoxSign>
      )}
    </>
  );
};

export default LoginBox;
