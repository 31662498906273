import React from 'react';
import PropTypes from 'prop-types';
import { Label } from './style';

const PannelLabel = (props) => {
  const { name } = props;

  return <Label>{name}&nbsp;</Label>; //TODO: Fix this properly
};

PannelLabel.propTypes = {
  name: PropTypes.string,
};
 
export default PannelLabel;
