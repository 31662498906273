import styled from 'styled-components';
import colors from '../../../modules/colors';

export const Blue = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.blueAliro};
  margin-bottom: 39px;
  cursor: pointer;
`;

export const SmallDesc = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin: 47px auto 0;
  max-width: 532px;
`;

export const BoxTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.black};
`;

export const Ul = styled.ul`
  padding-left: 28px;

  @media (max-width: 40em) {
    padding-left: 15px;
  }
`;

export const Li = styled.li`
  padding-top: 23px;
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.black};
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  opacity: ${({ hasFile }) => (hasFile ? 0.5 : 1)}

  &:before {
    content: '•';
    position: absolute;
    left: -15px;
    top: 20px;
    font-size: 34px;
    color: ${() => colors.blueAliro};
  }
`;

export const Help = styled.span`
  margin-left: 8px;
  margin-bottom: -2px;
`;

export const File = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.black};
  font-weight: 500;
`;

export const InputContainer = styled.div`
  position: relative;
  flex: 1;
  margin: 0 11px;
`;

export const Clear = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

export const ClearCurrent = styled(Clear)`
  position: absolute;
  top: 8px;
  right: 10px;
`;

export const Input = styled.input`
  border: 1px solid rgba(84, 84, 84, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 100%;
  line-height: 17px;
  padding: 8px 30px 8px 9px;
`;

export const InputFile = styled.input`
  display: none;
`;
