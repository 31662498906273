/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, FormTextarea, Error } from './style';

const LongAnswer = props => {
  const {
    validatorRegister,
    validatorConditions, 
    errors, 
    name,
    errorMessage,
    minLength
  } = props;

  if (minLength) {
    validatorConditions.minLength = {
      value: minLength,
      message: `The field must be at least ${minLength} characters`
    }
  }

  return (
    <Container errors={errors} name={name}>
      <FormTextarea {...props} rows="4" ref={validatorRegister && validatorRegister(validatorConditions)} />
      {errors && errors[name] && <Error className="error-message">{`${errorMessage || errors[name].message || 'This field is required'}`}</Error>}
    </Container>
  );
}

LongAnswer.propTypes = {
  placeholder: PropTypes.string.isRequired,
};

export default LongAnswer;
