import React from 'react';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import {
  Container, Label, Border, FormInputYellow, FormInputYellowSmall, Divider,
} from './style';

const ZipCode = (props) => {
  const { label, value, onInputChange } = props;
  let firstInputRef;
  let secondInputRef;

  const firstVal = value ? value.replace(/\D+/g, '').slice(0, 5) : '';
  const secondVal = value ? value.replace(/\D+/g, '').slice(5, 9) : '';

  return (
    <Container>
      <Flex width={[1, '151px']} justifyContent="space-between" marginRight="auto">
        <Label>{label}</Label>
        <Border />
      </Flex>
      <Flex alignItems="center" ml={[0, 0, 11]} width={['auto', '100%', 'auto']}>
        <FormInputYellow
          ref={ref => (firstInputRef = ref)}
          type="text"
          value={firstVal}
          onChange={onFirstInputChange}
          maxLength="5"
        />
        <Divider />
        <FormInputYellowSmall
          ref={ref => (secondInputRef = ref)}
          type="text"
          value={secondVal}
          onChange={onSecondInputChange}
          maxLength="4"
        />
      </Flex>
    </Container>
  );

  function onFirstInputChange(e) {
    const el = e.target;
    el.value = el.value.replace(/\D+/g, '');

    if (el.value.length === 5) {
      secondInputRef.focus();
    }

    onInputChange && onInputChange(`${el.value}${secondInputRef.value}`);
  }

  function onSecondInputChange(e) {
    const el = e.target;
    const { value } = el;
    el.value = el.value.replace(/\D+/g, '');

    if (!value.length) {
      firstInputRef.focus();
    }

    onInputChange && onInputChange(`${firstInputRef.value}${el.value}`);
  }
};

ZipCode.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onInputChange: PropTypes.func,
};

export default ZipCode;
