import React from 'react';
import PropTypes from 'prop-types';
import { Container, Text, Bold } from './style';

const Banner = (props) => {
  const { title, text } = props;

  return (
    <Container>
      <Text>
        {title && (
        <Bold>
          {title}
          {' '}
        </Bold>
        )}
        {text}
      </Text>
    </Container>
  );
};

Banner.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Banner;
