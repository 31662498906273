import styled from 'styled-components';
import colors from 'modules/colors';
import { Flex } from '@rebass/grid/emotion';

export const BestDeal = styled.div`
  position: absolute;
  left: 2.5px;
  top: 0;

  @media (max-width: 52em) {
    transform: scale(0.5);
    top: -30px;
    left: -30px;
  }
`;

export const Title = styled.p`
  font-size: 36px;
  line-height: 44px;
  color: ${() => colors.white};
  margin-bottom: 10px;
  font-weight: bold;
  max-width: 280px;
  text-align: center;

  @media (max-width: 52em) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const Price = styled.span`
  font-size: 36px;
  line-height: 41px;
  font-weight: bold;
  color: #fff;
  margin: 0 1.5px;

  @media (max-width: 52em) {
    font-size: 32px;
    line-height: 37px;
  }
`;

export const Currency = styled.span`
  font-size: 18px;
  line-height: 21px;
  align-self: flex-start;
  font-weight: bold;
  color: #fff;
`;

export const Usd = styled.span`
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 4.4px;
`;

export const WhiteBox = styled(Flex)`
  background-color: ${() => colors.white};
  border-radius: 5px;
  padding: 17.5px 16px 0;
`;

export const Button = styled.button`
  background-color: ${() => colors.yellow};
  box-shadow: 0px 4px 4px rgba(20, 36, 61, 0.25);
  border-radius: 5px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 14px;
  color: ${() => colors.blueDark};
  font-weight: 600;
  width: 100%;
  max-width: 176px;
`;
