import React, { useState, useContext } from 'react';
import PannelList from 'components/molecules/Pannel/List';
import PannelListItem from 'components/molecules/Pannel/List/Item';
import { AppContext } from 'context/appContext';
import { Contact, CallUs } from 'components/organisms/Modals';
import { Container } from './style';

const QuestionsList = () => {
  const { dispatch } = useContext(AppContext);
  const [questions] = useState([
    // { name: 'Chat With Us', click: () => openHubspotChat() },
    {
      name: 'Email Us',
      click: () => dispatch({
        type: 'setModalOpened',
        showModal: Contact,
        modalTitle: 'Contact Support',
        hubspot: 'EMAIL_US',
      }),
    },
    { name: 'Call Us', click: () => dispatch({ type: 'setModalOpened', showModal: CallUs }) },
  ]);
  /*
  function openHubspotChat() {
    window.HubSpotConversations.widget.open();
  }
  */
  return (
    <Container>
      <PannelList name="Have Questions?">
        {questions.map((question) => {
          const { name, click } = question;

          return <PannelListItem key={name} value={name} onClick={click} />;
        })}
      </PannelList>
    </Container>
  );
};

export default QuestionsList;
