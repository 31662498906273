import styled from 'styled-components';
import colors from 'modules/colors';

export const Heading = styled.h2`
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: ${() => colors.fontColor};
  margin: 13px auto 20px;
  text-align: center;
`;

export const Desc = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${() => colors.fontColor};
  margin: 0 auto 47px;
`;
