import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from 'context/appContext';
import PageTitle from 'components/molecules/PageTitle';
import ContainerApp from 'components/molecules/ContainerApp';
import { Redirect, Link } from 'react-router-dom';
import { isAuthenticated, getUrlGroups } from 'modules/utils';
import { Flex, Box } from '@rebass/grid/emotion';
import Icon from 'components/atoms/Icon';
import Loader from 'components/atoms/Loader';
import { Contact } from 'components/organisms/Modals';
import { useQuery, useMutation } from 'react-apollo-hooks';
import {
  GET_APPLICATION_LIST,
  GET_APPLICATION_PANNEL,
  GET_PAID,
} from 'graphql/application/queries';

import {
  FINISH_ANSWER,
  START_REVIEW,
  GENERATE_APPLICATIONS,
} from '../../../graphql/application/mutations';
import Button from '../../molecules/Button';
import { Heading, Desc, OpenModal } from './style.js';
import MultipleChoice from '../../molecules/Form/MultipleChoice';
import colors from '../../../modules/colors';

const Review = (props) => {
  const { history, inquiry } = props;
  const { data } = useQuery(GET_APPLICATION_LIST);
  const { dispatch } = useContext(AppContext);
  const [generate, setGenerate] = useState(false);
  const [generateButton, setGenerateButton] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [lockLoading, setLockLoading] = useState(false);
  const path = getUrlGroups();
  let url = '/';
  let inquiryState;

  const { data: dataInquiry } = useQuery(GET_APPLICATION_PANNEL, {
    variables: { slug: path.application },
  });

  const { data: dataPaid } = useQuery(GET_PAID, {
    variables: { slug: { inquirySlug: path.application } },
  });

  useEffect(() => {
    if (dataInquiry) {
      setGenerateButton(
        dataInquiry.inquiry.nextStep.filter(n => n.slug === 'documents').length === 0,
      );
    }
  }, [dataInquiry]);

  const [finishAnswer] = useMutation(FINISH_ANSWER, {
    update: (cache, { data }) => {
      setIsSubmitted(true);
    },
  });

  const [startReview] = useMutation(START_REVIEW, {
    update: (cache, { data }) => {
      inquiry.application.state = 'IN_REVIEW';
      document.getElementsByClassName('group')[0].click();
    },
  });

  const [generateApplication] = useMutation(GENERATE_APPLICATIONS, {
    update: async (cache, { data }) => {
      setGenerate(data);
    },
  });

  async function preSubmit(e) {
    setLockLoading(true);

    try {
      e.preventDefault();
      await finishAnswer({ variables: { slug: { inquirySlug: path.application } } });
    } catch (error) {
      console.log(error);
    } finally {
      setLockLoading(false);
    }
  }

  async function preReview(e, url) {
    try {
      e.preventDefault();
      await startReview({ variables: { slug: path.application } });
      history.push(url);
    } catch (error) {
      console.log(error);
    }
  }

  async function preGenerate() {
    setGenerateLoading(true);
    try {
      await generateApplication({
        variables: { slug: { inquirySlug: path.application } },
      });
    } catch(error) {
      console.log(error);
    } finally {
      setGenerateLoading(false);
    }
  }

  if (!isAuthenticated()) {
    return <Redirect to="/login" />;
  }

  if (data) {
    url = data.inquiries.filter(inq => inq.slug === path.application);
  }

  if (dataInquiry) inquiryState = dataInquiry.inquiry.application.state;

  if (generate) return <Redirect to="./packet" />;

  return (data && dataInquiry) ? (
    <>
      <PageTitle title="REVIEW ANSWERS" icon="review" iconWidth={50} iconHeight={50} />
      <ContainerApp>
        {((inquiryState === 'SUBMITTED'
          || inquiryState === 'SUBMITTED_DOCS'
          || inquiryState === 'FINISHED')
          && dataPaid
          && dataPaid.checkIfApplicationIsPaid)
        || isSubmitted ? (
          <>
            <Heading>Complete.</Heading>
            <Flex justifyContent="center" mt={-18}>
              <Icon name="checkmark-bigger" width={100} height={100} />
            </Flex>
            <Desc>
              Thank you for reviewing your answers. If you need to make any additional changes to
              your answers, please
              {' '}
              <OpenModal
                onClick={() => dispatch({
                  type: 'setModalOpened',
                  showModal: Contact,
                  modalTitle: 'Revision Request',
                  subject: 'I would like to revise my application answers.',
                  hubspot: 'REVISION_REQUEST',
                })
                }
              >
                contact support
              </OpenModal>
              .
            </Desc>

            {generateButton ? (
              <Flex justifyContent="center" pt={[40, 60, 80]}>
                <Button
                  value={generateLoading ? <Loader /> : 'Generate Application'}
                  extraClass={`${generateLoading ? '--disabled --no-interaction' : ''}`}
                  padding="12.5px 26.5px"
                  fontColor="#000"
                  bgColor={colors.yellow}
                  onClick={preGenerate}
                />
              </Flex>
            ) : (
              <Flex justifyContent="flex-end" pt={[40, 60, 80]}>
                <Link to="./documents">
                  <Button
                    margin={0}
                    value="Continue"
                    arrowRight
                    onClick={() => isChecked && setIsSubmitted(true)}
                  />
                </Link>
              </Flex>
            )}
          </>
          ) : (inquiryState === 'COMPLETED' || inquiryState === 'IN_REVIEW')
          && dataPaid
          && dataPaid.checkIfApplicationIsPaid ? (
            <>
              <Heading>Review your application.</Heading>
              <Desc>
              Now that you’ve completed your application, it’s time to take a second look to check
              for accuracy and make any necessary changes. Once you are done, click the checkmark
              below and submit your application. Contact support if you need to make changes after
              you’ve submitted the application.
              </Desc>
              <Flex pt={[15, 23, 33]} pb={[40, 55, 113]} justifyContent="center">
                <Button
                  bgColor={colors.yellow}
                  value="Review Application"
                  padding="12.5px 18px"
                  fontColor="#000"
                  onClick={e => preReview(e, `${url[0].nextGroupSlug}/${url[0].nextSubGroupSlug}`)}
                />
              </Flex>
              <Flex justifyContent="center">
                <Box width={6 / 7}>
                  <MultipleChoice
                    value
                    onChange={() => setIsChecked(!isChecked)}
                    optionSelected={isChecked}
                    label="I certify that the information I provided in the application is true and complete to the best of my knowledge."
                  />
                </Box>
              </Flex>
              <Flex py={25}>
                <Button
                  value={lockLoading ? <Loader /> : 'Lock and Submit'}
                  padding="12.5px 26.5px"
                  fontColor="#000"
                  bgColor={colors.yellow}
                  extraClass={`${isChecked && !lockLoading ? '' : '--disabled'} ${lockLoading ? '--no-interaction' : ''}`}
                  disabled
                  onClick={e => isChecked && preSubmit(e)}
                />
              </Flex>
            </>
            ) : ((inquiryState === 'IN_PROGRESS' || inquiryState === null) && dataPaid && dataPaid.checkIfApplicationIsPaid) ? (
              <>
                <Heading>Please complete and review your application.</Heading>
                <Flex justifyContent="center">
                  <Icon name="review-answers" width={100} height={100} />
                </Flex>
                <Desc>
                  After you complete your application, return to this page to review your answers.
                </Desc>
              </>
            ) : (
              <>
                {inquiry.free
                  ? <Heading>Please complete your application.</Heading>
                  : <Heading>Please complete your application and submit your payment.</Heading>
                }
                <Flex justifyContent="center">
                  <Icon name="review-answers" width={100} height={100} />
                </Flex>
                <Desc>
              After you complete your application, return to this page to review your answers.
                </Desc>
              </>
            ) }
      </ContainerApp>
    </>
  ) : null;
};

export default Review;
