/* eslint-disable react/require-default-props */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import FormInput from 'components/molecules/Form/FormInput';
import { AppContext } from 'context/appContext';
import { Password, Name } from 'components/organisms/Modals';
import PhoneInput from 'react-phone-input-2';
import MaskedInput from 'react-text-mask';

import 'react-phone-input-2/dist/style.css';
import {
  Container, Label, UpdateButton, Error,
} from './style';

const removeLetters = (e) => {
  const el = e.target;
  const { value } = el;

  const valueWithOutLetters = value.replace(/\D+/g, '');

  el.value = valueWithOutLetters;
};

const verifyInputType = (props) => {
  const {
    isDate,
    isNumber,
    isPhone,
    validatorRegister,
    validatorConditions,
    name,
    errors,
    type,
    minLength,
    customStyle,
  } = props;

  const isEmail = type === 'email';

  if (isDate) {
    validatorConditions.pattern = {
      value: /^((0?[0-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4})?$/,
      message: 'Invalid Date',
    };

    return (
      <MaskedInput
        mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        name={props.name}
        onChange={props.onChange}
        placeholder={props.placeholder}
        className="input-date"
        ref={inputRef => validatorRegister
          && validatorRegister(inputRef && inputRef.inputElement, validatorConditions)
        }
        defaultValue={props.value}
      />
    );
  }

  if (isPhone) {
    if (validatorConditions.required) {
      validatorConditions.minLength = {
        value: 8,
        message: 'Invalid Phone',
      };
    }

    return (
      <PhoneInput
        ref={(inputRef) => {

          if (inputRef) {
            inputRef.numberInputRef.name = props.name;
          }

          validatorRegister
            && validatorRegister(inputRef && inputRef.numberInputRef, validatorConditions);
        }}
        value={props.defaultValue}
        {...props}
      />
    );
  }

  if (isEmail) {
    if (validatorConditions) {
      validatorConditions.pattern = {
        value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Invalid Email',
      };
    }
  }

  if (minLength) {
    validatorConditions.pattern = {
      value: new RegExp(`^(.{${minLength},})?$`),
      message: `The field must be at least ${minLength} characters`,
    };
  }

  return (
    <FormInput
      {...props}
      validatorRegister={validatorRegister}
      // validatorConditions={validatorConditions}
      name={name}
      errors={errors}
      onInput={isNumber ? removeLetters : () => {}}
      isNumber={isNumber}
      customStyle={customStyle}
    />
  );
};

const ShortAnswer = (props) => {
  const {
    label, edit, editType, errors, name, errorMessage, isNumber, isDate, isPhone,
  } = props;
  const { dispatch } = useContext(AppContext);

  return (
    <Container errors={errors} name={name} isNumber={isNumber} isDate={isDate} isPhone={isPhone}>
      {label && <Label isNumber={isNumber}>{label}</Label>}

      {verifyInputType(props)}

      {errors && errors[name] && (
        <Error className="error-message">
          {`${errorMessage || errors[name].message || 'This field is required'}`}
        </Error>
      )}
      {edit && (
        <UpdateButton
          onClick={(e) => {
            dispatch({ type: 'setModalOpened', showModal: editType ? Password : Name });
          }}
        >
          {editType || 'edit'}
        </UpdateButton>
      )}
    </Container>
  );
};

ShortAnswer.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onInputChange: PropTypes.func,
  edit: PropTypes.bool,
  editType: PropTypes.string,
  customStyle: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ShortAnswer;
