import React, { useState, useEffect, useContext } from 'react';

import Pannel from 'components/molecules/Pannel';
import PannelLabel from 'components/molecules/Pannel/Label';
import FormName from 'components/molecules/Form/FormName';

import ApplicationList from 'components/organisms/ApplicationList';
import NextStepsList from 'components/organisms/NextStepsList';

import { hideIfIsPreview } from 'modules/utils';

import { AppContext } from 'context/appContext';

const PannelApplication = (props) => {
  const { state, dispatch } = useContext(AppContext);
  const [expand, setExpand] = useState(false);
  const { inquiry } = props;

  useEffect(() => {
    if (!inquiry) return;

    const { application } = inquiry;

    dispatch({ type: 'setProgress', progress: parseInt(application.progress, 10) });
    dispatch({ type: 'setPackageName', packageName: application.title });
  }, [inquiry, dispatch]);

  const { application, nextStep } = inquiry;
  const groups = application.body;

  // setSavingMessage('Application progress saved!');

  return groups.length ? (
    <Pannel onClick={() => setExpand(!expand)} open={expand}>
      <>
        <FormName name={application.title} />
        
        <PannelLabel name={`${state.progress || '0'}% done`} />
        {hideIfIsPreview(
          <PannelLabel name={state.savingStatus} />,
          // <PannelLabel name={`Last saved ${application.lastSaved || '0'} minutes ago`} />,
        )}

        <ApplicationList applicationSlug={application.slug} list={groups} status={application} />

        {hideIfIsPreview(
          <NextStepsList
            applicationSlug={application.slug}
            list={nextStep}
            bodyStatus={application.body}
          />,
        )}
      </>
    </Pannel>
  ) : (
    ''
  );
};

export default PannelApplication;
