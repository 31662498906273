import styled from 'styled-components';
import colors from 'modules/colors';

export const Title = styled.h3`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.greyDark};
  padding-bottom: 7px;
  border-bottom: 1px solid ${() => colors.greyDark}
`;
