import React from 'react';
import PropTypes from 'prop-types';
import { LabelCheckbox, Label } from './style';
import HelperText from 'components/molecules/HelperText';
import CustomCheckbox from 'components/molecules/CustomCheckbox';

const MultipleChoice = (props) => {
  const {
    label, pb, optionSelected, value, helperText
  } = props; // handleCheck

  return (
    <LabelCheckbox pb={pb}>
      <CustomCheckbox
        type="checkbox"
        {...props}
        checked={optionSelected === value}
      />
      <Label>{label}</Label>
      {helperText && <HelperText customClass="helper-text-container" text={helperText}/>}
    </LabelCheckbox>
  );
};

MultipleChoice.propTypes = {
  label: PropTypes.string.isRequired,
};

export default MultipleChoice;
