import React from "react";
import { logout } from "modules/utils";
import { Menu, MenuItem } from "./style";

const Dropdown = () => {
  return (
    <Menu className="submenu">
      <MenuItem to="/your-account">My Portal</MenuItem>
      <MenuItem to="/account-settings">Account Settings</MenuItem>
      <MenuItem to={window.location.pathname} onClick={() => logout()}>
        Logout
      </MenuItem>
    </Menu>
  );
};

export default Dropdown;
