import React, { useContext, useState } from 'react';
import { AppContext } from 'context/appContext';
import { Another } from './style';

const AllApplications = () => {
  const { state, dispatch } = useContext(AppContext);
  const [availableApplication, setAvailableApplication] = useState(false);

  return (
    <Another
      onClick={() => {
        setAvailableApplication(!availableApplication);
        dispatch({
          type: 'setAvailableApplications',
          showAvailableApplication: !availableApplication,
        });
      }}
    >
      {state.newApplication ? 'Hide Available Applications' : 'Start Another Application'}
    </Another>
  );
};

export default AllApplications;
