import React, { useContext, useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { EMAIL_US, REVISION_REQUEST } from 'graphql/application/mutations';
import { GET_ME } from 'graphql/user/queries';
import { GET_LINK_DOWNLOAD } from 'graphql/application/queries';
import { UPDATE_USER, UPDATE_PASSWORD, REQUEST_PASSWORD } from 'graphql/user/mutations';
import PasswordStrength from 'components/molecules/LoginRegisterBox/PasswordStrength';
import { verifyPasswordStrength, getUrlGroups, host } from 'modules/utils';
import colors from 'modules/colors';

import { AppContext } from 'context/appContext';
import { Box, Flex } from '@rebass/grid';
import RegisterBox from 'components/organisms/RegisterBox';
import LoginBox from 'components/organisms/LoginBox';
import Icon from 'components/atoms/Icon';
import MultipleChoice from 'components/molecules/Form/MultipleChoice';
import Error from 'components/molecules/Error';
import axios from 'axios';
import Address from '../Address';
import USAddress from '../USAddress';
import {
  ModalBlue,
  ModalBlueTitle,
  ModalBlueSubtitle,
  ModalWhite,
  ModalWhiteName,
  ModalWhiteTitle,
  Button,
  Pointer,
  ModalWhiteDesc,
  Form,
  FormGroup,
  Input,
  Email,
  Key,
  Eye,
  TextLink,
  ModalWhiteButton,
  ModalWhiteElegibleTitle,
  ModalWhiteElegibleDesc,
  Flag,
  ModalWhiteNotElegibleTitle,
  ModalWhiteNotElegibleDesc,
  ModalWhiteNotElegibleBox,
  ModalWhiteNotElegibleBoxDesc,
  Label,
  InputContact,
  TextareaContact,
  ModalWhiteButtonContact,
  ModalWhiteTextContact,
  ButtonWhite,
  ModalBlueSubtitleLink,
  ModalWhiteAttorneyDesc,
  ModalWhiteAttorneyBox,
  ButtonAttorney,
  ModalWhiteButtonResume,
  ModalWhiteTitleBorder,
  Cancel,
  Update,
  ModalWhiteDescPacket,
  ModalWhiteEmail,
  ModalWhiteDescDownload,
  ButtonDownload,
  CloseSign,
} from './style';

export const BeforeYouGo = () => {
  const { state, dispatch } = useContext(AppContext);
  return (
    <ModalBlue onClick={e => e.stopPropagation()}>
      <Flex
        justifyContent="flex-end"
        pt={17}
        px={20}
        onClick={() => dispatch({ type: 'setModalClosed' })}
      >
        <Pointer>
          <Icon width={15} height={15} name="close-white" />
        </Pointer>
      </Flex>
      <Flex alignItems="center" flexDirection="column">
        <Box width={[4 / 5, 3 / 4]} pt={[40, 60]} pb={48}>
          {state.isLeaving ? (
            <>
              <ModalBlueTitle>Before you go...</ModalBlueTitle>
              <ModalBlueSubtitle>Save your progress by creating a free account.</ModalBlueSubtitle>
            </>
          ) : (
            <ModalBlueTitle>Save your progress by creating a free account.</ModalBlueTitle>
          )}
          <Flex alignItems="center" flexDirection="column">
            <Icon width={150} height={150} name="signup" />
            <Button
              type="button"
              onClick={() => dispatch({
                type: 'setModalOpened',
                showModal: RegisterModal,
              })
              }
            >
              Create account
            </Button>
          </Flex>
        </Box>
      </Flex>
    </ModalBlue>
  );
};

export const ThankYou = () => {
  const { dispatch } = useContext(AppContext);
  return (
    <ModalBlue onClick={e => e.stopPropagation()}>
      <Flex alignItems="center" flexDirection="column">
        <Box width={3 / 4} pt={[40, 60]} pb={48}>
          <ModalBlueTitle>Thank you for creating an account.</ModalBlueTitle>
          <ModalBlueSubtitle>
            We’re excited to partner with you on your immigration journey.
          </ModalBlueSubtitle>
          <Flex alignItems="center" flexDirection="column">
            <Icon width={150} height={150} name="signup--done" />
            <Button type="button" onClick={() => dispatch({ type: 'setModalClosed' })}>
              Continue
            </Button>
          </Flex>
        </Box>
      </Flex>
    </ModalBlue>
  );
};

export const Password = () => {
  const { dispatch } = useContext(AppContext);

  const [passwordVisible, setPasswordVisible] = useState(true);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [oldPassValid, setOldPassValid] = useState(false);
  const [requested, setRequested] = useState(false);
  const [forgot, setForgot] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [email, setEmail] = useState('');

  const [sensitiveCases, setSensitiveCases] = useState({});

  const [updatePassword] = useMutation(UPDATE_PASSWORD, {
    update: (cache, { data }) => {
      setPasswordChanged(true);
    },
  });

  const [request] = useMutation(REQUEST_PASSWORD, {
    update: (cache, { data }) => {
      setRequested(data.requestResetPassword);
    },
  });

  function hideError() {
    setErrorMessage('');
  }

  async function preReset() {
    try {
      await updatePassword({
        variables: { user: { oldPassword, newPassword } },
      });
    } catch (error) {
      setOldPassValid(false);
      setErrorMessage(error.message.replace('GraphQL error: ', ''));
      setTimeout(() => {
        hideError();
      }, 4000);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    preReset();
  };

  async function handleRequest(e) {
    try {
      e.preventDefault();
      await request({ variables: { email } });
      hideError();
    } catch (error) {
      setErrorMessage(error.message.replace('GraphQL error: ', ''));

      setTimeout(() => {
        hideError();
      }, 4000);
    }
  };

  const forgotPass = (e) => {
    const form = document.getElementsByTagName('form')[0];
    if (form) form.reset();
  };

  const handleNewPassword = (password) => {
    setNewPassword(password);
    setSensitiveCases(verifyPasswordStrength(password));
  };

  useEffect(() => {
    forgotPass();
  });

  return (
    <ModalWhite onClick={e => e.stopPropagation()}>
      <Flex
        justifyContent="flex-end"
        pt={17}
        px={20}
        onClick={() => dispatch({ type: 'setModalClosed' })}
      >
        <Pointer>
          <Icon width={15} height={15} name="close" />
        </Pointer>
      </Flex>
      {forgot ? (
        <>
          <Flex pt={22} pb={43} px={31}>
            <ModalWhiteName>Forgot Password</ModalWhiteName>
          </Flex>
          <Flex justifyContent="center" pb={50}>
            <Box width={[4 / 5, 3 / 4]}>
              {requested ? (
                <>
                  <ModalWhiteDesc>
                    You should soon receive an email allowing you to reset your password. Please
                    make sure to check your spam and trash if you can't find the email.
                  </ModalWhiteDesc>
                  <ModalWhiteButton
                    type="button"
                    onClick={() => dispatch({ type: 'setModalClosed' })}
                  >
                    Return to Account
                  </ModalWhiteButton>
                </>
              ) : (
                <>
                  <ModalWhiteDesc>
                    To reset your password, enter your email address and we’ll send you a reset link.
                  </ModalWhiteDesc>
                  <Form onSubmit={handleRequest}>
                    {errorMessage && <Error>{errorMessage}</Error>}
                    <FormGroup>
                      <Input
                        type="email"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                      <Email>
                        <Icon width={21} height={16} name="email" />
                      </Email>
                    </FormGroup>
                    <ModalWhiteButton type="submit">Send via Email</ModalWhiteButton>
                  </Form>
                </>
              )}
            </Box>
          </Flex>
        </>
      ) : (
        <>
          <Flex pt={22} pb={passwordChanged ? 30 : 43} px={31}>
            <ModalWhiteName>Change Password</ModalWhiteName>
          </Flex>
          <Flex justifyContent="center" pb={50}>
            <Box width={[5 / 6, 4 / 5]}>
              {passwordChanged ? (
                <>
                  <ModalWhiteTitle>Your password has been changed.</ModalWhiteTitle>
                  <ModalWhiteDesc>
                    An email has been sent to you confirming this change.
                  </ModalWhiteDesc>
                  <ModalWhiteButton
                    type="button"
                    onClick={() => dispatch({ type: 'setModalClosed' })}
                  >
                    Return to Account
                  </ModalWhiteButton>
                </>
              ) : (
                <Box mt={-29}>
                  <Form onSubmit={handleSubmit}>
                    {errorMessage && <Error>{errorMessage}</Error>}
                    <FormGroup>
                      <Input
                        type={`${passwordVisible ? 'password' : 'text'}`}
                        placeholder="Old password"
                        required
                        minlength="6"
                        value={oldPassword}
                        onChange={e => setOldPassword(e.target.value)}
                      />
                      <Key>
                        <Icon width={20} height={20} name="key" />
                      </Key>
                      <Eye onClick={() => setPasswordVisible(!passwordVisible)}>
                        <Icon width={17} height={19} name="password-hidden" />
                      </Eye>
                    </FormGroup>
                    <TextLink
                      to="/"
                      onClick={(e) => {
                        e.preventDefault();
                        setForgot(true);
                      }}
                    >
                      Forgot password
                    </TextLink>
                    <Box mt={37}>
                      <FormGroup>
                        <Input
                          type={`${passwordVisible ? 'password' : 'text'}`}
                          placeholder="New password"
                          required
                          minlength="6"
                          value={newPassword}
                          onChange={e => handleNewPassword(e.target.value)}
                        />
                        <Key>
                          <Icon width={20} height={20} name="key" />
                        </Key>
                        <Eye onClick={() => setPasswordVisible(!passwordVisible)}>
                          <Icon width={17} height={19} name="password-hidden" />
                        </Eye>
                      </FormGroup>
                      <Box pt={12} mb={-25}>
                        <PasswordStrength
                          color={() => colors.fontColor}
                          sensitiveCases={sensitiveCases}
                        />
                      </Box>
                    </Box>

                    <ModalWhiteButton type="submit">
                      {oldPassValid ? 'Change Password' : 'Submit'}
                    </ModalWhiteButton>
                  </Form>
                </Box>
              )}
            </Box>
          </Flex>
        </>
      )}
    </ModalWhite>
  );
};

export const Name = () => {
  const { dispatch } = useContext(AppContext);
  const { data } = useQuery(GET_ME);
  const [name, setName] = useState('');
  const [nameChanged, setNameChanged] = useState(false);

  const [editName] = useMutation(UPDATE_USER, {
    update: (cache, { data }) => {
      setNameChanged(true);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    editName({ variables: { id: data.me._id, user: { name, email: data.me.email } } });
    dispatch({ type: 'setNewName', name });
  };

  return (
    <ModalWhite onClick={e => e.stopPropagation()}>
      <Flex
        justifyContent="flex-end"
        pt={17}
        px={20}
        onClick={() => dispatch({ type: 'setModalClosed' })}
      >
        <Pointer>
          <Icon width={15} height={15} name="close" />
        </Pointer>
      </Flex>
      <>
        <Flex pt={22} pb={43} px={31}>
          <ModalWhiteName>Change Name</ModalWhiteName>
        </Flex>
        <Flex justifyContent="center" pb={50}>
          <Box width={[5 / 6, 4 / 5]}>
            {nameChanged ? (
              <>
                <ModalWhiteTitle>Your name has been updated.</ModalWhiteTitle>
                <ModalWhiteButton
                  type="button"
                  onClick={() => dispatch({ type: 'setModalClosed' })}
                >
                  Return to Account
                </ModalWhiteButton>
              </>
            ) : (
              <>
                <ModalWhiteDesc>Update your name.</ModalWhiteDesc>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Input
                      type="text"
                      placeholder="Full Name"
                      required
                      minlength="6"
                      onChange={e => setName(e.target.value)}
                    />
                    <Key>
                      <Icon width={20} height={20} name="user" />
                    </Key>
                  </FormGroup>

                  <ModalWhiteButton type="submit">Submit</ModalWhiteButton>
                </Form>
              </>
            )}
          </Box>
        </Flex>
      </>
    </ModalWhite>
  );
};

export const Eligible = () => {
  const { dispatch } = useContext(AppContext);

  return (
    <ModalWhite onClick={e => e.stopPropagation()}>
      <Flex justifyContent="center" py={[35, 50]}>
        <Box width={[4 / 5, 3 / 4]}>
          <ModalWhiteElegibleTitle>
            You are eligible to apply for U.S. citizenship
          </ModalWhiteElegibleTitle>
          <Flag>
            <Icon width={100} height={100} name="usa-flag" />
          </Flag>
          <ModalWhiteElegibleDesc>
            Click “Continue” to proceed to your application
          </ModalWhiteElegibleDesc>
          <ModalWhiteButton type="button" onClick={() => dispatch({ type: 'setModalClosed' })}>
            Continue
          </ModalWhiteButton>
        </Box>
      </Flex>
    </ModalWhite>
  );
};

export const NotEligible = () => {
  const { dispatch } = useContext(AppContext);

  return (
    <ModalWhite onClick={e => e.stopPropagation()}>
      <Flex justifyContent="center" py={[35, 50]}>
        <Box width={[5 / 6, 4 / 5]}>
          <ModalWhiteNotElegibleTitle>We’re sorry.</ModalWhiteNotElegibleTitle>
          <ModalWhiteNotElegibleDesc>
            You are not eligible to apply with Legal Pilot at this time.
          </ModalWhiteNotElegibleDesc>
          <ModalWhiteNotElegibleBox mt={32}>
            <Box width={[1, 1 / 3, 1 / 4]}>
              <Icon width={100} height={100} name="speak" />
            </Box>
            <Flex
              justifyContent="center"
              width={[1, 2 / 3, 3 / 4]}
              pt={3}
              flexDirection="column"
              alignItems="center"
            >
              <ModalWhiteNotElegibleBoxDesc>
                If you’d like to speak with an attorney, check out our Legal Service Plan.
              </ModalWhiteNotElegibleBoxDesc>
              <ModalWhiteButton type="button" onClick={() => dispatch({ type: 'setModalClosed' })}>
                Learn More
              </ModalWhiteButton>
            </Flex>
          </ModalWhiteNotElegibleBox>
        </Box>
      </Flex>
    </ModalWhite>
  );
};

export const Contact = () => {
  const { data } = useQuery(GET_ME);
  const { state, dispatch } = useContext(AppContext);
  const [submitted, setSubmitted] = useState(false);
  const [isFilled, setIsFilled] = useState(true);

  const [form, setForm] = useState({
    Name: data ? data.me.name : '',
    Email: '',
    Subject: state.subject,
    Comment: '',
  });

  useEffect(() => {
    if (form.Name === '') {
      setForm({
        ...form,
        Name: data ? data.me.name : form.Name,
        Email: data ? data.me.email : form.Email,
      });
    }

    Object.values(form).map(f => (f === '' && f.length < 3 ? setIsFilled(false) : setIsFilled(true)));
  }, [form, data]);

  const [sendEmail] = useMutation(EMAIL_US, {
    update: (cache, { data }) => {
      setSubmitted(true);
    },
  });

  const [sendRevision] = useMutation(REVISION_REQUEST, {
    update: (cache, { data }) => {
      setSubmitted(true);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    for (let i = 0; i < e.target.elements.length; i++) {
      if (e.target.elements[i].value === '' && e.target.elements[i].hasAttribute('required')) {
        alert('There are some required fields!');
        return false;
      }
    }

    if (state.hubspot === 'EMAIL_US') {
      sendEmail({ variables: { form } });
    } else {
      sendRevision({ variables: { form } });
    }
  };

  return (
    <ModalWhite onClick={e => e.stopPropagation()}>
      <Flex
        justifyContent="flex-end"
        pt={17}
        px={20}
        onClick={() => dispatch({ type: 'setModalClosed' })}
      >
        <Pointer>
          <Icon width={15} height={15} name="close" />
        </Pointer>
      </Flex>
      <Flex justifyContent="center" pt={21} pb={33}>
        <Box width={8 / 9}>
          <ModalWhiteNotElegibleTitle>{state.modalTitle}</ModalWhiteNotElegibleTitle>
          {submitted ? (
            <>
              <Flex justifyContent="center" pt={[25, 42]} pb={[20, 32]}>
                <Box width={[1, 7 / 9]}>
                  <ModalWhiteTextContact>
                    Thank you for contacting support. We will review your request as soon as
                    possible and get back to you. Please check your email for additional
                    correspondence.
                  </ModalWhiteTextContact>
                </Box>
              </Flex>
              <Flex justifyContent="center" flexDirection="column" alignItems="center">
                <Icon width={100} height={100} name="speak" />
                <ButtonWhite onClick={() => dispatch({ type: 'setModalClosed' })}>
                  Close
                </ButtonWhite>
              </Flex>
            </>
          ) : (
            <form onSubmit={handleSubmit}>
              <Flex flexDirection="column">
                <Label htmlFor="name">Name*</Label>
                <InputContact
                  id="name"
                  type="text"
                  name="name"
                  required
                  onChange={e => setForm({ ...form, Name: e.target.value })}
                  value={form.Name}
                />
              </Flex>
              <Flex flexDirection="column">
                <Label htmlFor="email">Email*</Label>
                <InputContact
                  id="email"
                  type="email"
                  name="email"
                  required
                  onChange={e => setForm({ ...form, Email: e.target.value })}
                  value={form.Email}
                />
              </Flex>
              <Flex flexDirection="column">
                <Label htmlFor="email">Subject*</Label>
                <InputContact
                  id="subject"
                  type="text"
                  name="subject"
                  required
                  onChange={e => setForm({ ...form, Subject: e.target.value })}
                  value={form.Subject}
                />
              </Flex>
              <Flex flexDirection="column">
                <Label htmlFor="email">Question/Comment*</Label>
                <TextareaContact
                  id="subject"
                  type="text"
                  name="subject"
                  required
                  onChange={e => setForm({ ...form, Comment: e.target.value })}
                />
              </Flex>
              <ModalWhiteButtonContact type="submit" disabled={!isFilled}>
                Submit
                {' '}
                {state.modalTitle === 'Revision Request' && 'Request'}
              </ModalWhiteButtonContact>
            </form>
          )}
        </Box>
      </Flex>
    </ModalWhite>
  );
};

export const CallUs = () => {
  const { dispatch } = useContext(AppContext);

  return (
    <ModalWhite onClick={e => e.stopPropagation()}>
      <Flex
        justifyContent="flex-end"
        pt={17}
        px={20}
        onClick={() => dispatch({ type: 'setModalClosed' })}
      >
        <Pointer>
          <Icon width={15} height={15} name="close" />
        </Pointer>
      </Flex>
      <Flex pt={32} alignItems="center" flexDirection="column">
        <ModalWhiteNotElegibleTitle>Call Us</ModalWhiteNotElegibleTitle>
        <ModalBlueSubtitleLink href="tel:(619) 304-8464">(619) 304-8464</ModalBlueSubtitleLink>
      </Flex>
    </ModalWhite>
  );
};

export const Attorney = () => {
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    document.getElementById('resume').setAttribute('disabled', true);
  }, []);

  const handleCheck = (e) => {
    const resume = document.getElementById('resume');

    if (e.target.checked) {
      resume.removeAttribute('disabled');
    } else {
      resume.setAttribute('disabled', true);
    }
  };

  return (
    <ModalWhite onClick={e => e.stopPropagation()}>
      <Flex justifyContent="center" py={[35, 50]}>
        <Box width={5 / 6}>
          <ModalWhiteNotElegibleTitle>We want the best for you</ModalWhiteNotElegibleTitle>
          <Flex justifyContent="center" pt={[12, 19]}>
            <Box width={8 / 10}>
              <ModalWhiteAttorneyDesc>
                Based on your answer, we recommend you contact a legal professional before
                proceeding with your application.
              </ModalWhiteAttorneyDesc>
            </Box>
          </Flex>
          <ModalWhiteAttorneyBox mt={30} alignItems="center">
            <Box width={[1, 1 / 3, 1 / 4]}>
              <Icon width={100} height={100} name="speak" />
            </Box>
            <Flex
              justifyContent="center"
              width={[1, 2 / 3, 3 / 4]}
              flexDirection="column"
              alignItems="center"
            >
              <Box width={7 / 8}>
                <ModalWhiteNotElegibleBoxDesc>
                  We can help. With Legal Pilot’s Legal Service Plan, you can have a direct
                  consultation with a licensed attorney who can advise you.
                </ModalWhiteNotElegibleBoxDesc>
                <ButtonAttorney type="button" onClick={() => dispatch({ type: 'setModalClosed' })}>
                  Learn More
                </ButtonAttorney>
              </Box>
            </Flex>
          </ModalWhiteAttorneyBox>
          <Flex pt={[35, 50]} justifyContent="center">
            <ModalWhiteButtonResume
              type="button"
              id="resume"
              onClick={() => dispatch({ type: 'setModalClosed' })}
            >
              Resume Application
            </ModalWhiteButtonResume>
          </Flex>
          <Flex alignItems="center" justifyContent="center">
            <MultipleChoice
              label="I have spoken to an attorney and/or want to continue anyway."
              handleCheck={handleCheck}
            />
          </Flex>
        </Box>
      </Flex>
    </ModalWhite>
  );
};

export const UpdateAddress = () => {
  const { state } = useContext(AppContext);
  const { dispatch } = useContext(AppContext);
  let [updateView, setUpdateView] = useState(0);

  const handleClose = (ev) => {
    dispatch({ type: 'setModalClosed' });
    ev.preventDefault();
    return false;
  };

  const handleUpdate = (type, ev) => {
    if (type === 'billing') {
      dispatch({
        type: 'setBillingAddress',
        billingAddress: localStorage.getItem('address') ? JSON.parse(localStorage.getItem('address')) : '',
      });
    } else {
      dispatch({
        type: 'setMailingAddress',
        mailingAddress: localStorage.getItem('mailingAddress') ? JSON.parse(localStorage.getItem('mailingAddress')) : '',
      });
    }

    handleClose(ev);
    setUpdateView(updateView++);

    ev.preventDefault();
    return false;
  };

  const type = state.modalTitle === 'Update Mailing Address' ? 'mailing' : 'billing';

  return (
    <ModalWhite onClick={e => e.stopPropagation()}>
      <Flex justifyContent="center" pt={[35, 55]} flexDirection="column" alignItems="center">
        <Box width={5 / 9}>
          <ModalWhiteTitleBorder>{state.modalTitle}</ModalWhiteTitleBorder>
        </Box>
        <Flex pt={[10, 20]} pb={[27, 42]} width={1} alignItems="center" justifyContent="center">
          <Box width={[9 / 10, 8 / 10]} justifyContent="center">
            <form onSubmit={(ev) => handleUpdate(type, ev)}>
              <USAddress data={{ uid: state.modalUid }} type={type} />
              <Flex pt={[35, 56]} justifyContent="space-between">
                <Cancel onClick={handleClose}>Cancel</Cancel>
                <Update>Update</Update>
              </Flex>
            </form>
          </Box>
        </Flex>
      </Flex>
    </ModalWhite>
  );
};

export const Packet = () => {
  const { dispatch } = useContext(AppContext);
  const { data } = useQuery(GET_ME);
  return (
    <ModalWhite onClick={e => e.stopPropagation()}>
      <Flex alignItems="center" flexDirection="column">
        <Box width={3 / 4} pt={[40, 60]} pb={48}>
          <ModalWhiteNotElegibleTitle>Packet Sent</ModalWhiteNotElegibleTitle>
          <ModalWhiteDescPacket>
            Your full application packet has been sent to the following email address:
          </ModalWhiteDescPacket>
          <ModalWhiteEmail>{data && data.me.email}</ModalWhiteEmail>
          <Flex alignItems="center" flexDirection="column">
            <Icon width={100} height={100} name="package-sent" />
            <ButtonWhite onClick={() => dispatch({ type: 'setModalClosed' })}>Close</ButtonWhite>
          </Flex>
        </Box>
      </Flex>
    </ModalWhite>
  );
};

export const DownloadPacket = () => {
  const { dispatch } = useContext(AppContext);
  const path = getUrlGroups();
  const [url, setUrl] = useState('/');
  const [isLoading, setIsLoading] = useState(true);

  const { data: dataDownload } = useQuery(GET_LINK_DOWNLOAD, {
    variables: { slug: { inquirySlug: path.application } },
  });
  useEffect(() => {
    if (dataDownload) {
      axios
        .get(`${dataDownload.downloadApplication.link}`, {
          headers: {
            authorization: localStorage.getItem('token'),
            'Content-type': 'application/zip',
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          setUrl(window.URL.createObjectURL(new Blob([response.data])));
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
    }
  }, [dataDownload]);

  return (
    <ModalWhite onClick={e => e.stopPropagation()}>
      <Flex
        justifyContent="flex-end"
        pt={17}
        px={20}
        onClick={() => dispatch({ type: 'setModalClosed' })}
      >
        <Pointer>
          <Icon width={15} height={15} name="close" />
        </Pointer>
      </Flex>
      <Flex pt={16} pb={60} alignItems="center" flexDirection="column">
        <Box width={8 / 10}>
          <ModalWhiteNotElegibleTitle>Download Packet</ModalWhiteNotElegibleTitle>
          {isLoading ? (
            <ModalWhiteDescDownload>
              As soon as your packet is ready we will send it over via email. We can also try to
              download again in a few minutes
            </ModalWhiteDescDownload>
          ) : (
            <>
              <ModalWhiteDescDownload>
                Your file will be downloaded as a zip file. Once it's done downloading, double click
                on it to unzip (Mac users) or right click and select "extract all" (Windows users).
                All the PDFs associated with your application will be in this folder.
              </ModalWhiteDescDownload>
              <Flex flexDirection="column" alignItems="center">
                <Icon width={100} height={100} name="speak" />
                <ButtonDownload
                  href={url}
                  download={dataDownload && dataDownload.downloadApplication.name}
                  target="_blank"
                >
                  Download
                </ButtonDownload>
              </Flex>
            </>
          )}
        </Box>
      </Flex>
    </ModalWhite>
  );
};

export const RegisterModal = () => {
  const { dispatch } = useContext(AppContext);

  const closeModal = () => {
    dispatch({ type: 'setModalClosed', isModalOpened: false });
    dispatch({ type: 'setShowLogin', showLogin: true });
  };

  return (
    <ModalBlue onClick={e => e.stopPropagation()} className="modal--sign">
      <CloseSign onClick={closeModal}>
        <Icon width={15} height={15} name="close-white" />
      </CloseSign>
      <RegisterBox modal />
    </ModalBlue>
  );
};

export const LoginModal = () => {
  const { dispatch } = useContext(AppContext);

  const closeModal = () => {
    dispatch({ type: 'setModalClosed', isModalOpened: false });
    dispatch({ type: 'setShowLogin', showLogin: false });
  };

  return (
    <ModalBlue onClick={e => e.stopPropagation()} className="modal--sign">
      <CloseSign onClick={closeModal}>
        <Icon width={15} height={15} name="close-white" />
      </CloseSign>
      <LoginBox modal />
    </ModalBlue>
  );
};

export const PopupModal = ({title, description, close, imageServerName, buttonType, buttonLink, actions}) => {
  const { dispatch } = useContext(AppContext);

  async function onContinue() {
    switch (buttonType) {
      case 'close':
        await actions.setShouldShowPopupToFalse();
        dispatch({ type: 'setModalClosed' });
        break;

      case 'continue':
        await actions.setShouldShowPopupToFalse();
        actions.submit();
        dispatch({ type: 'setModalClosed' });
        break;

      case 'learnMore':
        await actions.setShouldShowPopupToFalse();
        window.open(buttonLink, '_blank').focus();
        break;
    }
  }

  return (
    <ModalWhite onClick={e => e.stopPropagation()}>
      {close && (
        <Flex
          justifyContent="flex-end"
          pt={17}
          px={20}
          onClick={() => dispatch({ type: 'setModalClosed' })}
        >
          <Pointer>
            <Icon width={15} height={15} name="close" />
          </Pointer>
        </Flex>
      )}
      <Flex justifyContent="center" py={[35, 50]}>
        <Box width={[4 / 5, 3 / 4]}>
          <ModalWhiteElegibleTitle>{title}</ModalWhiteElegibleTitle>
          <Flag>
            {imageServerName && (
              <img src={`${host().admin}/uploads/question/popup/${imageServerName}`} />
            )}
          </Flag>
          <ModalWhiteElegibleDesc>{description}</ModalWhiteElegibleDesc>
          <ModalWhiteButton type="button" onClick={onContinue}>
            {buttonType === 'learnMore' ? 'learn more' : buttonType}
          </ModalWhiteButton>
        </Box>
      </Flex>
    </ModalWhite>
  );
};

export const PopupModalDouble = ({title, description, boxMessage, close, imageServerName, buttonLink, checkText, actions}) => {
  const { dispatch } = useContext(AppContext);
  const [isChecked, setIsChecked] = useState(false)

  async function onContinue() {
    await actions.setShouldShowPopupToFalse();
    // actions.submit();
    dispatch({ type: 'setModalClosed' });
  }

  useEffect(() => {
    document.getElementById('resume').setAttribute('disabled', true);
  }, []);

  const handleCheck = (e) => {
    setIsChecked(!isChecked)
    const resume = document.getElementById('resume');

    if (e.target.checked) {
      resume.removeAttribute('disabled');
    } else {
      resume.setAttribute('disabled', true);
    }
  };

  return (
    <ModalWhite onClick={e => e.stopPropagation()}>
      {close && (
        <Flex
          justifyContent="flex-end"
          pt={17}
          px={20}
          onClick={() => dispatch({ type: 'setModalClosed' })}
        >
          <Pointer>
            <Icon width={15} height={15} name="close" />
          </Pointer>
        </Flex>
      )}
      <Flex justifyContent="center" py={[35, 50]}>
        <Box width={[5 / 6, 4 / 5]}>
          <ModalWhiteNotElegibleTitle>{title}</ModalWhiteNotElegibleTitle>
          <ModalWhiteNotElegibleDesc>{description}</ModalWhiteNotElegibleDesc>
          <ModalWhiteNotElegibleBox mt={32}>
            <Flex width={[1, 1 / 3, 1 / 4]}>
              {imageServerName && (
                <img width="100%" src={`${host().admin}/uploads/question/popup/${imageServerName}`} />
              )}
            </Flex>
            <Flex
              justifyContent="center"
              width={[1, 2 / 3, 3 / 4]}
              pt={3}
              flexDirection="column"
              alignItems="center"
            >
              <ModalWhiteNotElegibleBoxDesc>
                {boxMessage}
              </ModalWhiteNotElegibleBoxDesc>
              <ModalWhiteButton type="button" onClick={() => { window.open(buttonLink, '_blank').focus() }}>
                Learn More
              </ModalWhiteButton>
            </Flex>
          </ModalWhiteNotElegibleBox>
          <Flex pt={[35, 50]} justifyContent="center">
            <ModalWhiteButtonResume
              type="button"
              id="resume"
              onClick={onContinue}
            >
              Resume Application
            </ModalWhiteButtonResume>
          </Flex>
          <Flex alignItems="center" justifyContent="center">
            <MultipleChoice
              label={checkText}
              value
              onChange={handleCheck}
              optionSelected={isChecked}
            />
          </Flex>
        </Box>
      </Flex>
    </ModalWhite>
  );
};
