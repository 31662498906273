import styled from 'styled-components';
import colors from 'modules/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: ${({ isNumber, isDate, isPhone }) => (isNumber || isDate || isPhone ? '40%' : '100%')};
  padding-bottom: 5.5px;
  margin: 0 auto 17px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ errors, name }) => (errors && errors[name] ? colors.red : colors.grey)};

  .input-date {
    display: block;
    width: 100%;
    text-align: ${({ isDate }) => (isDate ? 'center' : 'left')};
    height: 22px;
    background: rgba(255, 223, 142, 0.1);
    color: ${() => colors.black};
    border: none;
    padding: 0 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  .react-tel-input input[type="tel"] {
    display: block;
    width: 100%;
    height: 22px;
    background: ${() => colors.white};
    color: ${() => colors.black};
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    &.invalid-number {
      border: 0px none transparent !important;
      background-color: transparent !important;
      border-left-color: transparent !important;
    }
  }

  .react-tel-input .flag-dropdown {
    background-color: ${() => colors.white};
    border: transparent;

    &:hover {
      .selected-flag {
        background-color: ${() => colors.white};
      }
    }
  }
}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Label = styled.label`
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  color: ${() => colors.blueDark};
  text-transform: uppercase;
  width: 200px;
  padding-right: 10px;
  border-right: 1px solid ${() => colors.blueDark};
`;

export const UpdateButton = styled.span`
  display: inline-block;
  min-width: 55px;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: ${() => colors.blue};
  cursor: pointer;
  text-align: right;
`;

export const Error = styled.div`
  position: absolute;
  left: calc(100% + 10px);
  width: fit-content !important;
  max-width: 160px;
  color: ${() => colors.error};
  background: ${() => colors.white};
  padding: 10px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid ${() => colors.grey};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  transition: 0.25s;

  @media (max-width: 1280px) {
    left: auto;
    top: calc(100% + 10px);
    right: 0;
    margin-right: 0 !important;
  }

  &:before {
    content: '';
    position: absolute;
    left: -5px;
    top: 12px;
    width: 8px;
    height: 8px;
    background-color: ${() => colors.white};
    border: 1px solid ${() => colors.grey};
    border-top-color: transparent;
    border-right-color: transparent;
    transform: rotate(45deg);

    @media (max-width: 1280px) {
      top: -5px;
      right: 10px;
      left: auto;
      border-bottom-color: transparent;
      border-top-color: ${() => colors.grey};
    }
  }
`;
