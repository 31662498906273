import styled from 'styled-components';
// import colors from 'modules/colors';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(27, 27, 27, 0.8);
  z-index: 100;

  @media (max-height: 780px) {
    overflow: auto;
  }
`;