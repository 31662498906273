import React, { useEffect } from 'react';
import { ApolloProvider } from 'react-apollo-hooks';
import { AppProvider } from 'context/appContext';
import { setContext } from 'apollo-link-context';
import { HttpLink, InMemoryCache, ApolloClient } from 'apollo-client-preset';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from "apollo-link-error";
import { removeHubSpot, isPreviewPage, host, logout } from 'modules/utils';

import GlobalStyle from 'modules/globals';
import Router from './router';

const App = () => {
  const uploadLink = createUploadLink({ uri: host().api });
  const httpLink = new HttpLink({ uri: host().api });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message === 'Not Authenticated' || message === 'User not found') logout();

        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      }
    );
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  useEffect(() => {
    if (isPreviewPage()) {
      removeHubSpot();
    }
  });

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');

    return {
      headers: {
        ...headers,
        authorization: token || '',
      },
    };
  });

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };

  const client = new ApolloClient({            
    link: authLink.concat(errorLink).concat(uploadLink, httpLink),
    cache: new InMemoryCache(),
    defaultOptions,
  });

  return (
    <ApolloProvider client={client}>
      <GlobalStyle />
      <AppProvider>
        <Router />
      </AppProvider>
    </ApolloProvider>
  );
};

export default App;
