import React from 'react';
import PageContainer from 'components/molecules/PageContainer';
import Hero from 'components/templates/Hero';
import ResetPassword from 'components/templates/ResetPassword';
import { Redirect } from 'react-router-dom';
import { isAuthenticated } from 'modules/utils';

export default () => {
  if (isAuthenticated()) {
    return <Redirect to="/account-settings" />;
  }

  return (
    <PageContainer display="inline-block">
      <Hero />
      <ResetPassword />
    </PageContainer>
  );
};
