import styled from 'styled-components';
import colors from '../../../modules/colors';

export const Loadeing = styled.div`
  border: 2px rgba(0, 0, 0, 0.12) solid;
  border-top: 2px ${() => colors.blueAliro} solid;
  border-radius: 50%;
  -webkit-animation: spCircRot 0.6s infinite linear;
  animation: spCircRot 0.6s infinite linear;
  width: 15px;
  height: 15px;
  margin: 0 auto;

  @keyframes spCircRot {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
