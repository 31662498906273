import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import AddressInput from 'algolia-places-react';
import HelperText from 'components/molecules/HelperText';
import MaskedInput from 'react-text-mask';

import { acceptValues } from 'modules/utils';

import 'react-phone-input-2/dist/style.css';
import {
  Container, Label, FormInputYellow, Border, Btn, Error, HiddenInput
} from './style';

const removeLetters = (e) => {
  const el = e.target;
  const { value } = el;

  const valueWithOutLetters = value.replace(/\D+/g, '');

  el.value = valueWithOutLetters;
};

const verifyInputType = (props, errorsState, setErrors) => {
  const {
    isPhone,
    isAddress,
    isDate,
    isNumber,
    isUsState,
    validatorRegister,
    validatorConditions,
    type,
    minLength,
    name,
  } = props;

  const isEmail = type === 'email';

  if (isPhone) {
    if (validatorConditions.required) {
      validatorConditions.minLength = {
        value: 8,
        message: 'Invalid Phone',
      };
    }

    return (
      <>
        <HiddenInput
          name={props.name}
          ref={inputRef => {
            if (!inputRef) return false;

            validatorRegister
              && validatorRegister(inputRef, validatorConditions)
          }}
          value={props.value}
          onChange={() => {}}
        />
        <PhoneInput
          disableAreaCodes
          {...props}
          onBlur={() => {
            setErrors({});
          }}
        />
      </>
    );
  }

  if (isAddress) {
    return (
      <AddressInput
        ref={inputRef => validatorRegister
          && validatorRegister(inputRef && inputRef.autocompleteElem, validatorConditions)
        }
        {...props}
      />
    );
  }

  if (isDate) {
    validatorConditions.pattern = {
      value: /^((0?[0-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4})?$/,
      message: 'Invalid Date',
    };

    return (
      <MaskedInput
        mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        {...props}
        maxLength=""
        className="input-date"
        ref={inputRef => validatorRegister
          && validatorRegister(inputRef && inputRef.inputElement, validatorConditions)
        }
      />
    );
  }

  if (isEmail) {
    validatorConditions.pattern = {
      value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Invalid Email',
    };
  }

  if (isUsState) {
    validatorConditions.validate = value => (acceptValues.includes(value.toLowerCase()) || value === '');

    if (errorsState && errorsState[name] && errorsState[name].type === 'validate') {
      errorsState[name].message = 'Invalid state name';
    }
  }

  if (minLength) {
    validatorConditions.pattern = {
      value: new RegExp(`^(.{${minLength},})?$`),
      message: `The field must be at least ${minLength} characters`,
    };
  }

  return (
    <FormInputYellow
      {...props}
      ref={validatorRegister && validatorRegister(validatorConditions)}
      onInput={isNumber ? removeLetters : () => {}}
    />
  );
};

const InputAnswer = (props) => {
  const {
    label,
    customStyle,
    isCode,
    helperText,
    onSubmit,
    errorMessage,
    setDisabled,
    errors,
    name,
  } = props;

  const [errorsState, setErrors] = useState(errors);

  useEffect(() => {
    setErrors(errors);
  }, [errors]);

  return (
    <Container
      customStyle={customStyle}
      className={isCode ? 'isCode' : ''}
      errors={errorsState}
      name={name}
    >
      <Label customStyle={customStyle}>
        {label}
        {helperText && <HelperText customClass="helper-text-container" text={helperText} />}
      </Label>
      <Border />

      {verifyInputType(props, errorsState, setErrors)}

      {errorsState && errorsState[name] && (
        
        <Error className="error-message">
          {`${errorMessage || errorsState[name].message || 'This field is required'}`}
        </Error>
      )}

      {isCode && (
        <Btn onClick={onSubmit} disabled={setDisabled}>
          Add
        </Btn>
      )}
    </Container>
  );
};

InputAnswer.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default InputAnswer;
