import React, { useContext, useEffect, useState } from 'react';
import PageTitle from 'components/molecules/PageTitle';
import { AppContext } from 'context/appContext';
import { Redirect } from 'react-router-dom';
import { isAuthenticated, getUrlGroups } from 'modules/utils';
import { GET_PAID } from 'graphql/application/queries';
import { useQuery } from 'react-apollo-hooks';
import Cart from './PackageBox';
import Checkout from './Checkout';
import Success from './Checkout/Success';

const Payment = (props) => {
  const { history, checkout } = props;
  const path = getUrlGroups();
  const { data: dataPaid } = useQuery(GET_PAID, {
    variables: { slug: { inquirySlug: path.application } },
  });

  const { state, dispatch } = useContext(AppContext);
  const [paid, setPaid] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (dataPaid) {
      dispatch({ type: 'setPaid', paid: dataPaid.checkIfApplicationIsPaid });
      setPaid(dataPaid.checkIfApplicationIsPaid);
      setLoaded(true);
    } else if (dataPaid === null) {
      dispatch({ type: 'setPaid', paid: false });
      setPaid(false);
      setLoaded(true);
    }
  }, [dataPaid, dispatch]);

  if (!isAuthenticated()) {
    return <Redirect to="/login" />;
  }

  return loaded ? (
    <>
      <PageTitle
        title={
          checkout || (dataPaid && !dataPaid.checkIfApplicationIsPaid)
            ? 'CHECKOUT'
            : state.paid || (dataPaid && dataPaid.checkIfApplicationIsPaid)
              ? 'PAYMENT COMPLETE'
              : 'SELECT PACKAGE'
        }
        icon="package"
      />
      {checkout ? <Checkout history={history} /> : state.paid || paid ? <Success /> : <Cart history={history} />}
    </>
  ) : null;
};

export default Payment;
