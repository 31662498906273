import React from 'react';
import PropTypes from 'prop-types';
import { Description } from './style';

const PageDescription = (props) => {
  const { description, size } = props;

  return <Description size={size}>{description}</Description>;
};

PageDescription.propTypes = {
  description: PropTypes.string.isRequired,
  size: PropTypes.number,
};

export default PageDescription;
