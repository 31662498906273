import styled from 'styled-components';
import { Box, Flex } from '@rebass/grid/emotion';

export const Container = styled(Box)`
  /* width: 100%;
  height: 100%; */
  padding: 84px 0;

  /* @ */
`;

export const Wrapper = styled(Flex)`
  width: 100%;
  max-width: 665px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    padding: 0 5%;
  }
`;
