import styled from 'styled-components';
import colors from 'modules/colors';

export const Question = styled.h3`
  display: flex;
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.fontColor};
  margin: 0 0 20px;

  &.mb-30 {
    margin: 0 0 35px
  }
`;
