import styled from 'styled-components';
import colors from 'modules/colors';
import { Flex } from "@rebass/grid/emotion";

export const Container = styled.div`
  padding-left: 30px;
  margin-bottom: 66px;
`;

export const Description = styled.p`
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: ${() => colors.black};
  margin-bottom: 14px;
  font-weight: 500;
`;

export const Row = styled(Flex)`
    padding: 20px 0 14px;
    border-top: 1px solid ${() => colors.grey};
    max-width: 205px;

    &.--no-border {
        border-top: 0;
    }
`;

export const Item = styled.p`
    color: ${() => colors.black};
    font-size: 16px;
    line-height: 18px;
`;

export const Bold = styled(Item)`
    font-weight: bold;
`;
