import React from 'react';
import Logo from 'components/molecules/Logo';
import NavbarUser from 'components/molecules/NavbarUser';
import Icon from 'components/atoms/Icon';
import { Navbar, Link, Container, TextLink } from './style';

export default () => {
  const hasToken = localStorage.getItem('token');

  return (
    <Navbar>
      <Link href="https://www.legalpilot.io" target="_blank">
        <Logo width={129} height={50} />
      </Link>
      {/* <NavbarUser /> */}
      { hasToken ? (
        <NavbarUser />
      ) : (
        <Container>
          <TextLink to="/login">Register/Log In</TextLink>
          <Icon width={30} height={30} name="user-outline" />
        </Container>
      )}
    </Navbar>
  );
}
