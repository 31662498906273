import React, { useContext, useEffect, useState } from 'react';
import Title from 'components/molecules/Checkbox/Title';
import PersonalInfo from 'components/molecules/Checkbox/PersonalInfo';
import Address from 'components/molecules/Checkbox/Address';
import { Flex } from '@rebass/grid/emotion';
import { UpdateAddress } from 'components/organisms/Modals';
import { AppContext } from 'context/appContext';

import { GET_ME } from 'graphql/user/queries';
import { useQuery } from 'react-apollo-hooks';

const YourInfo = () => {
  const { data } = useQuery(GET_ME);
  const { dispatch, state } = useContext(AppContext);
  const [billing, setBilling] = useState('');
  const [mailing, setMailing] = useState('');

  useEffect(() => {
    if (data) dispatch({ type: 'setEmail', email: data.me.email });
  }, [data, dispatch]);

  useEffect(() => {
    const { billingAddress, mailingAddress } = state;
    if (billingAddress && mailingAddress) {
      setBilling(billingAddress[0]);
      setMailing(mailingAddress[0]);
    }
  }, [state]);

  if (!data) {
    return <div />;
  }

  const choicesBiling = billing && billing.choices;
  const choicesMailing = mailing && mailing.choices;

  const hasAddress = choicesBiling && choicesBiling.find(choice => choice.value !== '');
  const hasMailingAddress = choicesMailing && choicesMailing.find(choice => choice.value !== '');

  return (
    data && (
      <>
        <Title title="YOUR INFO" />
        <Flex pt={42} pb={26} flexDirection="column">
          <PersonalInfo label="Full Name" value={data.me.name} />
          <PersonalInfo label="Email" value={data.me.email} />
          <Address
            label="Billing Address"
            value={
              hasAddress
                ? `${choicesBiling[0].value || ''} ${choicesBiling[4].value || ''} ${choicesBiling[5].value || ''} ${
                  choicesBiling[6].value || ''
                }, ${choicesBiling[7].value || ''} ${choicesBiling[8].value || ''} ${
                  choicesBiling[12] ? choicesBiling[12].value || '' : choicesBiling && choicesBiling[9].value || ''
                }`
                : ''
            }
            edit={() => dispatch({
              type: 'setModalOpened',
              showModal: UpdateAddress,
              modalTitle: 'Update Billing Address',
              modalUid: 'billing_address',
            })
            }
          />
          <Address
            label="Mailing Address"
            value={
              hasMailingAddress
                ? `${choicesMailing[0].value || ''} ${choicesMailing[4].value || ''} ${
                  choicesMailing[5].value || ''
                } ${choicesMailing[6].value || ''}, ${choicesMailing[7].value || ''} ${
                  choicesMailing[8].value || ''
                } ${
                  choicesMailing[12]
                    ? choicesMailing[12].value || ''
                    : choicesMailing && choicesMailing[9].value || ''
                }`
                : ''
            }
            edit={() => dispatch({
              type: 'setModalOpened',
              showModal: UpdateAddress,
              modalTitle: 'Update Mailing Address',
              modalUid: 'mailing_address',
            })
            }
          />
        </Flex>
      </>
    )
  );
};

export default YourInfo;
