import React, { useState, useContext } from 'react';
import { AppContext } from 'context/appContext';
import { Redirect } from 'react-router-dom';
import BoxSign from 'components/molecules/LoginRegisterBox';
import Title from 'components/molecules/LoginRegisterBox/Title';
import Form from 'components/molecules/LoginRegisterBox/Form';
import FormItem from 'components/molecules/LoginRegisterBox/FormItem';
import Input from 'components/molecules/LoginRegisterBox/Input';
// import ForgotPassword from 'components/molecules/LoginRegisterBox/ForgotPassword';

import Error from 'components/molecules/Error';
import { RegisterModal } from 'components/organisms/Modals';

import { Box } from '@rebass/grid/emotion';
import Button from 'components/molecules/Button';

import { useMutation } from 'react-apollo-hooks';
import { SIGN_IN, REQUEST_PASSWORD } from 'graphql/user/mutations';
import { signin } from 'modules/utils';
import { Forgot, Text, SignUp, Sign } from './style';

const LoginBox = ({ modal }) => {
  const { state, dispatch } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [requestPassword, setRequestPassword] = useState(false);
  const [requested, setRequested] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLogged, setIsLogged] = useState(false);
  const [login] = useMutation(SIGN_IN, {
    update: (cache, { data }) => {
      signin(data.login);
      setIsLogged(true);
    },
  });
  const [request] = useMutation(REQUEST_PASSWORD, {
    update: (cache, { data }) => {
      setRequested(data.requestResetPassword);
    },
  });

  function hideError() {
    setErrorMessage('');
  }

  async function preSignIn(e) {
    try {
      e.preventDefault();
      await login({ variables: { credentials: { email, password } } });
      hideError();
    } catch (error) {
      setErrorMessage(error.message.replace('GraphQL error: ', ''));

      setTimeout(() => {
        hideError();
      }, 4000);
    }
  }

  async function preRequest(e) {
    try {
      e.preventDefault();
      await request({ variables: { email } });
      hideError();
    } catch (error) {
      setErrorMessage(error.message.replace('GraphQL error: ', ''));

      setTimeout(() => {
        hideError();
      }, 4000);
    }
  }

  return isLogged ? (
    <Redirect to={`/your-account${window.location.search}`} />
  ) : (
    <>
      {login && (
        <Box
          width={[1, 1, modal ? 1 : 1 / 2]}
          className={`h100 ${state.showLogin ? 'showLogin' : 'hideLogin'}`}
        >
          <BoxSign>
            <Title
              title={
                requestPassword
                  ? 'Forgot your password? Type your email.'
                  : 'Already have an account? Sign In.'
              }
            />
            {errorMessage && <Error>{errorMessage}</Error>}
            <Form onSubmit={requestPassword ? !requested && preRequest : preSignIn}>
              <Box width={1}>
                {requested ? (
                  <Text>
                    You should soon receive an email allowing you to reset your password. Please
                    make sure to check your spam and trash if you can't find the email.
                  </Text>
                ) : (
                  <FormItem>
                    <Input
                      type="text"
                      placeholder="Email"
                      value={email}
                      onChange={ev => setEmail(ev.target.value)}
                    />
                  </FormItem>
                )}

                {!requestPassword && (
                  <FormItem>
                    <Input
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={ev => setPassword(ev.target.value)}
                    />
                  </FormItem>
                )}

                <Forgot
                  to="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setRequestPassword(!requestPassword);
                    setRequested(false);
                  }}
                >
                  {requestPassword ? 'Sign In' : 'Forgot your password?'}
                </Forgot>
              </Box>

              <Box width={1} alignSelf="flex-end">
                {requestPassword ? (
                  !requested && (
                    <Button
                      value="Request Password"
                      extraClass="secondary --bg-yellow"
                      fixedWidth="187px"
                      type="submit"
                    />
                  )
                ) : (
                  <Button
                    value="Sign In"
                    extraClass="secondary --bg-yellow"
                    fixedWidth="152px"
                    type="submit"
                  />
                )}
              </Box>
              <Sign
                mt="4"
                flexDirection="column"
                onClick={() => {
                  dispatch({ type: 'setShowLogin', showLogin: false });
                  window.location.pathname !== '/login'
                    && dispatch({ type: 'setModalOpened', showModal: RegisterModal });
                }}
                className={`sign ${modal && 'sign--modal'}`}
              >
                <SignUp>Don’t have an account?</SignUp>
                <SignUp yellow="true">Register</SignUp>
              </Sign>
            </Form>
          </BoxSign>
        </Box>
      )}
    </>
  );
};

export default LoginBox;
