import gql from 'graphql-tag';

export const EMAIL_US = gql`
  mutation sendEmailUs($form: FormInput) {
    sendEmailUs(form: $form)
  }
`;

export const REVISION_REQUEST = gql`
  mutation sendRevisionRequest($form: FormInput) {
    sendRevisionRequest(form: $form)
  }
`;

export const SAVE_ANSWER = gql`
  mutation saveAnswer($answer: SaveAnswerInput) {
    saveAnswer(answer: $answer) {
      progress
      state
    }
  }
`;

export const SAVE_ALL_ANSWERS = gql`
  mutation saveAllAnswers($answers: [SaveAnswerInput]) {
    saveAllAnswers(answers: $answers) {
      progress
      state
      body {
        title
        slug
        description
        locked
        items {
          title
          slug
          type
          description
          nextGroupSlug
          nextSubGroupSlug
          items {
            _id
            type
            template
            required
            title
            choices {
              _id
              type
              text
              label
              value
            }
          }
        }
      }
    }
  }
`;

export const DELETE_ANSWER = gql`
  mutation deleteAnswer($slug: FinishAnswerInput) {
    deleteAnswer(slug: $slug)
  }
`;

export const FINISH_ANSWER = gql`
  mutation finishAnswer($slug: FinishAnswerInput) {
    finishAnswer(slug: $slug)
  }
`;

export const APPLY_PAYMENT = gql`
  mutation applyPayment($packageId: ID!, $payment: PaymentInput!) {
    applyPayment(packageId: $packageId, payment: $payment) {
      status
      success
      error
    }
  }
`;

export const UPLOAD_FILES = gql`
  mutation uploadDocument($document: DocumentInput, $file: Upload!) {
    uploadDocument(document: $document, file: $file) {
      filename
      uid
      original
    }
  }
`;

export const DELETE_FILES = gql`
  mutation deleteUserDocument($document: DeleteDocumentInput!) {
    deleteUserDocument(document: $document)
  }
`;

export const RESEND_EMAIL = gql`
  mutation resendApplicationEmail($slug: ApplicationInput!) {
    resendApplicationEmail(slug: $slug)
  }
`;

export const START_REVIEW = gql`
  mutation startReview($slug: String!) {
    startReview(slug: $slug) {
      body {
        title
        slug
        description
        locked
        items {
          title
          slug
          type
          description
          nextGroupSlug
          nextSubGroupSlug
          items {
            _id
            type
            template
            required
            title
            choices {
              _id
              type
              text
              label
              value
            }
          }
        }
      }
    }
  }
`;

export const LOCK_DOCUMENTS = gql`
  mutation lockUserDocuments($slug: DocumentSlugInput) {
    lockUserDocuments(slug: $slug)
  }
`;

export const GENERATE_APPLICATIONS = gql`
  mutation generateApplication($slug: ApplicationInput!) {
    generateApplication(slug: $slug)
  }
`;
