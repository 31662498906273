import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/atoms/Icon';
import HelperText from 'components/molecules/HelperText';
import {
  Container, Label, Border, Select, Arrow, Error
} from './style';

const DropdownList = (props) => {
  const { label, options, optionSelected, defaultOption, helperText, validatorRegister, validatorConditions, errors, name, errorMessage } = props;

  return (
    <Container>
      <Label>{label}
        {helperText && <HelperText customClass="helper-text-container" text={helperText}/>}
      </Label>
      <Border />
      <Select {...props} ref={(inputRef) => validatorRegister && validatorRegister(inputRef, validatorConditions)} defaultValue={`${optionSelected ? optionSelected : defaultOption ? defaultOption : 'Select...'}`}>
        <option value="">{defaultOption ? defaultOption : 'Select...'}</option>
        {options.map((opt, index) => (
          <option key={index}>{opt}</option>
        ))}
      </Select>
      {errors && errors[name] && <Error className="error-message">{`${errorMessage ? errorMessage : 'This field is required'}`}</Error>}
      <Arrow>
        <Icon width={15} height={9} name="arrow-bottom" />
      </Arrow>
    </Container>
  );
};

DropdownList.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default DropdownList;
