import styled from 'styled-components';

export const ErrorMessage = styled.p`
  color: #721c24;
  background-color: #f8d7da;
  position: relative;
  padding: 10px 17px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  max-width: 433px;
  width: 100%;
  margin: 0 auto 20px;
`;
