import styled from "styled-components";
import colors from "modules/colors";
import { Flex } from "@rebass/grid";

export const InputGroup = styled(Flex)`
  border-bottom: 1px solid ${() => colors.grey};

  @media (max-width: 350px) {
    flex-wrap: wrap;
  }
`;
