import styled from 'styled-components';
import colors from 'modules/colors';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  max-width: 570px;
  margin: 0 auto;
`;

export const Title = styled.h3`
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${() => colors.blueAliro};
  margin-bottom: 15px;

  &:after {
    content: '';
    display: block;
    width: 100%;
    max-width: 290px;
    height: 1px;
    background-color: ${() => colors.blueLight};
    margin-top: 11px;
  }
`;

export const List = styled.div`
  width: 100%;
`;

export const ListItem = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Button = styled.button`
  background: ${() => colors.yellow};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: ${() => colors.fontColor};
  width: 46px;
  height: 21px;
  line-height: 21px;
  margin-right: 15px;
`;

export const Text = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.black};
`;

export const Strong = styled.strong``;
