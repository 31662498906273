import styled from "styled-components";
import { Box } from "@rebass/grid/emotion";

export const Relative = styled(Box)`
  position: relative;
  height: 36.49px;
`

export const Check = styled.div`
  position: absolute;
  right: -12px;
  top: 50%;
  margin-top: -8px;
`;
