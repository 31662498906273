import styled from 'styled-components';
import colors from 'modules/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 580px;
  padding-bottom: 20px;
  margin-bottom: 80px;
  border-bottom: 1px solid ${() => colors.blueAliro};
`;

export const ContainerIcon = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${() => colors.blueDark};
  margin-right: 15px;
`;

export const Title = styled.h1`
  display: block;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  color: ${() => colors.blueDark};
  text-transform: uppercase;
  margin: 0;

  @media (max-width: 52em) {
    font-size: 26px;
    line-height: 33px;
  }
`;
