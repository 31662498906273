import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 17px;

  &:last-child {
    margin-bottom: 0;
  }
`;
