import styled from 'styled-components';
import colors from 'modules/colors';

export const Text = styled.span`
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  color: ${({ yellow }) => (yellow ? colors.yellow : colors.white)};
  cursor: pointer;
`;

export const Agreement = styled.p`
  color: ${() => colors.white};
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 12px;
`;

export const Link = styled.a`
  color: ${() => colors.blueLight};
  font-size: 11px;
  line-height: 13px;
`;
