import styled from "styled-components";
import colors from "modules/colors";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 5.5px;
  border-bottom: 1px solid ${() => colors.grey};
  margin-bottom: 10px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: ${() => colors.blueDark};
  width: 162px;
  min-width: 108px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Border = styled.span`
  height: 17px;
  width: 1px;
  border-left: 1px solid ${() => colors.greyDark};
  margin-left: 21px;
  margin-right: 11px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const Select = styled.select`
  background-color: rgba(255, 223, 142, 0.1);
  padding: 7.5px 10px;
  width: 100%;
  appearance: none;
  border: none;
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  border-radius: 0;
  border-color: ${() => colors.red};
  border-width: 1px;
  border-style: ${({ errors, name }) => (errors && errors[name] ? 'solid' : 'none')};

  @media (max-width: 767px) {
    margin-right: 0;
  }
`;

export const Arrow = styled.div`
  position: absolute;
  right: 20px;

  @media (max-width: 767px) {
    bottom: 13.5px;
  }
`;

export const Error = styled.div`
  position: absolute;
  left: calc(100% + 10px);
  width: fit-content !important;
  max-width: 160px;
  color: ${() => colors.error};
  background: ${() => colors.white};
  padding: 10px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid ${() => colors.grey};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  transition: .25s;

  @media (max-width: 1280px) {
    left: auto;
    top: calc(100% + 10px);
    right: 0;
    margin-right: 0 !important;
  }

  &:before {
    content: '';
    position: absolute;
    left: -5px;
    top: 12px;
    width: 8px;
    height: 8px;
    background-color: ${() => colors.white};
    border: 1px solid ${() => colors.grey};
    border-top-color: transparent;
    border-right-color: transparent;
    transform: rotate(45deg);
    
    @media (max-width: 1280px) {
      top: -5px;
      right: 10px;
      left: auto;
      border-bottom-color: transparent;
      border-top-color: ${() => colors.grey};
    }
  }
`;
