import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/atoms/Icon';
import { Container, Text, ContainerIcon } from './style';

const Button = (props) => {
  const {
    value,
    onClick,
    extraClass,
    fixedWidth,
    arrowRight,
    arrowLeft,
    removeLeft,
    to,
    margin,
    padding,
    bgColor,
    fontColor,
    type,
  } = props;

  return (
    <Container
      type={type || 'button'}
      onClick={onClick}
      className={extraClass}
      fixedWidth={fixedWidth}
      to={to}
      margin={margin}
      padding={padding}
      bgColor={bgColor}
    >
      {removeLeft && (
        <ContainerIcon orientation="left">
          <Icon name="close-delete" width={28} height={29} />
        </ContainerIcon>
      )}
      {arrowLeft && (
        <ContainerIcon orientation="left">
          <Icon name="arrow-left" width={16} height={14} />
        </ContainerIcon>
      )}
      <Text className="button-text" fontColor={fontColor}>
        {value}
      </Text>
      {arrowRight && (
        <ContainerIcon orientation="right">
          <Icon name="arrow-right" width={16} height={14} />
        </ContainerIcon>
      )}
    </Container>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  extraClass: PropTypes.string,
  fixedWidth: PropTypes.string,
  arrowRight: PropTypes.bool,
};

export default Button;
