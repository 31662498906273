import styled from 'styled-components';
import colors from 'modules/colors';
import { Link } from 'react-router-dom';

export const Menu = styled.div`
  display: none;
  padding: 20px;
  position: absolute;
  top: 70px;
  right: 20px;
  z-index: 5;
  background-color: ${() => colors.white};
  min-width: 165px;

  border: 1px solid ${() => colors.grey};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const MenuItem = styled(Link)`
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.black};
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;
