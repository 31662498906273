import React from 'react';
import PropTypes from 'prop-types';
import ListName from 'components/molecules/Pannel/ListName';
import Icon from 'components/atoms/Icon';
import { Container, OnlyMobile } from './style';

const PannelList = (props) => {
  const { name, children, arrow } = props;

  return (
    <Container>
      <ListName name={name} />
      {children}
      {arrow && (
        <OnlyMobile>
          <Icon width={15} height={15} name="arrow-bottom" />
        </OnlyMobile>
      )}
    </Container>
  );
};

PannelList.propTypes = {
  name: PropTypes.string.isRequired,
};

export default PannelList;
