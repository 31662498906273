import styled from 'styled-components';
import colors from 'modules/colors';

export const Container = styled.div`
  background-color: ${() => colors.yellow};
  padding: 13px;
  position: absolute;
  top: 27px;
  left: 0;
  width: 100%;
  text-align: center;

  @media (max-width: 63.99em) {
    top: 0;
  }
`;

export const Text = styled.p`
  color: ${() => colors.fontColor};
  font-size: 16px;
  line-height: 20px;
`;

export const Bold = styled.span`
  font-weight: bold;
`;
