import React from 'react';
import PageContainer from 'components/molecules/PageContainer';
import ActivateAccountContainer from 'components/templates/ActivateAccountContainer';
import PannelHelp from 'components/templates/PannelHelp';

export default () => (
  <PageContainer>
    <ActivateAccountContainer />
    <PannelHelp />
  </PageContainer>
);
