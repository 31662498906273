import React, { useEffect, useContext, useState } from 'react';
import { AppContext } from 'context/appContext';
import Title from 'components/molecules/Checkbox/Title';
import InputAnswer from 'components/molecules/Form/InputAnswer';
import { Flex, Box } from '@rebass/grid/emotion';
import Icon from 'components/atoms/Icon';
import SecurePayment from 'assets/images/secure-payment.png';
import { Number, Cvc, Expiration } from 'react-credit-card-primitives';

import { GET_ME } from 'graphql/user/queries';
import { useQuery } from 'react-apollo-hooks';

import { Relative, Check } from './style';
import { func } from 'prop-types';

const CardInfo = () => {
  const { data } = useQuery(GET_ME);
  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    const { name, number, cvv, expiration } = state.creditCardValid;
    let isCardValid = false;

    if (name && number && cvv && expiration) isCardValid = true;

    dispatch({ type: 'setConfirmPayment', isCardValid });
  }, [state.creditCardValid, dispatch]);

  useEffect(() => {
    dispatch({ type: 'setCreditCard', cvv: '' });
    dispatch({ type: 'setCardValidationCvv', cvv: false })
  }, []);

  function formatExpiration(value) {
    if (value.length <= 2) return value;
    if (value.length >= 3) return value.slice(0,2)+' / '+value.slice(2,4);
   }

  function getExpirationValue() {
    return formatExpiration(state.creditCard.expiration);
  }

  function validadeCardName(name) {
    if (!name) return false;
    const arrName = name.split(' ');
    const lastNameLength = arrName.length-1;
    if (arrName.length < 2) return false;
    if (arrName[0].length < 3) return false;
    if (arrName[lastNameLength].length < 3) return false;
    return true;
  }

  if (!data) {
    return <div />;
  }

  return (
    data && (
      <>
        <Title title="CARD INFO" />
        <Flex pt={17} flexDirection="column">
          <Box pl={9} pb={9}>
            <Relative>
              <InputAnswer
                label="Name on Card"
                placeholder="Name on Card"
                customStyle={{
                  width: '99px',
                  paddingRight: '10px',
                  fontSize: '12px',
                  lineHeight: '15px',
                }}
                type="text"
                value={state.creditCard.nameOnCard}
                onChange={e => {
                  const finalValue = e.target.value.replace(/[0-9]/g, '');
                  dispatch({ type: 'setCreditCard', name: finalValue })
                  dispatch({ type: 'setCardValidationName', name: validadeCardName(finalValue) })
                }}
                autocomplete="cc-name"
              />

              {state.creditCardValid.name && (
                <Check>
                  <Icon width={16} height={16} name="checkmark-circle" />
                </Check>
              )}
            </Relative>
          </Box>
          <Box pl={9} pb={9}>
            <Relative>
              <Number
                masked
                onChange={({ value, valid }) => {
                  dispatch({ type: 'setCreditCard', cardNumber: value });
                  dispatch({ type: 'setCardValidationNumber', number: valid })
                }}
                value={state.creditCard.cardNumber}
                render={({ getInputProps }) => (
                  <InputAnswer
                    {...getInputProps()}
                    label="Card Number"
                    customStyle={{
                      width: '105px',
                      fontSize: '12px',
                      lineHeight: '15px',
                      margin: '0',
                    }}
                  />
                )}
              />
              {state.creditCardValid.number && (
                <Check>
                  <Icon width={16} height={16} name="checkmark-circle" />
                </Check>
              )}
            </Relative>
          </Box>
          <Box pl={9} pb={9}>
            <Relative>
              <Cvc
                masked
                onChange={({ value, valid }) => {
                  const finalValue = value.replace(/\D/g,'');
                  dispatch({ type: 'setCreditCard', cvv: finalValue });
                  dispatch({ type: 'setCardValidationCvv', cvv: valid })
                }}
                value={state.creditCard.cvv}
                render={({ getInputProps }) => (
                  <InputAnswer
                    {...getInputProps()}
                    label="CVV"
                    customStyle={{
                      width: '105px',
                      fontSize: '12px',
                      lineHeight: '15px',
                      margin: '0',
                    }}
                  />
                )}
              />
              {state.creditCardValid.cvv && (
                <Check>
                  <Icon width={16} height={16} name="checkmark-circle" />
                </Check>
              )}
            </Relative>
          </Box>
          <Box pl={9} pb={9}>
            <Relative>
              <Expiration
                masked
                onChange={({ rawValue, valid }) => {
                  const finalValue = rawValue.replace(/\D/g,'');
                  dispatch({ type: 'setCreditCard', expiration: finalValue });
                  dispatch({ type: 'setCardValidationExpiration', expiration: valid })
                }}
                value={state.creditCard.expiration}
                render={({ getInputProps }) => {
                  return (
                    <InputAnswer
                      {...getInputProps()}
                      label="Expiration"
                      maxLength="7"
                      value={getExpirationValue()}
                      customStyle={{
                        width: '105px',
                        fontSize: '12px',
                        lineHeight: '15px',
                        margin: '0',
                      }}
                    />
                  )}
                }
              />
              {state.creditCardValid.expiration && (
                <Check>
                  <Icon width={16} height={16} name="checkmark-circle" />
                </Check>
              )}
            </Relative>
          </Box>
          <Flex justifyContent="center">
            <Box width={8 / 9}>
              <Flex justifyContent="space-between" pb="18">
                <Icon name="visa" width={60} height={30} />
                <Icon name="mastercard" width={60} height={30} />
                <Icon name="discover" width={60} height={30} />
                <Icon name="american-express" width={60} height={30} />
              </Flex>
              <Flex justifyContent="center" pt={18}>
                <img src={SecurePayment} width="85" height="76" alt="Secure Payment" />
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </>
    )
  );
};

export default CardInfo;
