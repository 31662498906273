import React from 'react';
import { Container, HiddenInput, IconContainer, Icon } from './style';

const CustomRadio = (props) => {
  const { checked, validatorRegister, validatorConditions, name, errors } = props;
  
  return (
    <Container>
      <HiddenInput {...props} ref={validatorRegister && validatorRegister(validatorConditions)} />
      <IconContainer checked={checked} errors={errors} name={name}>
        <Icon checked={checked} />
      </IconContainer>
    </Container>
  );
};

export default CustomRadio;
