import React, { useState, useContext } from 'react';
import { AppContext } from 'context/appContext';
import PageTitle from 'components/molecules/PageTitle';
import ContainerApp from 'components/molecules/ContainerApp';
import { Redirect } from 'react-router-dom';
import { isAuthenticated, getUrlGroups } from 'modules/utils';
import { Flex } from '@rebass/grid/emotion';
import { useQuery, useMutation } from 'react-apollo-hooks';
import Icon from 'components/atoms/Icon';
import { GET_PACKET, GET_APPLICATION_PANNEL } from 'graphql/application/queries';
import { RESEND_EMAIL } from 'graphql/application/mutations';
import { Packet, DownloadPacket } from 'components/organisms/Modals';
import Loader from 'components/atoms/Loader';
import { Heading, Desc } from '../Review/style.js';
import { Blue } from '../Documents/style.js';
import Button from '../../molecules/Button';
import WhiteBox from '../../molecules/WhiteBox/index.js';

const Review = ({ inquiry }) => {
  const { dispatch } = useContext(AppContext);
  const path = getUrlGroups();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const [resendEmail] = useMutation(RESEND_EMAIL, {
    update: (cache, { data }) => {
      setLoading(false);
      dispatch({
        type: 'setModalOpened',
        showModal: Packet,
      });
    },
  });

  const { data } = useQuery(GET_PACKET, {
    variables: { slug: path.application },
  });

  const { data: dataInquiry } = useQuery(GET_APPLICATION_PANNEL, {
    variables: { slug: path.application },
  });

  if (!isAuthenticated()) {
    return <Redirect to="/login" />;
  }

  let getInquiry;
  let inquiryState;
  let hasPacketReview;

  if (dataInquiry) {
    getInquiry = dataInquiry.inquiry;
    inquiryState = getInquiry.application.state;
    hasPacketReview = getInquiry.settings.packetReview;
  }

  async function preResend(e) {
    try {
      e.preventDefault();
      setLoading(true);
      await resendEmail({ variables: { slug: { inquirySlug: path.application } } });
    } catch (error) {
      console.log(error);
    }
  }

  return (data && dataInquiry) ? (
    <>
      <PageTitle title="APPLICATION PACKET" icon="packet" />
      {inquiryState !== 'FINISHED' ? (
        <ContainerApp mw="510px">
          {inquiry.free
            ? <Heading>Please complete your application.</Heading>
            : <Heading>Please complete your application and submit your payment.</Heading>
          }
          
          <Flex justifyContent="center">
            <Icon name="review-answers" width={100} height={100} />
          </Flex>
          <Desc>Complete all the steps above in order to generate your application packet.</Desc>
          {data.getPacketByInquirySlug && (
            <Blue onClick={() => setShow(!show)}>
              What is included in my complete application packet?
            </Blue>
          )}
          {show && (
            <WhiteBox
              title="Your Application Packet Includes:"
              list={data.getPacketByInquirySlug.items}
            />
          )}
        </ContainerApp>
      ) : (
        <ContainerApp mw="509px">
          {hasPacketReview ? (
            <Heading>Your application will be reviewed by an attorney.</Heading>
          ) : (
            <Heading>Your application packet is ready.</Heading>
          )}

          <Flex justifyContent="center" mt={-18}>
            <Icon name="checkmark-bigger" width={100} height={100} />
          </Flex>
          {hasPacketReview ? (
            <Desc>
              Your application will be reviewed by an attorney. We'll be in touch with you shortly
              on the status of your application.
            </Desc>
          ) : (
            <Desc>
              Your application packet is complete and ready to download. The complete application
              has also been sent to your e-mail. If you still have questions after reading the
              filing instructions and accompanying documents, please contact support.
            </Desc>
          )}

          {data.getPacketByInquirySlug ? (
            <Flex mt={45}>
              <WhiteBox
                title="Your Application Packet Includes:"
                list={data.getPacketByInquirySlug.items}
              />
            </Flex>
          ) : (
            ''
          )}
          {!hasPacketReview && (
            <Flex justifyContent="space-between" pt={[40, 60, 80]}>
              <Button
                margin="0px"
                value={loading ? <Loader /> : 'Resend Email'}
                extraClass="secondary"
                onClick={e => preResend(e)}
                fixedWidth="149px"
              />
              <Button
                margin="0px"
                value="Download Packet"
                onClick={() => dispatch({
                  type: 'setModalOpened',
                  showModal: DownloadPacket,
                })
                }
              />
            </Flex>
          )}
        </ContainerApp>
      )}
    </>
  ) : null;
};

export default Review;
