import styled from 'styled-components';
import colors from 'modules/colors';

export const Description = styled.p`
  width: 100%;
  white-space: pre-wrap;
  font-weight: 500;
  font-size: ${({ size }) => `${size}px` || '14px'};
  line-height: 17px;
  color: ${() => colors.blackLight};
  margin: 0 0 20px;
`;
