import React from 'react';
import { Container } from './style';
import { isPreviewPage } from 'modules/utils';

const getWidth = () => {
  const isPreview = isPreviewPage();

  return isPreview ? [1, 1, 1, 5/7] : [1, 1, 1, 5/9];
};

const FormContainer = (props) => {
  const { children, customClass } = props;
  
  return (
    <Container
      flexDirection="column"
      alignItems="center"
      width={getWidth()}
      py={[35, 50, 50, 100]}
      px={20}
      className={customClass}
    >
      {children}
    </Container>
  );
};

export default FormContainer;
