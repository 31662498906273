import React from 'react';
import { Form } from './style';

const BoxForm = (props) => {
  const { children, onSubmit } = props;

  return <Form onSubmit={onSubmit}>{children}</Form>;
};

export default BoxForm;
