import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Title, List, ListItem, Text, Strong, Button,
} from './style';

const AvailableApplications = (props) => {
  const { applications } = props;

  function handleSlug(item) {
    return `/applications/${item.slug}/${item.nextGroupSlug}`;
  }

  const available = applications.filter(a => a.progress < 1 && !a.progress);

  return available.length > 0 ? (
    <Container>
      <Title>Available Applications</Title>
      <List>
        {available.map(item => (
          <ListItem key={item.slug} to={handleSlug(item)}>
            <Button type="button">Start</Button>
            <Text>
              <Strong>{`${item.title}: `}</Strong>
              {item.subtitle}
            </Text>
          </ListItem>
        ))}
      </List>
    </Container>
  ) : (
    ''
  );
};

AvailableApplications.propTypes = {
  applications: PropTypes.array.isRequired,
};

export default AvailableApplications;
