import React, { useState, useEffect } from 'react';
import FormContainer from 'components/organisms/FormContainer';
import GroupPage from 'components/organisms/GroupPage';
import SubGroupPage from 'components/organisms/SubGroupPage';
import Payment from 'components/organisms/Payment';
import Review from 'components/organisms/Review';
import Documents from 'components/organisms/Documents';
import Cover from 'components/organisms/CoverLetter';
import Packet from 'components/organisms/ApplicationPacket';
import { getUrlGroups } from 'modules/utils';

const PannelForm = ({ history, inquiry }) => {
  const [pageType, setPageType] = useState('GROUP');
  const [visibility, setVisibility] = useState('hidden');

  const path = getUrlGroups();

  if (window.location.pathname.indexOf('/checkout') === -1) window.scroll({top: 0, left: 0, behavior: 'smooth' });

  useEffect(() => {
    setVisibility('hidden');
    setPageType('GROUP');

    setTimeout(() => {
      setVisibility('visible');
    }, 300);

    if (path.subGroup === 'preview') {
      setPageType('GROUP');
      return;
    }

    if (path.subGroup && path.subGroup !== 'preview') {
      setPageType('SUB_GROUP');
      return;
    }

    switch (path.group) {
      case 'payment':
        setPageType('PAYMENT');
        break;
      case 'checkout':
        setPageType('CHECKOUT');
        break;
      case 'review':
        setPageType('REVIEW');
        break;
      case 'documents':
        setPageType('DOCUMENTS');
        break;
      case 'cover':
        setPageType('COVER');
        break;
      case 'packet':
        setPageType('PACKET');
        break;
      default:
        setPageType('GROUP');
    }

    window.scroll({top: 0, left: 0, behavior: 'smooth' });
    
  }, [window.location.pathname]);

  return (
    <FormContainer customClass={`${visibility === 'visible' ? '--visible' : ''}`}>
      {pageType === 'GROUP' && <GroupPage history={history} inquiry={inquiry} />}
      {pageType === 'SUB_GROUP' && path.subGroup !== 'preview' && (
        <SubGroupPage history={history} inquiry={inquiry} />
      )}
      {pageType === 'PAYMENT' && <Payment history={history} />}
      {pageType === 'CHECKOUT' && <Payment checkout={true} history={history} />}
      {pageType === 'REVIEW' && <Review history={history} inquiry={inquiry} />}
      {pageType === 'DOCUMENTS' && <Documents />}
      {pageType === 'COVER' && <Cover />}
      {pageType === 'PACKET' && <Packet inquiry={inquiry} />}
    </FormContainer>
  );
};

export default PannelForm;
