import React, { useContext, useState } from 'react';
import ListName from 'components/molecules/Pannel/ListName';
import Button from 'components/molecules/Button';
import { AppContext } from 'context/appContext';
import { getSlugUrlGroups } from 'modules/utils';
import {
  Container, Description, Row, Item, Bold,
} from './style';

const CartList = (props) => {
  const { history } = props;
  const { state, dispatch } = useContext(AppContext);
  const selected = state.packages.filter(p => p.isSelected);
  const [hidden, setHidden] = useState(false);
  const { slug } = getSlugUrlGroups();

  return (
    !hidden
    && !state.paid && (
      <Container>
        <ListName name="Your Cart" />
        {selected.length === 0 ? (
          <>
            <Row width={[1, 5 / 6]} justifyContent="flex-end" ml={12} className="--no-border">
              <Description>
                Your cart is empty.
                {' '}
                <br />
                Please select a package to continue.
              </Description>
            </Row>
            <Row width={[1, 5 / 6]} ml={12} justifyContent="flex-end" className="--no-border">
              <Button
                value="Continue to Payment"
                extraClass="continue-payment --disabled"
                onClick={() => {}}
                disabled
              />
            </Row>
          </>
        ) : (
          <>
            <Row width={[1, 5 / 6]} justifyContent="space-between" ml={12} className="--no-border">
              <Item>{selected.map(pack => pack.name)}</Item>
              <Bold>
$
                {selected.map(pack => pack.price)}
              </Bold>
            </Row>
            <Row width={[1, 5 / 6]} justifyContent="space-between" ml={12}>
              <Bold>Total</Bold>
              <Bold>
$
                {selected.map(pack => pack.price)}
              </Bold>
            </Row>
            <Row width={[1, 5 / 6]} ml={12} justifyContent="flex-end">
              <Button
                value="Continue to Payment"
                extraClass="continue-payment"
                onClick={(e) => {
                  setHidden(true);
                  dispatch({ type: 'setPaymentPage', payment: true });

                  history.push(`/applications/${slug}/checkout`);
                }}
              />
            </Row>
          </>
        )}
      </Container>
    )
  );
};

export default CartList;
