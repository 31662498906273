import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/atoms/Icon';
import { ListItem, SubList } from './style';
import { isPreviewPage } from 'modules/utils';
import ProgressDone from 'assets/images/progress-done.png';
import ProgressWip from 'assets/images/progress-wip.png';

const PannelListItem = (props) => {
  const {
    to, onClick, value, children, sub, active, icon, locked,
  } = props;

  const [isPreview] = useState(isPreviewPage());

  return (
    <ListItem
      to={to || window.location.pathname}
      onClick={onClick}
      sub={sub ? sub.toString() : 'false'}
      active={active ? active.toString() : 'false'}
      locked={locked}
      activeClassName={sub ? 'sub' : 'group'}
    >
      {value}

      {icon && icon.name && !isPreview && <Icon name={icon.name} width={icon.width} height={icon.height} />}

      {icon && icon.name === 'progress-done' && isPreview ? (
        <img className="icon-status" width={icon.width} src={ProgressDone} alt="checkmark" />
      ) : icon && icon.name && isPreview && (
        <img className="icon-status" width={icon.width} src={ProgressWip} alt="wip" />
      )}

      {children && <SubList>{children}</SubList>}
    </ListItem>
  );
};

PannelListItem.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string.isRequired,
  sub: PropTypes.bool,
  icon: PropTypes.object,
  locked: PropTypes.string,
};

export default PannelListItem;
