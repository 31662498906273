import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/atoms/Icon';
import 'react-phone-input-2/dist/style.css';
import { Container, Helper } from './style';

const InputAnswer = (props) => {
  const { text, customClass } = props;

  return (
    <Container className={customClass}>
      <Icon width={15} height={15} name="help" />
      <Helper className="helper-text">{text}</Helper>
    </Container>
  );
};

InputAnswer.propTypes = {
  text: PropTypes.string.isRequired
};

export default InputAnswer;
