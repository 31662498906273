import React from 'react';
import { Box } from '@rebass/grid/emotion';
import CardInfo from './CardInfo';
import OrderInfo from './OrderInfo';
import YourInfo from './YourInfo';
import { Container } from './style';

const Checkout = (props) => (
  <Container justifyContent="space-between" flexDirection={['column', 'row']} width={1}>
    <Box width={[1, 5 / 10]}>
      <YourInfo />
      <CardInfo />
    </Box>
    <Box width={[1, 4 / 10]}>
      <OrderInfo history={props.history} />
    </Box>
  </Container>
);

export default Checkout;
