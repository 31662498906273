import styled from "styled-components";
import colors from "modules/colors";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 5.5px;
  border-bottom: 1px solid ${() => colors.grey};
  margin-bottom: 10px;
  position: relative;

  input {
    border-color: ${() => colors.red};
    border-width: 1px;
    border-style: ${({ errors, name }) => (errors && errors[name] ? 'solid' : 'none')};
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${() => colors.blueDark};
  min-width: 108px;
  text-align: left;
`;

export const Border = styled.span`
  height: 17px;
  width: 1px;
  border-left: 1px solid ${() => colors.greyDark};
  margin-left: 21px;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const Divider = styled.span`
  height: 1px;
  width: 16px;
  background-color: ${() => colors.greyDark};
  margin: 0 10px;
`;

export const FormInputYellow = styled.input`
  display: block;
  width: 59%;
  height: 30px;
  color: ${() => colors.black};
  border: none;
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  background: rgba(255, 223, 142, 0.1);
`;

export const FormInputYellowSmall = styled(FormInputYellow)`
  width: calc(35% - 29px);
  margin-right: 11px;

  @media (max-width: 767px) {
    margin-right: 0;
    width: 35%;
  }
`;
