import styled from 'styled-components';
import AliroLogo from 'assets/images/legal-pilot.svg';

export const Logo = styled.img.attrs(() => ({
  src: AliroLogo,
}))`
  margin-top: -5px;
  ${props => props.w || 0};
  ${props => props.h || 0};
`;
