import React, { useContext, useState } from 'react';
import { AppContext } from 'context/appContext';
import { Box, Flex } from '@rebass/grid/emotion';
import Icon from 'components/atoms/Icon';
import Button from 'components/molecules/Button';
import { useMutation } from 'react-apollo-hooks';
import { DELETE_ANSWER } from '../../../graphql/application/mutations';
import {
  Container,
  Title,
  CompletedText,
  StatusBar,
  NextStepsContainer,
  NextStepsWrapper,
  NextStepsTitle,
  NextStepsList,
  NextStepsItem,
  BadgesList,
  BadgesListItem,
  BadgesListItemText,
  ContainerButton,
  Delete,
} from './style';

const ApplicationStatus = ({
  progress,
  title,
  paid,
  state,
  docs,
  slug,
  group,
  refetchApps,
  refetchApplications,
  packetReview,
  free,
  nextSteps,
  startItem,
}) => {
  const [deleteAnswer] = useMutation(DELETE_ANSWER, {
    update: async (cache, { data }) => {
      setShowDelete(false);
      dispatch({
        type: 'setAvailableApplications',
        showAvailableApplication: false,
      });

      sessionStorage.removeItem('inquiry');

      await refetchApplications();
      await refetchApps();
    },
  });
  const { dispatch } = useContext(AppContext);
  // const [availableApplication] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  async function preDelete(e) {
    try {
      e.preventDefault();
      await deleteAnswer({ variables: { slug: { inquirySlug: slug } } });
    } catch (error) {
      console.log(error);
    }
  }

  const hasDocs = nextSteps.find(step => step.title === 'Documents');

  return (
    <>
      <Container>
        {showDelete ? (
          <Box width={5 / 6}>
            <Title>Are you sure you want to permanently delete your application?</Title>
            <CompletedText align="center">
              This action can not be undone. If you delete this application, you will no longer be
              able to access it.
            </CompletedText>
            <Flex justifyContent="space-between" pt={[15, 30]}>
              <Button
                value="Yes, Delete Application"
                extraClass="button--delete"
                removeLeft
                padding="5px 13px"
                margin="0"
                onClick={preDelete}
              />
              <Button
                value="No, Return to Portal"
                extraClass="button"
                arrowRight
                padding="5px 13px"
                margin="0"
                onClick={() => setShowDelete(!showDelete)}
              />
            </Flex>
          </Box>
        ) : (
          <Box width={7 / 9}>
            <Title>{title}</Title>
            <CompletedText>
              You have completed
              {` ${progress}`}
% of your application.
            </CompletedText>
            <StatusBar status={progress} />
            {progress === 100 && (
              <NextStepsContainer>
                <NextStepsWrapper>
                  <NextStepsTitle>Next Steps</NextStepsTitle>
                  <NextStepsList>
                    {!free && (
                      <NextStepsItem>
                        {paid && <Icon name="checkmark" width={15} height={14} />}
                        <span style={{ marginLeft: paid ? 7 : 22 }}>Payment</span>
                      </NextStepsItem>
                    )}
                    <NextStepsItem>
                      {
                      }
                      {(state === 'SUBMITTED' || state === 'FINISHED') && packetReview ? (
                        <Icon name="checkmark" width={15} height={14} />
                      ) : (state === 'SUBMITTED' || state === 'FINISHED') && !packetReview ? (
                        <Icon name="checkmark" width={15} height={14} />
                      ) : ''}
                      <span
                        style={{
                          marginLeft:
                            (state === 'SUBMITTED' || state === 'FINISHED') && packetReview
                              ? 7
                              : 22,
                        }}
                      >
                        Review Answers
                      </span>
                    </NextStepsItem>
                    {hasDocs && (
                      <NextStepsItem>
                        {docs && <Icon name="checkmark" width={15} height={14} />}
                        <span style={{ marginLeft: docs ? 7 : 22 }}>Documents</span>
                      </NextStepsItem>
                    )}
                  </NextStepsList>
                </NextStepsWrapper>

                {!packetReview && state === 'SUBMITTED' && state === 'FINISHED' && (
                  <BadgesList>
                    <BadgesListItem>
                      <Icon name="ship" width={50} height={50} />
                      <BadgesListItemText>Print and Ship Included</BadgesListItemText>
                    </BadgesListItem>
                    <BadgesListItem>
                      <Icon name="review" width={50} height={50} />
                      <BadgesListItemText>Print and Ship Included</BadgesListItemText>
                    </BadgesListItem>
                  </BadgesList>
                )}
              </NextStepsContainer>
            )}
            <Flex pt={[15, 30]} justifyContent="flex-end">
              <ContainerButton
                to={`applications/${slug}/${
                  // meaning the applications is in progress
                  state === 'NOT_STARTED' || state === 'IN_PROGRESS'
                  ? startItem
                      ? startItem.subgroup
                        ? `${startItem.group}/${startItem.subgroup}`
                        : startItem.group
                      : group
                     // meaning the application is in review
                    : state === 'COMPLETED' || state === 'IN_REVIEW'
                      ? 'review'
                        // meaning the application was reviewed, the form is not free, the user did not pay yet, there is no document required
                        : (state === 'SUBMITTED' || state === 'SUBMITTED_DOCS') && !free && !paid && docs
                          ? 'payment'
                            // meaning the user generated the packet and the packet will be available for download
                            : state === 'FINISHED' && !packetReview
                              ? 'packet'
                                // meaning the application was reviewed but upload documents ir required to move forward
                                : state === 'SUBMITTED' && !docs
                                  ? 'documents'
                                    : group
                }`}
              >
                <Button
                  value={`${
                    // paid  = a boolean var that informs if the application was paid.
                    // state = a boolean var that informs the application status.
                    // docs  = a boolean var that verifies the documens status.
                    // packetReview = a boolean var that verifies if the application requires attorney review

                    // meaning the applications is in progress
                    state === 'NOT_STARTED' || state === 'IN_PROGRESS'
                      ? 'Resume Application'
                         // meaning the application is in review
                        : state === 'COMPLETED' || state === 'IN_REVIEW'
                          ? 'Review Answers'
                            // meaning the application was reviewed, the form is not free, the user did not pay yet, there is no document required
                            : (state === 'SUBMITTED' || state === 'SUBMITTED_DOCS') && !free && !paid && docs
                              ? 'Submit Payment'
                                // meaning the user generated the packet and the packet will be available for download
                                : state === 'FINISHED' && !packetReview
                                  ? 'Download Packet'
                                    // meaning the application was reviewed but upload documents ir required to move forward
                                    : state === 'SUBMITTED' && !docs
                                      ? 'Submit Documents'
                                        : 'View My Application'

                  }`}
                  extraClass="button"
                  padding="10.5px 13px"
                  arrowRight
                />
              </ContainerButton>
            </Flex>
            {state !== 'SUBMITTED' && state !== 'FINISHED' && (
              <Delete onClick={() => setShowDelete(!showDelete)}>Delete Application</Delete>
            )}
          </Box>
        )}
      </Container>
    </>
  );
};

export default ApplicationStatus;
