import styled from 'styled-components';
import colors from 'modules/colors';

export const Bold = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: ${() => colors.black};
`;

export const Link = styled.span`
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${() => colors.blue};
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid ${() => colors.grey};
  cursor: pointer;
  text-align: right;
`;

export const PriceText = styled(Bold)`
  font-weight: 400;
  margin-bottom: 7px;
`;

export const Container = styled(Bold)`
  .box-button {
    margin: 0 auto;
  }
`;
