import React from 'react';
import Logo from 'components/molecules/Logo';
import { Flex } from '@rebass/grid/emotion';
import {
  Footer, Col6, Container, Nav, List, ItemList, Link, Text,
} from './style';

export default () => (
  <Footer>
    <Flex alignItems="center" flexDirection={['column', 'row']}>
      <Col6 width={[1, 1 / 2]} alignItems="center" justifyContent="center">
        <Link href="https://www.legalpilot.io" target="_blank">
          <Logo width={274} height={106} />
        </Link>
      </Col6>
      <Col6 width={[1, 1 / 2]} alignItems="center" justifyContent="center">
        <Container>
          <Nav>
            <List>
              <ItemList>
                <Link href="https://www.legalpilot.io/why-us" target="_blank">
                  About Us
                </Link>
              </ItemList>
              <ItemList>
                <Link href="https://www.legalpilot.io/get-started" target="_blank">
                  Contact Us
                </Link>
              </ItemList>
            </List>
          </Nav>
          <Text>
            LegalPilot provides an online service to aid you as you prepare your immigration forms.
            LegalPilot is not a government service, is not a law firm, and does not substitute for
            the advice of an attorney. The information provided in this site is not legal advice.
            The cost for using LegalPilot's services does not include any government filling fees,
            application fees, or biometrics fees. Your access to and use of this site and service is
            subject to the Terms of Use.
          </Text>
        </Container>
      </Col6>
    </Flex>
  </Footer>
);
