import React from 'react';
import PropTypes from 'prop-types';
import { InputElement } from './style';

const Input = (props) => {
  const {
    type, value, placeholder, onChange,
  } = props;
  
  return <InputElement type={type} placeholder={placeholder} value={value} onChange={onChange} />;
};

Input.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default Input;
