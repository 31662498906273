import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'context/appContext';
import { Box } from '@rebass/grid/emotion';
import AccountContainer from 'components/organisms/AccountContainer';
import PageTitle from 'components/molecules/PageTitle';
import YourAccountTitle from 'components/molecules/YourAccountTitle';
import AvailableApplications from 'components/organisms/AvailableApplications';
import ApplicationStatus from 'components/organisms/ApplicationStatus';
import AllApplications from 'components/organisms/AllApplications';

import { GET_APPLICATION_LIST, GET_APPLICATIONS } from 'graphql/application/queries';
import { GET_ME } from 'graphql/user/queries';
import { SAVE_ALL_ANSWERS } from 'graphql/application/mutations';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { Redirect } from 'react-router-dom';

const YourAccountContainer = () => {
  const { state, dispatch } = useContext(AppContext);
  const { data: dataMe } = useQuery(GET_ME);
  const { data, refetch } = useQuery(GET_APPLICATION_LIST);
  const { data: dataApp, error: errorApp, refetch: refetchApp } = useQuery(GET_APPLICATIONS);
  const storage = JSON.parse(sessionStorage.getItem('inquiry'));
  const [saveAllAnswers] = useMutation(SAVE_ALL_ANSWERS, {
    update: (cache, { data }) => {
      const progress = data.saveAllAnswers.progress;
      dispatch({ type: 'setProgress', progress: progress.progress });
      refetch();
      refetchApp();
    },
  });

  const [download, setDownload] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  useEffect(() => {
    const { search } = window.location;
    const split = search.split('=');
    if (split[0] === '?download') {
      setDownload(split[1]);
    }
    if (data) data.inquiries.map(inq => inq.progress && setInProgress(true));
  }, [setDownload, data]);

  function getSenderData() {
    const parsedStorage = Object.values(storage);
    return parsedStorage.reduce((acc, item) => {
      acc.push(...item);
      return acc;
    }, []);
  }

  useEffect(() => {
    if (storage) {
      const save = async function () {

        await saveAllAnswers({ variables: { answers: getSenderData() } });
        sessionStorage.removeItem('inquiry');
      };

      save();
    }

    dispatch({ type: 'setPaymentPage', payment: false });

    // sessionStorage.removeItem('inquiry');
  }, []);

  if (!data || !dataMe) {
    return <Box width={[1, 1, 5 / 9, 6 / 9]} />;
  }

  if (!dataMe.me.active) {
    return <Redirect to="/activate" />;
  }

  return download ? (
    <Redirect to={`applications/${download}/packet`} />
  ) : data && dataApp && !errorApp ? (
    <AccountContainer>
      <PageTitle title="Your Account" icon="your-account" />
      <YourAccountTitle title="Welcome to your account portal. Please select an application to get started." />
      {dataApp.applications.map((app, i) => (
        <ApplicationStatus
          key={i}
          progress={parseInt(app.progress)}
          title={app.title}
          slug={app.slug}
          group={app.nextGroupSlug}
          subGroup={app.nextSubGroupSlug}
          paid={app.paid}
          free={app.free}
          docs={app.statusDocuments}
          state={app.state}
          refetchApps={refetch}
          refetchApplications={refetchApp}
          packetReview={app.packetReview}
          nextSteps={app.nextStep}
          startItem={app.startItem}
        />
      ))}
      {data.inquiries.length > 0
        && dataApp.applications.length > 0
        && data.inquiries.length !== dataApp.applications.length && <AllApplications />}

      {(state.newApplication || !inProgress || dataApp.applications.length === 0) && (
        <AvailableApplications applications={data.inquiries} />
      )}
    </AccountContainer>
  ) : (
    ''
  );
};

export default YourAccountContainer;
