import styled from 'styled-components';
import colors from 'modules/colors';

export const Title = styled.h1`
  display: block;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  color: ${() => colors.blueDark};
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 35px;

  @media (max-width: 63.99em) {
    display: none;
  }
`;
