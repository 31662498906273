import React from 'react';
import PropTypes from 'prop-types';
import icons from 'assets/svg/icons.svg';
import { Svg } from './style';

const Icon = (props) => {
  const { name, width, height, customClass } = props;

  return (
    <Svg {...props} width={width} height={height} className={`icon icon-${name} ${customClass}`}>
      <use xlinkHref={`${icons}#${name}`} />
    </Svg>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default Icon;
