import styled from 'styled-components';
import { Box } from '@rebass/grid/emotion';
import colors from 'modules/colors';

export const Button = styled(Box)`
  min-width: 150px;

  &:first-child label {
    border-right: none;
  }
`;

export const Label = styled.label`
  background-color: ${() => colors.white};
  text-align: center;
  color: ${() => colors.blueDark};
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  position: relative;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ errors, inputname }) => (errors && errors[inputname] ? colors.red : colors.blueDark)};

  .helper-text-container {
    font-weight: 500;
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + label {
    background-color: ${() => colors.yellow};
  }
`;
