import React from 'react';
import PropTypes from 'prop-types';
import { Container, Label, Info } from './style';

const PersonalInfo = (props) => {
  const {
    label, value,
  } = props;

  return (
    <Container>
      <Label>{label}</Label>
      <Info>{value}</Info>
    </Container>
  );
};

PersonalInfo.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default PersonalInfo;
