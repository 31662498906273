import React from 'react';
import PropTypes from 'prop-types';
import { Title } from './style';

const FormName = (props) => {
  const { name } = props;

  return <Title>{name}</Title>;
};

FormName.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormName;
