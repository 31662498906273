import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@rebass/grid/emotion';
import { Text, HelperContainer } from './style';
import HelperText from 'components/molecules/HelperText';
import CustomRadio from 'components/molecules/CustomRadio';

const SingleChoice = (props) => {
  const {
    title,
    value,
    optionSelected,
    customClass,
    helperText,
    helperPosition
  } = props;

  return (
    <Flex width={1}>
      {/* <Box width={[1, 2 / 5]}> */}
      <Box width="100%">
        <Text className={customClass}>
          <HelperContainer>
            {title}
            {helperText && helperPosition === 'middle' && <HelperText customClass="helper-text-container" text={helperText}/>}
          </HelperContainer>
          <CustomRadio
            type="radio"
            {...props}
            checked={optionSelected === value}
          />
          {helperText && helperPosition === 'end' && <HelperText customClass="helper-text-container" text={helperText}/>}
        </Text>
      </Box>
    </Flex>
  );
};

SingleChoice.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default SingleChoice;
