import React from 'react';
import PropTypes from 'prop-types';
import HelperText from 'components/molecules/HelperText';
import { Button, Label, Input } from './style';

const ButtonAnswer = (props) => {
  const {
    option, index, optionSelected, value, helperText, validatorRegister, validatorConditions, name, errors, onChange
  } = props;

  function removeInputFocus(e) {
    e.target.blur();
    onChange && onChange(e);
  }

  return (
    <Button key={`button-${index}`}>
      <Input id={`option-${index}`} type="radio" {...props} checked={optionSelected === value} ref={validatorRegister && validatorRegister(validatorConditions)} onChange={removeInputFocus} />
      <Label htmlFor={`option-${index}`} inputname={name} errors={errors}>
        {option}
        {helperText && <HelperText customClass="helper-text-container" text={helperText} />}
      </Label>
    </Button>
  );
};

ButtonAnswer.propTypes = {
  index: PropTypes.string.isRequired,
  option: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ButtonAnswer;
