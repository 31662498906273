import React from 'react';
import PropTypes from 'prop-types';
import { Title } from './style';

const YourAccountTitle = (props) => {
  const { title } = props;

  return <Title>{title}</Title>;
};

YourAccountTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default YourAccountTitle;
