import React from 'react';
import PageContainer from 'components/molecules/PageContainer';
import YourAccount from 'components/templates/YourAccount';
import PannelHelp from 'components/templates/PannelHelp';

export default () => (
  <PageContainer>
    <YourAccount />
    <PannelHelp />
  </PageContainer>
);
