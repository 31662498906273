import React from 'react';
import { Container, Title } from './style';

const HeroAmericanFlag = () => (
  <Container>
    <Title>SIMPLIFY YOUR IMMIGRATION JOURNEY</Title>
  </Container>
);

export default HeroAmericanFlag;
