import React, { useContext, useEffect } from 'react';
import Package from 'components/molecules/Package';
import List from 'components/molecules/Package/List';
import Icon from 'components/atoms/Icon';
import { Flex } from '@rebass/grid/emotion';
import { AppContext } from 'context/appContext';
import { GET_PACKAGES, GET_APPLICATION_LIST } from 'graphql/application/queries';
import { getUrlGroups, getSlugUrlGroups } from 'modules/utils';

import { useQuery } from 'react-apollo-hooks';
import {
  BestDeal, Title, Price, Currency, Usd, WhiteBox, Button,
} from './style';

const PackageBox = ({ history }) => {
  const { state, dispatch } = useContext(AppContext);
  const { data } = useQuery(GET_PACKAGES);
  const { data: dataSlug } = useQuery(GET_APPLICATION_LIST);
  const path = getUrlGroups();
  const { slug } = getSlugUrlGroups();
  
  useEffect(() => {
    if (data) {
      dispatch({ type: 'setPackages', packages: data.packages });
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (state.payment) history.push(`/applications/${slug}/checkout`);
  }, [state, history, slug])

  if (dataSlug) {
    state.packages.filter((inquiry, index) => inquiry.inquirySlug === path.application);
  }

  const addToCart = (id) => {
    dispatch({ type: 'setPackageSelected', id });
  };

  return dataSlug ? (
    <>
      {state.packages
        .filter(inquiry => inquiry.inquirySlug === path.application)
        .map((p, i) => (
          <Package key={i}>
            {p.deal && (
              <BestDeal>
                <Icon width={103} height={112} name="best-deal" />
              </BestDeal>
            )}
            <Flex justifyContent="center" alignItems="center" flexDirection="column">
              <Title>{p.name}</Title>
              <Flex alignItems="flex-end">
                <Currency>$</Currency>
                <Price>{p.price}</Price>
                <Usd>USD</Usd>
              </Flex>
            </Flex>
            <WhiteBox
              width={1}
              mt={24}
              mb={16}
              justifyContent="space-between"
              flexDirection={['column', 'row']}
              flexWrap="wrap"
            >
              {p.services.map((item, index) => (
                <List key={index} name={item} />
              ))}
            </WhiteBox>
            <Flex justifyContent="center">
              <Button onClick={() => addToCart(p._id)}>
                {p.isSelected ? 'Selected' : 'Add to cart'}
              </Button>
            </Flex>
          </Package>
        ))}
    </>
  ) : (
    ''
  );
};

export default PackageBox;
