import styled from 'styled-components';
import colors from 'modules/colors';

export const Flex = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  position: relative;
`;

export const Text = styled.label`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.fontColor};
  padding: 12px 15px 12px 0;

  &:last-child {
    padding-right: 0;
  }
`;

export const Error = styled.div`
  position: absolute;
  left: calc(100% + 10px);
  width: fit-content !important;
  max-width: 160px;
  color: ${() => colors.error};
  background: ${() => colors.white};
  padding: 10px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid ${() => colors.grey};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  transition: 0.25s;

  @media (max-width: 1280px) {
    left: auto;
    top: calc(100% + 10px);
    right: 0;
    margin-right: 0 !important;
  }

  &:before {
    content: '';
    position: absolute;
    left: -5px;
    top: 12px;
    width: 8px;
    height: 8px;
    background-color: ${() => colors.white};
    border: 1px solid ${() => colors.grey};
    border-top-color: transparent;
    border-right-color: transparent;
    transform: rotate(45deg);
    
    @media (max-width: 1280px) {
      top: -5px;
      right: 10px;
      left: auto;
      border-bottom-color: transparent;
      border-top-color: ${() => colors.grey};
    }
  }
`;
