import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Icon from 'components/atoms/Icon';
import { AppContext } from 'context/appContext';

import Dropdown from 'components/molecules/NavbarUser/Dropdown';
// import DropdownPopups from 'components/molecules/NavbarUser/DropdownPopups';

import { GET_ME } from 'graphql/user/queries';
import { useQuery } from 'react-apollo-hooks';
import { Container, TextLink, HasSubmenu } from './style';

const NavbarUser = (props) => {
  const { loading, data, error } = useQuery(GET_ME);
  const { state, dispatch } = useContext(AppContext);

  useEffect(() => {
    if (data && data.me) dispatch({ type: 'setNewName', name: data.me.name });
  }, [data, dispatch]);

  if (loading) {
    return <Container />;
  }

  return (
    <>
      {error || !data || !data.me ? (
        props.location.pathname !== '/login' && (
          <Container>
            <TextLink to="/login">Register/Log In</TextLink>
            <Icon width={30} height={30} name="user-outline" />
          </Container>
        )
      ) : (
        <Container>
          <HasSubmenu alignItems="center" justifyContent="center">
            <TextLink to={window.location.pathname}>{state.name}</TextLink>
            <Icon width={30} height={30} name="user-outline" />
            <Dropdown />
          </HasSubmenu>
        </Container>
      )}
    </>
  );
};

export default withRouter(NavbarUser);
