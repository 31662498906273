import React, { useContext } from 'react';
import { AppContext } from 'context/appContext';
import PropTypes from 'prop-types';
import Icon from 'components/atoms/Icon';
import {
  Container, FlexContainer, Label, List, ListItem,
} from './style';

const PasswordStrength = (props) => {
  const { state } = useContext(AppContext);
  const { sensitiveCases, color } = props;

  return (
    <Container>
      <FlexContainer>
        <Label color={color}>Please create a password that meets the following requirements</Label>
      </FlexContainer>

      <List>
        <ListItem
          color={color}
          className={
            sensitiveCases.six
              ? '--valid'
              : state.passwordLength !== 0 && state.registerInvalid && '--invalid'
          }
        >
          at least 6 caracters in lenght
          {sensitiveCases.six ? (
            <Icon name="checkmark" width={16} height={12} />
          ) : (
            state.passwordLength !== 0 && state.registerInvalid && <Icon name="invalid" width={11} height={10} />
          )}
        </ListItem>
        <ListItem
          color={color}
          className={
            sensitiveCases.upper
              ? '--valid'
              : state.passwordLength !== 0 && state.registerInvalid && '--invalid'
          }
        >
          at least one uppercase letter
          {sensitiveCases.upper ? (
            <Icon name="checkmark" width={16} height={12} />
          ) : (
            state.passwordLength !== 0 && state.registerInvalid && <Icon name="invalid" width={11} height={10} />
          )}
        </ListItem>
        <ListItem
          color={color}
          className={
            sensitiveCases.number
              ? '--valid'
              : state.passwordLength !== 0 && state.registerInvalid && '--invalid'
          }
        >
          at least one number
          {sensitiveCases.number ? (
            <Icon name="checkmark" width={16} height={12} />
          ) : (
            state.passwordLength !== 0 && state.registerInvalid && <Icon name="invalid" width={11} height={10} />
          )}
        </ListItem>
        <ListItem
          color={color}
          className={
            sensitiveCases.symbol
              ? '--valid'
              : state.passwordLength !== 0 && state.registerInvalid && '--invalid'
          }
        >
          at least one symbol
          {sensitiveCases.symbol ? (
            <Icon name="checkmark" width={16} height={12} />
          ) : (
            state.passwordLength !== 0 && state.registerInvalid && <Icon name="invalid" width={11} height={10} />
          )}
        </ListItem>
      </List>
    </Container>
  );
};

PasswordStrength.propTypes = {
  sensitiveCases: PropTypes.object.isRequired,
};

export default PasswordStrength;
