import styled from 'styled-components';
import colors from 'modules/colors';

export const Container = styled.div`
  margin-right: 10px;
  margin-left: 7px
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const IconContainer = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({ checked }) => (checked ? colors.blueAliro : colors.white)};
  border-width: 2px;
  border-style: solid;
  border-color: ${({ errors, name }) => (errors && errors[name] ? colors.red : colors.blueAliro)};
`;

export const Icon = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: ${({ checked }) => (checked ? colors.white : 'transparent')};
`;
