import styled from 'styled-components';
import colors from 'modules/colors';

export const Another = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.blueAliro};
  margin: 0 auto 25px;
  padding: 25px 0;
  cursor: pointer;
  text-decoration-line: underline;
`;
