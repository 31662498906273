import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from 'modules/utils';

const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={props => (isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location.query },
            search: props.location.search,
          }}
        />
      ))
      }
    />
  );
};

export default PrivateRoute;
