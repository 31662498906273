import React from 'react';
import { Flex } from '@rebass/grid/emotion';
import { Link } from 'react-router-dom';
import Icon from 'components/atoms/Icon';
import ContainerApp from 'components/molecules/ContainerApp';
import Button from '../../../../molecules/Button';
import { Heading, Desc } from './style.js';

const Success = () => (
  <>
    <Heading>Thank you for your payment.</Heading>
    <Desc>A confirmation has been sent to your email address.</Desc>
    <Flex justifyContent="center">
      <Icon name="checkmark-bigger" width={100} height={100} />
    </Flex>
    <ContainerApp mw="414px">
      <Flex justifyContent="flex-end" pt={[40, 60, 100]} width={1}>
        <Link to="./review">
          <Button margin="0" value="Continue" arrowRight />
        </Link>
      </Flex>
    </ContainerApp>
  </>
);

export default Success;
