import styled from 'styled-components';
import { Flex } from '@rebass/grid/emotion';

export const Container = styled(Flex)`
  height: auto;
  overflow: visible;
  position: relative;
  opacity: 0;
  transition: opacity 0.3s;
  visibility: hidden;

  &.--visible {
    opacity: 1;
    visibility: visible;
  }
`;
