import React from 'react';
import AccountContainer from 'components/organisms/AccountContainer';
import PageTitle from 'components/molecules/PageTitle';
import ActivationCode from 'components/organisms/ActivationCode';

const ActivateAccountContainer = () => (
  <AccountContainer>
    <PageTitle title="Your Account" icon="your-account" />
    <ActivationCode />
  </AccountContainer>
);

export default ActivateAccountContainer;
