import React, { useContext } from 'react';
import { AppContext } from 'context/appContext';
import Button from 'components/molecules/Button';
import { BeforeYouGo } from 'components/organisms/Modals';
import { isAuthenticated } from 'modules/utils';
import { Container } from './style';

const SaveProgress = () => {
  const { dispatch } = useContext(AppContext);

  function onSaveProgress() {
    dispatch({
      type: 'setModalOpened',
      showModal: BeforeYouGo,
    });
  }

  if (isAuthenticated()) {
    return <div />;
  }

  return (
    <Container>
      <Button value="Save Progress" onClick={onSaveProgress} />
    </Container>
  );
};

export default SaveProgress;
