import React, { useContext } from 'react';
import { GET_ME } from 'graphql/user/queries';
import { useQuery } from 'react-apollo-hooks';
import { AppContext } from 'context/appContext';
import colors from 'modules/colors';
import ShortAnswer from 'components/molecules/Form/ShortAnswer';
import { Redirect } from 'react-router-dom';
import { Box, BoxTitle, BoxForm } from './style';

const AccountInformation = () => {
  const { data } = useQuery(GET_ME);
  const { state } = useContext(AppContext);

  if (!data) {
    return false;
  }

  if (data && !data.me.active) {
    return <Redirect to="/activate" />;
  }

  return (
    data
    && data.me.active && (
      <Box>
        <BoxTitle>Account Information</BoxTitle>
        <BoxForm>
          <ShortAnswer label="Full Name" type="text" value={state.name} onChange={() => {}} truevalue={state.name} edit />
          <ShortAnswer
            label="Email Address"
            type="email"
            value={data.me.email || ''}
            customStyle={{ color: colors.greyDark, marginRight: '55px' }}
            onChange={() => {}}
            edit={false}
          />
          <ShortAnswer
            label="Password"
            type="password"
            value="******"
            onChange={e => false}
            edit
            editType="change"
          />
        </BoxForm>
      </Box>
    )
  );
};

export default AccountInformation;
