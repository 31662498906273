import React from 'react';
import LoginBox from 'components/organisms/LoginBox';
import RegisterBox from 'components/organisms/RegisterBox';
import { Box, Flex } from '@rebass/grid/emotion';
import {
  Container, ContainerBox, Divider, Or, BottomLabel,
} from './style';

const LoginRegister = () => (
  <Container>
    <Flex justifyContent="center" className="h100 h649">
      <Box width={[1, 9.3 / 10]} className="h100">
        <ContainerBox className="h100">
          <RegisterBox />
          <Divider width={[1, 0.5 / 10]}>
            <Or>OR</Or>
          </Divider>
          <LoginBox />
        </ContainerBox>
      </Box>
    </Flex>
    <BottomLabel>Simple. Accurate. Affordable.</BottomLabel>
  </Container>
);

export default LoginRegister;
