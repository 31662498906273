import styled from 'styled-components';
import colors from 'modules/colors';
import { Flex } from '@rebass/grid/emotion';

export const Container = styled(Flex)`
  position: relative;
  display: ${props => props.display || 'flex'}!important;
  justify-content: space-between;
  width: 100%;
  background-color: ${() => colors.white};
  overflow: visible;
  min-height: calc(100vh - 70px);
`;
