import styled from "styled-components";
import colors from 'modules/colors';
import { Flex } from '@rebass/grid/emotion';

export const Container = styled(Flex)`
    max-width: 580px;
`;

export const Text = styled.p`
    font-size: 12px;
    line-height: 15px;
    color: ${() => colors.black}
`;
