import React, { useContext } from 'react';
import PannelList from 'components/molecules/Pannel/List';
import PannelListItem from 'components/molecules/Pannel/List/Item';
import { isAuthenticated } from 'modules/utils';
import { Container } from './style';
import { AppContext } from 'context/appContext';
import { RegisterModal } from 'components/organisms/Modals';

const NextStepsList = (props) => {
  const { applicationSlug, list } = props;
  const { pathname } = window.location;
  const { dispatch } = useContext(AppContext);

  function verifyIsLogged() {
    if (!isAuthenticated()) {
      return dispatch({ type: 'setModalOpened', showModal: RegisterModal });
    }
  }

  return (
    <Container onClick={verifyIsLogged}>
      <PannelList name="Next Steps">
        {list.map(item => (
          <PannelListItem
            key={`${applicationSlug}/${item.slug}`}
            to={isAuthenticated() && `/applications/${applicationSlug}/${item.slug}`}
            value={item.title}
            active={(pathname === `/applications/${applicationSlug}/${item.slug}`) || (item.title === 'Payment' && pathname === `/applications/${applicationSlug}/checkout`)}
          />
        ))}
      </PannelList>
    </Container>
  );
};

export default NextStepsList;
