import React from 'react';
import {
  Redirect, Route, Switch, BrowserRouter,
} from 'react-router-dom';

import { hideIfIsPreview } from 'modules/utils';

import Navbar from 'components/organisms/Navbar';
import Footer from 'components/organisms/Footer';
import Modals from 'components/molecules/Modal';

import Login from 'views/Login';
import ResetPassword from 'views/ResetPassword';
import AccountSettings from 'views/AccountSettings';
import YourAccount from 'views/YourAccount';
import Application from 'views/Application';
import AccountActivation from 'views/AccountActivation';
import PrivateRoute from './PrivateRoute';

const Router = () => (
  <BrowserRouter>
    <Route
      render={() => (
        <>
          {hideIfIsPreview(<Navbar />)}

          <Switch>
            {/* <Route path="/" component={Application} exact /> */}
            <Route path="/login" component={Login} exact />
            <Route path="/reset-password" component={ResetPassword} exact />
            <Route path="/register" component={Login} exact />

            <Route path="/applications/:application_slug" component={Application} exact />
            <Route
              path="/applications/:application_slug/:group_slug"
              component={Application}
              exact
            />
            <Route
              path="/applications/:application_slug/:group_slug/:sub_group_slug"
              component={Application}
              exact
            />

            <Route path="/applications/:application_slug/preview" component={Application} exact />

            <Route
              path="/applications/:application_slug/:group_slug/preview"
              component={Application}
              exact
            />

            <Route
              path="/applications/:application_slug/:group_slug/:sub_group_slug/preview"
              component={Application}
              exact
            />

            <PrivateRoute
              path="/applications/:application_slug/payment"
              component={Application}
              exact
            />
            <PrivateRoute
              path="/applications/:application_slug/checkout"
              component={Application}
              exact
            />
            <PrivateRoute
              path="/applications/:application_slug/review"
              component={Application}
              exact
            />
            <PrivateRoute
              path="/applications/:application_slug/packet  "
              component={Application}
              exact
            />
            <PrivateRoute
              path="/applications/:application_slug/cover"
              component={Application}
              exact
            />

            <PrivateRoute path="/activate" component={AccountActivation} exact />
            <PrivateRoute path="/your-account" component={YourAccount} exact />
            <PrivateRoute path="/account-settings" component={AccountSettings} exact />
            <Redirect to="/your-account" />
          </Switch>

          {hideIfIsPreview(<Footer />)}

          <Modals />
        </>
      )}
    />
  </BrowserRouter>
);

export default Router;
