import styled from "styled-components";
import colors from "modules/colors";
import { Link } from "react-router-dom";
import { Flex } from "@rebass/grid/emotion";

export const Container = styled.span`
  position: relative;
  display: flex;
  align-items: center;
`;

export const HasSubmenu = styled(Flex)`
  position: relative;

  &:hover .submenu {
    display: block;
  }
`;

export const TextLink = styled(Link)`
  display: inline-block;
  margin-right: 13px;
  font-size: 13px;
  font-weight: 500;
  color: ${() => colors.white};
  text-align: left;
  line-height: 70px;
`;
