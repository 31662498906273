import styled from 'styled-components';
import colors from 'modules/colors';

export const InputElement = styled.input`
  display: block;
  width: 100%;
  height: 22px;
  background: rgba(255, 223, 142, 0.1);
  color: ${({ customStyle }) => (customStyle && customStyle.color ? customStyle.color : colors.black)}!important;
  border: none;
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-right: ${({ customStyle }) => (customStyle && customStyle.marginRight ? customStyle.marginRight : 0)};
`;
