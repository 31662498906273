import gql from 'graphql-tag';

export const GET_ME = gql`
  {
    me {
      _id
      name
      email
      active
    }
  }
`;

export const GET_USERS = gql`
  {
    users {
      _id
      name
      email
    }
  }
`;
