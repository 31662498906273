import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@rebass/grid/emotion';
import { Text, Error, Flex } from './style';
import HelperText from 'components/molecules/HelperText';
import CustomRadio from 'components/molecules/CustomRadio';

const GroupedAnswer = (props) => {
  const { title, optionSelected, helperText, errors, errorMessage, name } = props;

  return (
    <Flex>
      <Box className='grouped-answer-pt1'>
        <Text>{title}
        {helperText && <HelperText customClass="helper-text-container" text={helperText}/>}
        </Text>
      </Box>
      <Flex className='grouped-answer-pt2'>
        <Text>
          Yes
          <CustomRadio type="radio" value="Yes" {...props} checked={optionSelected === 'Yes'} />
        </Text>
        <Text>
          No
          <CustomRadio type="radio" value="No" {...props} checked={optionSelected === 'No'} />
        </Text>
      </Flex>
      {errors && errors[name] && (
        <Error className="error-message">{`${errorMessage || 'This field is required'}`}</Error>
      )}
    </Flex>
  );
};

GroupedAnswer.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default GroupedAnswer;
