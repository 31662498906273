import gql from 'graphql-tag';

export const GET_APPLICATION_LIST = gql`
  {
    inquiries {
      slug
      title
      subtitle
      description
      nextGroupSlug
      nextSubGroupSlug
      progress
      state
    }
  }
`;

export const GET_APPLICATION_PANNEL = gql`
  query inquiry($slug: String!) {
    inquiry(slug: $slug) {
      settings {
        hasCover
        hasPackage
        packetReview
      }
      application {
        title
        slug
        description
        progress
        state
        lastSaved
        body {
          _id
          uid
          title
          description
          slug
          locked
          lock
          status
          items {
            uid
            title
            type
            lock
            description
            slug
            status
            items {
              uid
              _id
              type
              template
              required
              title
              logic {
                viewControl {
                  action
                  group
                  subGroup
                  question
                  equalTo
                }
              }
              choices {
                uid
                _id
                type
                text
                label
                value
                locked
                required
                logic {
                  viewControl {
                    action
                    group
                    subGroup
                    question
                    equalTo
                  }
                }
              }
            }
          }
          logic {
            viewControl {
              action
              group
              subGroup
              question
              equalTo
            }
          }
        }
      }
      nextStep {
        title
        description
        slug
        show
      }
    }
  }
`;

export const GET_APPLICATION_DETAIL = gql`
  query inquiryDetail($slugInquiry: String!, $slugGroup: String!, $slugSubGroup: String) {
    inquiryDetail(
      slugs: { slugInquiry: $slugInquiry, slugGroup: $slugGroup, slugSubGroup: $slugSubGroup }
    ) {
      body {
        title
        slug
        description
        locked
        icon
        items {
          title
          slug
          type
          description
          nextGroupSlug
          nextSubGroupSlug
          logic {
            viewControl {
              action
              group
              subGroup
              question
              equalTo
            }
          }
          items {
            uid
            _id
            type
            template
            required
            title
            logic {
              viewControl {
                action
                group
                subGroup
                question
                equalTo
              }
            }
            choices {
              uid
              _id
              type
              text
              label
              value
              logic {
                viewControl {
                  action
                  group
                  subGroup
                  question
                  equalTo
                }
              }
            }
          }
        }
      }
    }
  }
`;

const queryInquiryData = `
free
application {
  title
  slug
  description
  progress
  state
  lastSaved
  body {
    uid
    title
    slug
    description
    locked
    icon
    status
    logic {
      viewControl {
        action
        group
        subGroup
        question
        equalTo
        field
      }
    }
    items {
      uid
      title
      slug
      type
      description
      nextGroupSlug
      nextSubGroupSlug
      status
      locked
      logic {
        viewControl {
          action
          group
          subGroup
          question
          equalTo
          lessOrEqualTo
          field
        }
      }
      items {
        uid
        _id
        type
        template
        required
        title
        message
        hasCloseButton,
        imageServerName,
        checkBoxText,
        secondMessage,
        link,
        buttonType,
        buttonLink,
        locked
        logic {
          viewControl {
            action
            group
            subGroup
            question
            equalTo
            field
          }
          paramControl {
            group
            subGroup
            question
            answer
          }
        }
        general {
          isMailingAddress
          helperText
          errorMessage
        }
        choices {
          uid
          _id
          type
          text
          label
          value
          isFirstField
          locked
          required
          isCountry
          choicesSelect {
            uid
            text
          }
          general {
            helperText
            errorMessage
            minLength
            maxLength
          }
          logic {
            viewControl {
              action
              group
              subGroup
              question
              equalTo
              field
            }
          }
        }
      }
    }
  }
}
nextStep {
  title
  description
  slug
  show
}`;

export const GET_APPLICATION_INQUIRY = gql`
  query inquiry($slug: String!) {
    inquiry(slug: $slug) {
      ${queryInquiryData}
    }
  }
`;

export const GET_APPLICATION_INQUIRY_PREVIEW = gql`
  query inquiryPreview($inquirySlug: String!, $token: String!) {
    inquiryPreview(previewInput: { inquirySlug: $inquirySlug, token: $token }) {
      ${queryInquiryData}
    }
  }
`;

export const GET_APPLICATION_INQUIRY_PREVIEW_ANSWERS = gql`
  query answerPreview($inquirySlug: String!, $token: String!, $answerId: String!) {
    answerPreview(previewInput: { inquirySlug: $inquirySlug, token: $token, answerId: $answerId }) {
      ${queryInquiryData}
    }
  }
`;

export const GET_PACKAGES = gql`
  {
    packages {
      _id
      inquirySlug
      name
      services
      deal
      price
    }
  }
`;

export const GET_DOCUMENTS = gql`
  query inquiryDocuments($slug: DocumentSlugInput!) {
    inquiryDocuments(slug: $slug) {
      fileName
      description
      helper
      action
      logicEnabled
    }
  }
`;

export const GET_USER_DOCUMENTS = gql`
  query getUserDocuments($slug: DocumentSlugInput!) {
    getUserDocuments(slug: $slug) {
      filename
      uid
      original
      type
    }
  }
`;

export const GET_COVER_LETTER = gql`
  query getCoverLetter($slug: CoverLetterInput!) {
    getCoverLetter(slug: $slug) {
      extraText
      subTitle
      title
    }
  }
`;

export const GET_PACKET = gql`
  query getPacketByInquirySlug($slug: String!) {
    getPacketByInquirySlug(slug: $slug) {
      items {
        name
        helperText
      }
    }
  }
`;

export const GET_PAID = gql`
  query checkIfApplicationIsPaid($slug: ApplicationInput!) {
    checkIfApplicationIsPaid(slug: $slug)
  }
`;

export const GET_APPLICATIONS = gql`
  {
    applications {
      slug
      title
      subtitle
      description
      progress
      state
      nextGroupSlug
      nextSubGroupSlug
      paid
      statusDocuments
      packetReview
      free
      nextStep {
        title
      }
      startItem {
        group
        subgroup
      }
    }
  }
`;

export const GET_LINK_DOWNLOAD = gql`
  query downloadApplication($slug: ApplicationInput!) {
    downloadApplication(slug: $slug) {
      link
      name
    }
  }
`;

export const GET_DISCOUNT = gql`
  query checkDiscountCode($packageId: ID!, $code: String!) {
    checkDiscountCode(packageId: $packageId, code: $code) {
      finalPrice
    }
  }
`;
