import styled from 'styled-components';
import colors from 'modules/colors';
import { Box } from '@rebass/grid/emotion';

export const PackageBox = styled(Box)`
  background-color: ${() => colors.blueDark};
  position: relative;
  max-width: 488px;
  border-radius: 5px;
`;
