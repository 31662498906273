import React, { useState } from 'react';
import PageTitle from 'components/molecules/PageTitle';
import ContainerApp from 'components/molecules/ContainerApp';
import { Redirect } from 'react-router-dom';
import { isAuthenticated, getUrlGroups } from 'modules/utils';
import { useQuery } from 'react-apollo-hooks';
import { Flex } from '@rebass/grid/emotion';
import { GET_COVER_LETTER } from 'graphql/application/queries';
import Icon from 'components/atoms/Icon';
import { Heading, Desc } from '../Review/style.js';
import { Blue } from '../Documents/style.js';

const Review = () => {
  const path = getUrlGroups();
  const { data } = useQuery(GET_COVER_LETTER, {
    variables: { slug: { inquirySlug: path.application } },
  });
  const [show, setShow] = useState(false);

  if (!isAuthenticated()) {
    return <Redirect to="/login" />;
  }

  return data ? (
    <>
      <PageTitle title="COVER LETTER" icon="cover" iconWidth={60} iconHeight={60} />
      <ContainerApp mw="510px">
        <Heading>{data.getCoverLetter && data.getCoverLetter.title}</Heading>
        <Flex justifyContent="center">
          <Icon name="review-answers" width={100} height={100} />
        </Flex>
        <Desc>{data.getCoverLetter && data.getCoverLetter.subTitle}</Desc>
        {data.getCoverLetter && (
          <Blue onClick={() => setShow(!show)}>What does the custom cover letter say?</Blue>
        )}
        {show && <Desc>{data.getCoverLetter && data.getCoverLetter.extraText}</Desc>}
      </ContainerApp>
    </>
  ) : (
    ''
  );
};

export default Review;
