import React from 'react';
import PropTypes from 'prop-types';
import { Question } from './style';
import HelperText from 'components/molecules/HelperText';

const FormQuestion = (props) => {
  const { question, helperText, questionTemplate } = props;
  const templates = [1, 5, 6];

  return (
  <Question className={`${templates.includes(questionTemplate) ? 'mb-30' : ''}`}>
    {question}
    {helperText && (
      <HelperText
        customClass="helper-text-container"
        text={helperText}
      />
    )}
  </Question>
  )
};

FormQuestion.propTypes = {
  question: PropTypes.string.isRequired,
};

export default FormQuestion;
