import React from 'react';
import PropTypes from 'prop-types';
import { PannelContainer } from './style';

const Pannel = (props) => {
  const { children, onClick, open } = props;

  return (
    <PannelContainer
      py={[30, 30, 30, 100]}
      px={2}
      width={[1, 1, 1, 2 / 9]}
      flex={1}
      alignSelf="flex-end"
      open={open}
      onClick={onClick}
    >
      {children}
    </PannelContainer>
  );
};

Pannel.propTypes = {
  padding: PropTypes.string,
};

export default Pannel;
