import styled from 'styled-components';
import colors from 'modules/colors';

export const LabelCheckbox = styled.label`
  font-size: 14px;
  line-height: 15px;
  font-weight: 500;
  color: ${() => colors.black};
  margin-right: 13px;
  padding-bottom: ${({ pb }) => pb || 0};
  display: flex;

  .helper-text-container {
    margin-left: 10px;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const Label = styled.span`
  display: inline-block;
  margin-left: 10px;
  padding-bottom: 12px;
`;