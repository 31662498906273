const colors = {
  blue: '#2F80ED',
  blueAliro: '#11546E',
  blueLight: '#6FB7D7',
  blueDark: '#14243D',

  grey: '#C4C4C4',
  greyDark: '#545454',
  greyMedium: '#E9E9E9',
  
  black: '#000000',
  blackLight: '#1B1B1B',

  red: '#C20034',
  yellow: '#FFDF8E',
  white: '#FFFFFF',
  green: '#2CA58D',

  error: '#B42A3E',

  fontColor: '#1B1B1B',
  applicationFocus: 'rgba(255, 223, 142, 0.1)',
};

export default colors;
