import React from 'react';
import { PackageBox } from './style';

const Package = ({ children, bestDeal }) => (
  <PackageBox width={1} mb={21} py={20} px={34} bestDeal={bestDeal}>
    {children}
  </PackageBox>
);

export default Package;
