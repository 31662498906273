import styled from 'styled-components';
import colors from 'modules/colors';

export const Title = styled.h2`
  display: block;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: ${() => colors.blackLight};
  margin-bottom: 50px;
  width: 90%;
  max-width: 570px;
`;
