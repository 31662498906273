import React, { useState, useEffect, useContext } from 'react';

import PageTitle from 'components/molecules/PageTitle';
import PageDescription from 'components/molecules/PageDescription';
import Banner from 'components/molecules/Banner';
import { Flex, Box } from '@rebass/grid/emotion';
import Button from 'components/molecules/Button';
import ContainerApp from 'components/molecules/ContainerApp';
import { RegisterModal } from 'components/organisms/Modals';
import { AppContext } from 'context/appContext';

import { getUrlGroups, isPreviewPage, isAuthenticated } from 'modules/utils';

const GroupPage = (props) => {
  const { history, inquiry } = props;
  const { dispatch } = useContext(AppContext);

  let inquiryState;

  const [dataGroup, setDataGroup] = useState(null);
  const [isPreview] = useState(isPreviewPage());

  function groupVariables() {
    const { application: slugInquiry, group: slugGroup } = getUrlGroups();
    return { slugInquiry, slugGroup, slugSubGroup: '' };
  }

  useEffect(() => {
    if (inquiry && inquiry.application) {
      const dataGroup = inquiry.application.body.find(
        item => item.slug === groupVariables().slugGroup,
      );
      setDataGroup(dataGroup);
    }
  }, [window.location.pathname]);

  function handleContinue() {
    const { search } = window.location;

    if (!dataGroup.items.length) {
      if (isPreview) return false;

      if (!isAuthenticated()) {
        return dispatch({ type: 'setModalOpened', showModal: RegisterModal });
      }

      if (inquiry.free) {
        history.push(`/applications/${groupVariables().slugInquiry}/review`);
      } else {
        history.push(`/applications/${groupVariables().slugInquiry}/payment`);
      }

      return false;
    }

    const href = isPreview
      ? `/applications/${groupVariables().slugInquiry}/${dataGroup.slug}/${
        dataGroup.items[0].slug
      }/preview${search}`
      : `/applications/${groupVariables().slugInquiry}/${dataGroup.slug}/${
        dataGroup.items[0].slug
      }`;

    history.push(href);
  }

  if (inquiry) inquiryState = inquiry.application.state;

  if (dataGroup && !dataGroup.description) handleContinue();

  return dataGroup && inquiry ? (
    <>
      {inquiryState === 'IN_REVIEW' && (
        <Banner title="Review Application:" text="Make any necessary changes and submit" />
      )}
      <PageTitle title={dataGroup.title} icon={dataGroup.icon} isImage />
      <ContainerApp>
        <PageDescription description="Important Instructions" size={18} />
        <PageDescription description={dataGroup.description} />
        <Box mt={5} width="100%">
          <Flex justifyContent="space-between">
            <div />
            <Button value="Continue" onClick={handleContinue} arrowRight margin="0" />
          </Flex>
        </Box>
      </ContainerApp>
    </>
  ) : (
    ''
  );
};

export default GroupPage;
