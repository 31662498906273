import React from 'react';
import PropTypes from 'prop-types';
import { InputElement } from './style';

const FormInput = ({
  type,
  value,
  placeholder,
  onChange,
  disabled,
  customStyle,
  validatorRegister,
  validatorConditions,
  name,
  maxLength,
  onInput,
  truevalue
}) => (
  <InputElement
    type={type}
    placeholder={placeholder}
    value={truevalue || undefined}
    defaultValue={value}
    onChange={onChange}
    disabled={disabled}
    customStyle={customStyle}
    name={name}
    maxLength={maxLength}
    onInput={onInput}
    ref={validatorRegister && validatorRegister(validatorConditions)}
  />
);

FormInput.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FormInput;
