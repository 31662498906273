import styled from 'styled-components';

export const CustomFlex = styled.div`
  box-sizing: border-box;
  margin-bottom: 50px;
  width: 96.7%;
  margin-left: auto;
  display: flex;
  flex-direction: ${props => props.flexDirection};
  justify-content: center;
  position: relative;
`;