import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import PageContainer from 'components/molecules/PageContainer';
import PannelApplication from 'components/templates/PannelApplication';
import PannelForm from 'components/templates/PannelForm';
import PannelHelp from 'components/templates/PannelHelp';
import { AppContext } from 'context/appContext';

import {
  getSlugUrlGroups, getPageType, getAllUrlParams, hideIfIsPreview,
} from 'modules/utils';
import { useQuery } from 'react-apollo-hooks';
import {
  GET_APPLICATION_INQUIRY,
  GET_APPLICATION_INQUIRY_PREVIEW,
  GET_APPLICATION_INQUIRY_PREVIEW_ANSWERS,
} from 'graphql/application/queries';

export default (props) => {
  const [inquiry, setInquiry] = useState('');
  const [pageType] = useState(getPageType());
  const { dispatch } = useContext(AppContext);

  let QUERY = null;
  let variables = null;

  const { token, answerId } = getAllUrlParams();
  const { slug } = getSlugUrlGroups();

  switch (pageType) {
    case 'previewAnswerPage':
      variables = { inquirySlug: slug, token, answerId };
      QUERY = GET_APPLICATION_INQUIRY_PREVIEW_ANSWERS;
      break;

    case 'previewPage':
      variables = { inquirySlug: slug, token };
      QUERY = GET_APPLICATION_INQUIRY_PREVIEW;
      break;

    default:
      variables = { slug };
      QUERY = GET_APPLICATION_INQUIRY;
  }

  const { data, error } = useQuery(QUERY, {
    variables,
  });

  function verifyMailingAddress(finalData) {
    const groups = finalData.application.body;

    groups.forEach(group => {
      group.items.forEach(subgroup => {
        const isMailing = subgroup.items.filter(
          question => question.general.isMailingAddress,
        );

        if (isMailing.length) {
          if (isMailing[0].choices.length < 9) {
            localStorage.setItem('USAddress', true);
          } else {
            localStorage.removeItem('USAddress');
          }
  
          dispatch({ type: 'setBillingAddress', billingAddress: isMailing });
          dispatch({ type: 'setMailingAddress', mailingAddress: isMailing });
  
          localStorage.setItem('address', JSON.stringify(isMailing));
          localStorage.setItem('mailingAddress', JSON.stringify(isMailing));
        }
      })
    });
  }

  useEffect(() => {
    if (data && !inquiry) {
      let finalData = null;

      switch (pageType) {
        case 'previewAnswerPage':
          finalData = data.answerPreview;
          break;

        case 'previewPage':
          finalData = data.inquiryPreview;
          break;

        default:
          finalData = data.inquiry;
          verifyMailingAddress(finalData);
      }


      setInquiry(finalData);
    }
  }, [data, inquiry, pageType]);

  useEffect(() => {
    sessionStorage.removeItem('inquiry');
  }, []);

  if (error) return <Redirect to="/your-profile" />;
  if (!inquiry) return <PageContainer />;

  return (
    inquiry && (
      <PageContainer>
        <PannelApplication inquiry={inquiry} />
        <PannelForm history={props.history} inquiry={inquiry} />
        {hideIfIsPreview(<PannelHelp history={props.history} />)}
      </PageContainer>
    )
  );
};
