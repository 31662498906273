import styled from 'styled-components';
import colors from 'modules/colors';
import { Box } from '@rebass/grid/emotion';

export const Container = styled.div`
  padding: 73px 0 146px;
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;

  @media (max-width: 39.99em) {
    height: 100%;
    padding: 0;
  }

  .h649 {
    height: 649px
    
    @media (max-width: 39.99em) {
      height: 100%;
    }
  }
`;

export const ContainerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 39.99em) {
    flex-direction: column;
  }
`;

export const Divider = styled(Box)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95%;

  &::before {
    content: '';
    background-color: ${() => colors.blueAliro};
    position: absolute;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    height: 100%;

    @media (max-width: 39.99em) {
      display: none;
    }
  }

  @media (max-width: 39.99em) {
  }
`;

export const Or = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.black};
  display: inline-block;
  background-color: ${() => colors.white};
  width: 23px;
  line-height: 70px;
  z-index: 10;
  box-sizing: content-box;

  @media (max-width: 39.99em) {
    display: none;
  }
`;

export const BottomLabel = styled.p`
  display: block;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${() => colors.black};
  margin-top: 45px;
  text-align: center;

  @media (max-width: 39.99em) {
    display: none;
  }
`;
