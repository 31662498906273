import styled from 'styled-components';
import colors from 'modules/colors';

export const Title = styled.h3`
  display: block;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  color: ${() => colors.white};
  margin-top: 0;
  margin-bottom: 50px;

  @media (max-width: 767px) {
    font-size: 27px;
    line-height: 35px;
    margin-bottom: 25px;
  }
`;
