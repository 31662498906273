import React from 'react';
import { Container, Wrapper } from './style';

const AccountContainer = (props) => {
  const { children } = props;

  return (
    <Container width={[1, 1, 1, 6 / 9]}>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
};

export default AccountContainer;
