import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AppContext } from 'context/appContext';
import BoxRegister from 'components/molecules/LoginRegisterBox';
import Title from 'components/molecules/LoginRegisterBox/Title';
import Form from 'components/molecules/LoginRegisterBox/Form';
import FormItem from 'components/molecules/LoginRegisterBox/FormItem';
import Input from 'components/molecules/LoginRegisterBox/Input';
import PasswordStrength from 'components/molecules/LoginRegisterBox/PasswordStrength';
import { verifyPasswordStrength, signin } from 'modules/utils';
import Error from 'components/molecules/Error';

import { Flex, Box } from '@rebass/grid/emotion';
import Button from 'components/molecules/Button';

import { useMutation } from 'react-apollo-hooks';
import { SIGN_UP } from 'graphql/user/mutations';

import { LoginModal } from 'components/organisms/Modals';
import { Text, Agreement, Link } from './style';
import { Sign } from '../LoginBox/style';

const RegisterBox = ({ modal }) => {
  const { state, dispatch } = useContext(AppContext);
  const [login, setLogin] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState('');
  const [sensitiveCases, setSensitiveCases] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [signup] = useMutation(SIGN_UP, {
    update: (cache, { data }) => {
      signin(data.signup);
      setLogin(true);
      dispatch({ type: 'setRegisterInvalid', registerInvalid: false });
      window.location.href = '/activate';
    },
  });

  function passwordChanged(password) {
    dispatch({ type: 'setPasswordLength', passwordLength: password.length });

    setPassword(password);
    setSensitiveCases(verifyPasswordStrength(password));
  }

  function getUser() {
    return { name, email, password };
  }

  function hideError() {
    setErrorMessage('');
  }

  async function preSignUp(e) {
    try {
      e.preventDefault();
      if (email === confirmEmail) {
        await signup({ variables: { user: getUser() } });
      } else {
        dispatch({ type: 'setRegisterInvalid', registerInvalid: true });
        setErrorMessage('Email does not match!');
        setTimeout(() => {
          hideError();
        }, 4000);
      }
    } catch (error) {
      dispatch({ type: 'setRegisterInvalid', registerInvalid: true });
      setErrorMessage(error.message.replace('GraphQL error: ', ''));
      setTimeout(() => {
        hideError();
      }, 4000);
    }
  }

  if(login) return <Redirect to="/your-account" />

  return (
    signup && (
      <Box
        width={[1, 1, modal ? 1 : 1 / 2]}
        className={`h100 ${state.showLogin ? 'hideLogin' : 'showLogin'}`}
      >
        <BoxRegister modal>
          <Title title="Create a Free Account" />
          {errorMessage && <Error>{errorMessage}</Error>}
          <Form onSubmit={preSignUp}>
            <FormItem>
              <Input
                type="text"
                placeholder="Full Name"
                value={name}
                onChange={ev => setName(ev.target.value)}
              />
            </FormItem>

            <FormItem>
              <Input
                type="text"
                placeholder="Email"
                value={email}
                onChange={ev => setEmail(ev.target.value)}
              />
            </FormItem>

            <FormItem>
              <Input
                type="text"
                placeholder="Confirm Email"
                value={confirmEmail}
                onChange={ev => setConfirmEmail(ev.target.value)}
              />
            </FormItem>

            <FormItem>
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={ev => passwordChanged(ev.target.value)}
              />
            </FormItem>

            <PasswordStrength sensitiveCases={sensitiveCases} />

            <Agreement>
              By continuing, I agree to LegalPilot’s
              {' '}
              <Link
                href="https://www.legalpilot.io/terms-and-conditions"
                target="_blank"
              >
                Terms of Use
              </Link>
              {' '}
              and
              {' '}
              <Link
                href="https://www.legalpilot.io/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </Link>
              .
            </Agreement>

            <Flex justifyContent="center">
              <Button
                value="Register"
                extraClass="secondary --bg-yellow"
                fixedWidth="152px"
                type="submit"
              />
            </Flex>

            <Sign
              mt="4"
              flexDirection="column"
              onClick={() => {
                dispatch({ type: 'setShowLogin', showLogin: true });
                window.location.pathname !== '/login'
                  && dispatch({ type: 'setModalOpened', showModal: LoginModal });
              }}
              className={`sign ${modal && 'sign--modal'}`}
              >
              <Text>Already have an account</Text>
              <Text yellow="true">Sign In</Text>
            </Sign>
          </Form>
        </BoxRegister>
      </Box>
    )
  );
};

export default RegisterBox;
