import styled from 'styled-components';
import colors from 'modules/colors';

export const Container = styled.div`
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const IconContainer = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: ${({ checked }) => (checked ? colors.blueAliro : colors.white)};
  border-width: 2px;
  border-style: solid;
  border-color: ${({ errors, name }) => (errors && errors[name] ? colors.red : colors.blueAliro)};

  .icon-checkbox {
    opacity: ${({ checked }) => (checked ? '1' : '0')};
  }
`;