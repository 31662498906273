import styled from 'styled-components';
import colors from 'modules/colors';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  background-color: ${() => colors.white};
  border: 1px solid ${() => colors.grey};
  border-top: 7px solid ${() => colors.blueDark};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 580px;
  margin: 0 auto 25px;
  padding: 50px 0;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Title = styled.h1`
  display: block;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: ${() => colors.blueAliro};
  margin-bottom: 30px;
`;

export const CompletedText = styled.span`
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.black};
  margin-bottom: 20px;
  text-align: ${({ align }) => align || 'left'};
`;

export const StatusBar = styled.span`
  position: relative;
  display: block;
  width: 100%;
  height: 18px;
  background-color: ${() => colors.white};
  border: 0.5px solid ${() => colors.grey};
  margin-bottom: 25px;

  &:after {
    content: '';
    display: block;
    width: ${({ status }) => `${status}%`};
    height: 100%;
    background-color: ${() => colors.yellow};
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.2s;
  }
`;

export const NextStepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  margin-bottom: 35px;
`;

export const NextStepsTitle = styled.span`
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.black};
  margin-bottom: 15px;
`;

export const NextStepsWrapper = styled.div`
  width: 50%;
`;

export const NextStepsList = styled.div`
  padding-left: 15px;
`;

export const NextStepsItem = styled.span`
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.black};
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const BadgesList = styled.div`
  width: 50%;
`;

export const BadgesListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const BadgesListItemText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${() => colors.black};
  padding-left: 10px;
`;

export const ContainerButton = styled(Link)`
  display: flex;
  flex-direction: row-reverse;

  .button {
    margin: 0;
  }
`;

export const Delete = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.greyDark};
  cursor: pointer;
  position: absolute;
  bottom: 9px;
  left: 14px;
`;
