import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: ${({ mw }) => mw || '562px'};

  .-grouped-answers + .-grouped-answers.-no-title {
    margin-top: -47px;
  }

  .buttons-container {
    margin-top: 100px;
  }

  .grouped-answer-pt1 {
    padding-right: 15px;
  }

  .grouped-answer-pt2 {
    width: auto;
    margin-left: auto;
  }
`;
