import styled from 'styled-components';
import { Box } from '@rebass/grid/emotion';
import colors from 'modules/colors';

export const BoxSign = styled(Box)`
  width: 100%;
  /* max-width: 560px; */
  height: 100%;
  /* height: 560px; */
  padding: 50px 10%;

  background-color: ${() => colors.blueAliro};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 39.99em) {
    border-radius: 0;
  }

  @media (max-width: 51.99em) {
    padding: 10px 20px;
  }
`;
