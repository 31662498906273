import styled from 'styled-components';
import colors from 'modules/colors';

export const Box = styled.div`
  display: 100%;
  max-width: 580px;
  margin: 0 auto;
  padding: 50px 30px;
  background-color: ${() => colors.white};
  border: 1px solid ${() => colors.grey};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

export const BoxTitle = styled.h3`
  display: block;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${() => colors.blueAliro};
  margin-bottom: 35px;

  &:after {
    content: '';
    display: block;
    width: 100%;
    max-width: 290px;
    height: 1px;
    background-color: ${() => colors.blueLight};
    margin-top: 11px;
  }
`;

export const BoxForm = styled.div`
  width: 100%;
  padding: 0 25px;
`;
