import React, { useState, useEffect, useContext } from 'react';
import useForm from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import PageTitle from 'components/molecules/PageTitle';
import FormQuestion from 'components/molecules/Form/FormQuestion';
import Button from 'components/molecules/Button';
import ContainerApp from 'components/molecules/ContainerApp';
import Banner from 'components/molecules/Banner';
import { Flex, Box } from '@rebass/grid/emotion';
import {
  isAuthenticated, getSlugUrlGroups, getUrlGroups, isPreviewPage
} from 'modules/utils';
import { SAVE_ANSWER } from 'graphql/application/mutations';
import { useMutation } from 'react-apollo-hooks';
import { AppContext } from 'context/appContext';

import Error from 'components/molecules/Error';
import { PopupModal, PopupModalDouble, RegisterModal, BeforeYouGo } from 'components/organisms/Modals';
import HelperText from 'components/molecules/HelperText';
import InputAnswer from '../../molecules/Form/InputAnswer';
import DropdownList from '../../molecules/Form/DropdownList';
import ShortAnswer from '../../molecules/Form/ShortAnswer';
import LongAnswer from '../../molecules/Form/LongAnswer';
import SingleChoice from '../../molecules/Form/SingleChoice';
import MultipleChoice from '../../molecules/Form/MultipleChoice';
import ButtonAnswer from '../../molecules/Form/ButtonAnswer';
import GroupedAnswer from '../../molecules/Form/GroupedAnswer';

import { CustomFlex } from './style';

import { InputGroup } from '../USAddress/style';
import {
  Container,
  Label,
  Border,
  FormInputYellow,
  FormInputYellowSmall,
  Divider,
} from '../../molecules/Form/ZipCode/style';

import { Error as ErrorMessage } from '../../molecules/Form/InputAnswer/style.js';

const SubGroupPage = (props) => {
  const { history, inquiry } = props;
  const { state, dispatch } = useContext(AppContext);

  const {
    handleSubmit, register, errors, setError, clearError, watch, reset: resetForm,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const [dataGroup, setDataGroup] = useState(null);
  const [dataSubGroup, setDataSubGroup] = useState(null);
  const [dataQuestions, setDataQuestions] = useState(null);
  const [shouldShowPopup, setShouldShowPopup] = useState(true);
  const [isLastSubGroup] = useState(false);
  const [subGroupLocked, setSubGroupLocked] = useState(false);
  const [inquiryGroup, setInquiryGroup] = useState('');

  const [cloneValue, setCloneValue] = useState([]);
  const [isPreview] = useState(isPreviewPage());

  const [arrAnswers, setArrAnswers] = useState([]);

  let [address, setAddress] = useState([]);
  let [usAddress, setUsAddress] = useState([]);

  const arrType = ['text', 'number', 'date', 'phone', 'email', 'select'];

  const path = getUrlGroups();

  let inquiryState;
  const zipFirstInputRef = [];
  const zipSecondInputRef = [];

  const [saveAnswer] = useMutation(SAVE_ANSWER, {
    update: (cache, { data }) => {
      // handleLogic();
      dispatch({ type: 'setProgress', progress: parseInt(data.saveAnswer.progress, 10) });
      dispatch({ type: 'setSavingStatus', savingStatus: 'Application progress saved!'});

      setTimeout(() => { // TODO: Move this to the right place
        dispatch({ type: 'setSavingStatus', savingStatus: '' });
      }, 5000);
    },
  });

  const [errorMessage, setErrorMessage] = useState('');

  function getAnsweredQuestions() {
    const choicesUids = [];
    const choicesValue = [];
    const choicesSubGroup = [];
    const choicesField = [];
    const choicesWithValues = [];

    const groups = inquiry.application.body;

    groups.forEach((group) => {
      group.items.forEach((subgroup) => {
        subgroup.items.forEach((questions) => {
          questions.choices.forEach((choice) => {
            if (choice.value) {
              choicesSubGroup.push({ subGroup: subgroup.uid, question: questions.uid, value: choice.value });
              choicesField.push({ field: choice.uid, equalTo: choice.value.toLowerCase() });
              choicesUids.push(choice.uid);
              choicesValue.push(choice.value);
              setCloneValue([...cloneValue, choice.value]);
            }
          });
        });
      });
    });

    return {
      choicesSubGroup,
      choicesUids,
      choicesValue,
      choicesField,
    };
  }

  function updateLogics(questions) {
    for (let i=0; i<questions.length; i++) {
      const { choicesUids, choicesValue, choicesField } = getAnsweredQuestions();

    questions.filter((item) => {
      const question = item;
      const { logic } = question;
      const { viewControl, paramControl } = logic;
      const paramUids = choicesUids.indexOf(paramControl.answer);

      if (logic && viewControl) {
        let locked = true;

        viewControl.forEach((vc) => {
          if (!vc.equalTo) return;

          if (choicesUids.indexOf(vc.equalTo) !== -1) locked = false;

          const checkLogic = choicesField.filter(choice => (vc.field !== ''
          ? choice.field === vc.field
                && choice.equalTo === vc.equalTo.toLowerCase()
          : choice.field === vc.equalTo)).length === 0;

          if (checkLogic === false) locked = false;
          if (choicesValue.indexOf(vc.equalTo) !== -1) locked = false;

          question.locked = locked;
        });

        if (question.template === 9 && question.locked) {
          question.choices[0].value = null;
          question.choices[1].value = null;
        }

        if (question.template === 3 && question.locked) {
          question.choices[0].value = '';
        }
      }

      if (logic && paramControl) {
        if (!paramControl.answer) return false;
        if (question.title.includes('{param}')) {
          question.title = question.title.replace('{param}', choicesValue[paramUids] || '_ _ _ _');
        } else if (question.title.includes('_ _ _ _')) {
          question.title = question.title.replace('_ _ _ _', choicesValue[paramUids] || '_ _ _ _');
        } else {
          question.title = question.title.replace(
            cloneValue[paramUids],
            choicesValue[paramUids] || '_ _ _ _',
          );
        }
      }

      item.choices.forEach((choice) => {
        if (logic && viewControl) {
          choice.logic.viewControl.forEach((vc) => {
            if (!vc.equalTo) return;

            choice.locked = choicesUids.indexOf(vc.equalTo) === -1;
          });
        }

        return choice;
      });

      return question;
    });

    // handleGroupLogic();
    }

    return questions;
  }

  function handleGroupLogic() {
    const { choicesField } = getAnsweredQuestions();

    const { body } = inquiry.application;

    body.forEach((group) => {
      group.logic.viewControl.forEach((vc) => {
        if (!vc.equalTo) return;

        const checkLogic = choicesField.filter(choice => (vc.field !== ''
          ? choice.field === vc.field
                && choice.equalTo === vc.equalTo.toLowerCase()
          : choice.field === vc.equalTo)).length === 0;

        group.locked = checkLogic;
      });
    });

    dispatch({ type: 'setInquiry', inquiry });
  }

  function handleSubGroupLogic() {
    const { choicesSubGroup, choicesField } = getAnsweredQuestions();

    const { body } = inquiry.application;

    body.forEach((group) => {
      group.items.forEach((subgroup) => {
        subgroup.logic.viewControl.forEach((viewControl) => {
          let checkLogic = false;
          let checkLogicEqualTo = false;

          if (viewControl.lessOrEqualTo) {
            checkLogic = choicesSubGroup.filter(choice => {
              return choice.subGroup === viewControl.subGroup
              && choice.question === viewControl.question
              && parseFloat(choice.value) >= parseFloat(viewControl.lessOrEqualTo)
            });
          }

          if (viewControl.equalTo) {
            checkLogicEqualTo = checkLogicEqualTo = choicesField.filter(choice => (viewControl.field !== ''
              ? choice.field === viewControl.field
                    && choice.equalTo === viewControl.equalTo.toLowerCase()
              : choice.field === viewControl.equalTo)).length === 0;
          }

          if (viewControl.action === 'unlock') {
            subgroup.locked = checkLogic ? checkLogic.length === 0 : checkLogicEqualTo;
          }

          if (viewControl.action === 'lock') {
            subgroup.locked = checkLogic ? checkLogic.length > 0 : checkLogicEqualTo;
          }
        });
      });
    });

    dispatch({ type: 'setInquiry', inquiry });
  }

  function handleLogic() {
    if (!dataQuestions) return;

    setShouldShowPopup(true);

    const questions = updateLogics(dataQuestions.questions);

    setDataQuestions({
      title: dataQuestions.title,
      questions,
    });

    // handleGroupLogic();
  }

  function checkboxChanged(event, questionIndex, choiceIndex) {
    const { value, checked } = event.target;

    const data = dataQuestions.questions;
    const question = data[questionIndex];
    const choice = question.choices[choiceIndex];
    choice.value = value;

    if (!checked) {
      choice.value = '';
    }

    setDataQuestions({
      questions: data,
    });

    handleLogic();
    clearError(`multiple-choice-${question.uid}-0`);
  }

  function clearAllLockedAddresses(questions) {
    questions.forEach(question => {
      if ((question.template === 5 || question.template === 6) && question.locked) {
        question.choices.forEach(choice => {
          choice.value = '';
        })
      }
    });

    return questions;
  }

  function radioChanged(event, questionIndex, choiceIndex, clear) {
    let { questions } = dataQuestions;

    const question = questions[questionIndex];

    if (clear) {
      question.choices.forEach((item) => {
        const choice = item;
        choice.value = '';
      });
    }

    let yesOrNoChoice = '';

    if (event.target.value === 'Yes' && question.template === 10) {
      yesOrNoChoice = question.choices[choiceIndex+1];
      question.choices[choiceIndex+2].value = null;
    }
    else if (event.target.value === 'No' && question.template === 10) {
      yesOrNoChoice = question.choices[choiceIndex+2];
      question.choices[choiceIndex+1].value = null;
    }

    const choice = question.choices[choiceIndex];
    choice.value = event.target.value;
    if (yesOrNoChoice) yesOrNoChoice.value = event.target.value;

    questions = clearAllLockedAddresses(questions);

    setDataQuestions({
      questions
    });

    handleLogic();
    clearError(`single-choice-${question.uid}`);
    clearError(`grouped-answers-${choice.uid}`);
  }

  function phoneChanged(event, questionIndex, choiceIndex) {
    const data = dataQuestions.questions;
    const question = data[questionIndex];
    const choice = question.choices[choiceIndex];

    choice.value = event;

    setDataQuestions({
      questions: data,
    });
  }

  function answerChanged(event, questionIndex, choiceIndex, isCountry) {
    const data = dataQuestions.questions;
    const question = data[questionIndex];
    const choice = question.choices[choiceIndex];
    choice.value = isCountry ? event : event.target.value;

    setDataQuestions({
      questions: data,
    });
  }

  function dropdownChanged(event, questionIndex, choiceIndex) {
    const data = dataQuestions.questions;
    const question = data[questionIndex];

    const choice = question.choices[choiceIndex];
    choice.value = event;

    setDataQuestions({
      questions: data,
    });

    handleLogic();
  }

  function addressChanged(event, questionIndex, choiceIndex, type) {
    const data = dataQuestions.questions;

    const question = data[questionIndex];
    const choice = question.choices[choiceIndex];
    choice.value = event.query || event.target.value;

    if (type === 'radio') {
      switch (choiceIndex) {
        case 1:
          question.choices[2].value = '';
          question.choices[3].value = '';
          break;
        case 2:
          question.choices[1].value = '';
          question.choices[3].value = '';
          break;
        case 3:
          question.choices[1].value = '';
          question.choices[2].value = '';
          break;
        default:
          question.choices[1].value = '';
          question.choices[2].value = '';
          question.choices[3].value = '';
      }
    }

    setDataQuestions({
      questions: data,
    });
  }

  function zipChanged(event, questionIndex, choiceIndex) {
    const data = dataQuestions.questions;
    const question = data[questionIndex];

    const choice = question.choices[choiceIndex];
    let newValue = event.target.value.replace(/\D+/g, '');

    if (newValue.length >= 5) newValue = newValue.substring(0, 5);
    choice.value = newValue;

    if (choice.value.length === 5) {
      zipSecondInputRef[questionIndex].focus();
    }

    setDataQuestions({
      questions: data,
    });
  }

  function dateChanged(event, questionIndex, choiceIndex) {
    const data = dataQuestions.questions;
    const question = data[questionIndex];
    const choice = question.choices[choiceIndex];

    let { value } = event.target;
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '$1/$2');
    value = value.replace(/(\d{2})(\d)/, '$1/$2');

    value = value.replace(/(\d{2})(\d{2})/, '$1$2');
    // setDate(value)
    choice.value = value;

    // choice.value = value.split('')
    // .reverse()
    // .slice(0, 10)
    // .reverse()
    // .join('');

    setDataQuestions({
      questions: data,
    });

    clearError(`short-answer-${question.uid}`);
  }

  function handleInputType(typeItem) {
    const type = arrType[typeItem];

    if (type === 'phone') {
      return 'tel';
    }

    if (type === 'date') {
      return 'text';
    }

    if (type === 'number') {
      return 'text';
    }

    return type;
  }

  function updateInquiryState() {
    dispatch({ type: 'setInquiry', inquiry });
  }

  function validateMultipleChoice() {
    const result = {};

    const elements = document.querySelectorAll('.multiple-choice');

    elements.forEach((element) => {
      const { name } = element;
      const isChecked = element.checked;
      result[name] = isChecked;
    });

    const resultKeys = Object.keys(result);
    const names = [];
    const checkboxes = {};
    let hasErrors = false;

    resultKeys.forEach((key) => {
      const fullName = key.split('-');
      const name = `${fullName[0]}-${fullName[1]}-${fullName[2]}`;
      if (fullName[0] === 'multiple' && !names.includes(name)) names.push(name);
    });

    names.forEach((name) => {
      resultKeys.forEach((key) => {
        if (key.includes(name)) {
          if (checkboxes[name]) checkboxes[name].push({ key, value: result[key] });
          else checkboxes[name] = [{ key, value: result[key] }];
        }
      });
    });

    const valids = {};

    names.forEach((name) => {
      resultKeys.forEach((key) => {
        if (key.includes(name)) {
          valids[name] = false;
        }
      });
    });

    const checkboxesKeys = Object.keys(checkboxes);

    checkboxesKeys.forEach((key) => {
      checkboxes[key].forEach((checkbox) => {
        if (checkbox.value) valids[key] = true;
      });
    });

    const validKeys = Object.keys(valids);

    validKeys.forEach((key) => {
      if (!valids[key]) {
        setError(`${key}-0`, 'required');
        hasErrors = true;
      }
    });

    return !hasErrors;
  }

  function handleCache() {
    if (!isAuthenticated()) {
      const currentAnswers = JSON.parse(sessionStorage.getItem('inquiry')) || {};

      if (arrAnswers.length) {
        const arrayGroup = currentAnswers[arrAnswers[0].slugs.group];
        if (arrayGroup && arrayGroup.length) {
          arrAnswers.forEach(item => {
            const found = arrayGroup.find(group => item.slugs.subgroup === group.slugs.subgroup);

            if (found) found.questions = item.questions;
            else currentAnswers[arrAnswers[0].slugs.group].push(item);
          });
        } else {
          currentAnswers[arrAnswers[0].slugs.group] = arrAnswers;
        }

      }

      sessionStorage.setItem('inquiry', JSON.stringify(currentAnswers));
    }
  }

  useEffect(() => {
    handleCache();
  }, [arrAnswers])

  useEffect(() => {
    handleCache();
  }, []);

  function getRequiredAndCompletedFieldsFromQuestion(question) {
    let required = 0;
    let completed = 0;
    if (question.required) {
      required = 1;
    }
    for (const choice of question.choices) {
      const questionRequiredAndCompleted = (choice.value && question.required);
      if (questionRequiredAndCompleted) {
        return {
          required,
          completed: 1
        };
      }
      const inputRequired = (choice.required && !question.required);
      if (inputRequired) {
        required++;
      }
      const inputRequiredAndCompleted = choice.value && choice.required;
      if (inputRequiredAndCompleted) {
        completed++;
      }
    }
    return {
      required,
      completed
    };
  }


  function updateOfflineProgress() {
    if (!isAuthenticated()) {
      let required = 0, completed = 0;
      for (const group of inquiry.application.body) {
        if (group.locked) continue;
        for (const subgroup of group.items) {
          if (subgroup.locked) continue;
          for (const question of subgroup.items) {
            if (question.locked) continue;
            const res = getRequiredAndCompletedFieldsFromQuestion(question);
            required += res.required;
            completed += res.completed;
          }
        }
      }

      const progress = Math.round(completed / required * 100);

      dispatch({ type: 'setProgress', progress });
    }
  };


  async function onSubmitContinue() {
    if (!validateMultipleChoice()) return false;

    try {
      setAddress([]);
      setUsAddress([]);

      const popupItems = dataQuestions.questions.filter(
        item => (item.template === 11 && item.locked === false) || (item.template === 12 && item.locked === false),
      );

      if (popupItems.length && shouldShowPopup) {
        const item = popupItems[0];

        // setShouldShowPopup(false);

        if(item.template === 11) {
          dispatch({
            type: 'setModalOpened',
            showModal: PopupModal,
            modalProps: {
              title: item.title,
              description: item.message,
              close: item.hasCloseButton,
              imageServerName: item.imageServerName,
              buttonType: item.buttonType,
              buttonLink: item.buttonLink,
              actions: {
                setShouldShowPopupToFalse: async () => setShouldShowPopup(false),
                submit: () => document.querySelector('button[type="submit"]').click()
              },
            },
          });
        } else {
          dispatch({
            type: 'setModalOpened',
            showModal: PopupModalDouble,
            modalProps: {
              title: item.title,
              description: item.message,
              close: item.hasCloseButton,
              boxMessage: item.secondMessage,
              buttonLink: item.link,
              checkText: item.checkBoxText,
              imageServerName: item.imageServerName,
              actions: {
                setShouldShowPopupToFalse: async () => setShouldShowPopup(false),
                submit: () => document.querySelector('button[type="submit"]').click()
              },
            },
          });
        }
        return false;
      }

      const { slug, slugGroup, slugSubGroup } = getSlugUrlGroups();

      const questions = dataQuestions.questions.map((question) => {
        const answer = question.choices.map(choice => ({
          _id: choice._id,
          value: choice.value !== '' ? choice.value : null,
        }));
        return { _id: question._id, choices: answer };
      });

      const answer = {
        slugs: {
          inquiry: slug,
          group: slugGroup,
          subgroup: slugSubGroup,
        },
        questions,
      };

      const allAnswers = [...arrAnswers, answer];

      setArrAnswers(allAnswers);

      if (isAuthenticated()) {
        if (!isPreview) await saveAnswer({ variables: { answer } });
      } else if (!isPreview) {
        // sessionStorage.setItem('inquiry', JSON.stringify(arrAnswers));
      }

      handleGroupLogic();
      handleSubGroupLogic();

      const isMailing = dataQuestions.questions.filter(
        question => question.general.isMailingAddress,
      );

      if (isMailing.length) {
        if (isMailing[0].choices.length < 9) {
          localStorage.setItem('USAddress', true);
        } else {
          localStorage.removeItem('USAddress');
        }

        dispatch({ type: 'setBillingAddress', billingAddress: isMailing });
        dispatch({ type: 'setMailingAddress', mailingAddress: isMailing });

        localStorage.setItem('address', JSON.stringify(isMailing));
        localStorage.setItem('mailingAddress', JSON.stringify(isMailing));
      }

      const doneGroup = inquiry.application.body.find(group => group.slug === slugGroup);
      const doneSubgroup = doneGroup ? doneGroup.items.find(subgroup => subgroup.slug === slugSubGroup) : null;

      if (doneSubgroup) doneSubgroup.status = 'DONE';

      const selectedSubgroups = doneGroup.items;

      const doneSubgroups = selectedSubgroups.filter(subgroup => !subgroup.locked).filter(subgroup => subgroup.status === 'DONE');

      const unlockedSubgroups = selectedSubgroups.filter(subgroup => !subgroup.locked);

      selectedSubgroups.filter(subgroup => subgroup.locked).forEach(subgroup => {
        subgroup.status = 'UNSTARTED';
        subgroup.items.forEach(question => {
          question.choices.forEach(choice => {
            choice.value = '';
          });
        });
      });

      if (doneSubgroups.length === unlockedSubgroups.length) {
        doneGroup.status = 'DONE';
      } else {
        doneGroup.status = 'DOING';
      }

      updateOfflineProgress();

      const { hasMoreGroup, hasMoreSubGroup } = getNextStepsInformation();
      let href;
      const { search } = window.location;

      // if (hasMoreSubGroup[0]) hasMoreSubGroup = hasMoreSubGroup[0].filter(sub => sub !== false);
      if (hasMoreSubGroup[0]) {
        href = isPreview
          ? `/applications/${slug}/${slugGroup}/${hasMoreSubGroup[0].slug}/preview${search}`
          : `/applications/${slug}/${slugGroup}/${hasMoreSubGroup[0].slug}`;

          if (isPreview) {
            setTimeout(() => {
              history.push(href);
            })
          } else {
            history.push(href);

          }
      } else if (hasMoreGroup[0]) {
        inquiry.application.body.filter(
          item => item.slug === hasMoreGroup[0].slug
            && item.description
            && (href = isPreview
              ? `/applications/${slug}/${hasMoreGroup[0].slug}/preview${search}`
              : `/applications/${slug}/${hasMoreGroup[0].slug}`),
        );

        if (!href) {
          inquiry.application.body.filter(
            item => item.slug === hasMoreGroup[0].slug
              && (href = isPreview
                ? `/applications/${slug}/${hasMoreGroup[0].slug}/preview${search}`
                : `/applications/${slug}/${hasMoreGroup[0].slug}`),
          );
        }

        if (isPreview) {
          setTimeout(() => {
            history.push(href);
          })
        } else {
          history.push(href);
        }

      } else {
        if (isPreview) return false;

        if (!isAuthenticated()) {
          return dispatch({ type: 'setModalOpened', showModal: RegisterModal });
        }

        if (inquiry.free) {
          history.push(`/applications/${slug}/review`);
        } else {
          history.push(`/applications/${slug}/payment`);
        }
      }
    } catch (error) {
      setErrorMessage(error.message.replace('GraphQL error: ', ''));
    }

    return true;
  }

  function getNextStepsInformation() {
    const groups = inquiry.application.body;

    const { slugGroup, slugSubGroup } = getSlugUrlGroups();

    const groupsUnlock = groups.filter(g => !g.locked);
    let subGroupsUnlock = groups.find(group => group.slug === slugGroup);
    subGroupsUnlock = subGroupsUnlock.items.filter(subgroup => !subgroup.locked);

    const hasMoreGroup = groupsUnlock
      .map((a, i) => a.slug === slugGroup && groupsUnlock[i + 1])
      .filter(f => f !== false);

    const hasMoreSubGroup = subGroupsUnlock
      .map((item, idx) => item.slug === slugSubGroup && subGroupsUnlock[idx + 1])
      .filter(f => f !== false);

    return { hasMoreGroup, hasMoreSubGroup };
  }

  useEffect(() => {
    const addEvent = (obj, evt, fn) => {
      if (obj.addEventListener) {
        obj.addEventListener(evt, fn, false);
      } else if (obj.attachEvent) {
        obj.attachEvent(`on${evt}`, fn);
      }
    };

    const removeEvent = (obj, evt, fn) => {
      if (obj.removeEventListener) {
        obj.removeEventListener(evt, fn, false);
      } else if (obj.attachEvent) {
        obj.detachEvent(`on${evt}`, fn);
      }
    };

    const openModal = function() {
      dispatch({
        type: 'setModalOpened',
        showModal: BeforeYouGo,
        isLeaving: true,
      });
    }

    dispatch({
      type: 'setFnModal',
      fnModal: openModal
    });

    if (!isAuthenticated() && !isPreview && !state.isModalOpened) {
      updateInquiryState();

      const storageInquiry = JSON.parse(sessionStorage.getItem('inquiry'));
      const storageLength = Object.keys(storageInquiry).length;

      if (storageLength > 0) {
        addEvent(document, 'mouseleave', state.fnModal);
      }

      setTimeout(() => {
        resetForm();
      });
    } else {
      removeEvent(document, 'mouseleave', state.fnModal);
    }
  }, [state.isModalOpened, window.location.pathname]);

  useEffect(() => {
    // if (isPreview) {
    //   const { hasMoreGroup, hasMoreSubGroup } = getNextStepsInformation();

    //   const isLastSubGroup = !hasMoreGroup[0] && !hasMoreSubGroup[0];

    //   setIsLastSubGroup(isLastSubGroup);
    // }

    if (!path.subGroup) return;

    handleGroupLogic();
    handleSubGroupLogic();

    const { body } = inquiry.application;
    const { slugGroup, slugSubGroup } = getSlugUrlGroups();

    const applicationGroup = body.filter(item => item.slug === slugGroup)[0];
    if (!applicationGroup) return;

    const subGroup = applicationGroup.items.filter(item => item.slug === slugSubGroup)[0];

    setInquiryGroup(applicationGroup);

    if (!subGroup) return;

    const questions = subGroup.items.map((question) => {
      question.choices.forEach((choice) => {
        if (!choice.value) {
          choice.value = '';
        }
      });
      return question;
    });

    setDataGroup(applicationGroup);
    setDataSubGroup(subGroup);
    setDataQuestions({
      title: subGroup.title,
      questions: updateLogics(questions),
    });
  }, [window.location.pathname]);

  useEffect(() => {
    if (inquiryGroup) {
      inquiryGroup.items
        && inquiryGroup.items.filter(
          subgroup => subgroup.slug === getSlugUrlGroups().slugSubGroup && setSubGroupLocked(subgroup.locked),
        );
    }
  }, [inquiryGroup]);

  if (address.length > 0) {
    const addressInputs = [
      'name',
      '',
      '',
      '',
      'unit',
      'city',
      'county',
      'administrative',
      'postcode',
      '',
      'province',
      'postcode',
      'country',
    ];

    // address.map(
    //   (a, idx) => a.suggestion
    //     && dataQuestions.questions[idx].choices.map(
    //       (d, i) => dataQuestions.questions[idx].template === 5
    //         && (i === 0 || i > 3)
    //         && (d.value = i > addressInputs.length || !a.suggestion[addressInputs[i]]
    //           ? ''
    //           : a.suggestion[addressInputs[i]]),
    //     ),
    // );
    address.map(
      (a, idx) => a.suggestion
        && dataQuestions.questions[idx].choices.map(
          (d, i) => dataQuestions.questions[idx].template === 5
            && (i === 0 || i > 3)
            && (d.value = i > addressInputs.length || !a.suggestion[addressInputs[i]]
              ? ''
              : a.suggestion[addressInputs[i]]),
        ),
    );
  }
  if (usAddress.length > 0) {
    const usAddressInputs = [
      'name',
      '',
      '',
      '',
      'unit',
      'city',
      'county',
      'administrative',
      'postcode',
    ];

    usAddress.map(
      (a, idx) => a.suggestion
        && dataQuestions.questions[idx].choices.map(
          (d, i) => dataQuestions.questions[idx].template === 6
            && (i === 0 || i > 3)
            && (d.value = i > usAddressInputs.length || !a.suggestion[usAddressInputs[i]]
              ? ''
              : a.suggestion[usAddressInputs[i]]),
        ),
    );

    // if (dataQuestions) {
    //   console.log(dataQuestions.questions[0].choices[6].value);
    // }

    // usAddress.map(
    //   (a, idx) => a.suggestion
    //     && dataQuestions.questions[idx].choices.map(
    //       (d, i) => dataQuestions.questions[idx].template === 6
    //         && (i === 0 || i > 3)
    //         && (d.value = i > addressInputs.length || !a.suggestion[addressInputs[i]]
    //           ? null
    //           : a.suggestion[addressInputs[i]]),
    //     ),
    // );
    // dataQuestions.questions.map(
    //   d => d.template === 6
    //     && d.choices.map(
    //       (c, i) => (i === 0 || i > 3)
    //         && (c.value = i > addressInputs.length || !usAddress.suggestion[addressInputs[i]]
    //           ? null
    //           : usAddress.suggestion[addressInputs[i]]),
    //     ),
    // );
  }

  address = [];
  usAddress = [];

  if (inquiry) inquiryState = inquiry.application.state;

  return inquiryGroup.locked || subGroupLocked ? (
    <Redirect to="/your-account" />
  ) : dataQuestions ? (
    <>
      {inquiryState === 'IN_REVIEW' && (
        <Banner title="Review Application:" text="Make any necessary changes and submit" />
      )}
      {inquiryState === 'SUBMITTED' && (
        <Banner title="Locked Application:" text="You already submitted and can no longer edit" />
      )}
      <PageTitle title={dataSubGroup.title} icon={dataGroup.icon} isImage />
      <ContainerApp mw="464px">
        <form onSubmit={handleSubmit(onSubmitContinue)} noValidate>
          {dataQuestions.questions.map((question, i) => {
            let {
              title, template, choices, general, uid,
            } = question;

            if (template === 11 || template === 12) return false;

            const { required, locked } = question;

            let list = [];
            if (choices) {
              list = choices.filter(choice => choice.value !== '');
            } else {
              title = question.map(q => q.title);
              template = question.map(q => q.template);
              choices = question.map(q => q.choices);
              list = question.map(q => q.choices.filter(choice => choice.value !== ''));
            }
            return (
              !locked && (
                <Box key={i} className={`${!title ? '-no-title': ''} ${template === 10 ? '-grouped-answers' : ''}`}>
                  {template === 2 ? (
                    <Flex justifyContent="center" pb={50}>
                      <DropdownList
                        label={title}
                        options={choices
                          .filter(choice => !choice.locked)
                          .map(unlock => [unlock.text])}
                        optionSelected={list.length > 0 ? list[0].value : ''}
                        helperText={general.helperText}
                        name={`list-answer-${uid}`}
                        validatorRegister={register}
                        validatorConditions={{ required }}
                        errorMessage={general.errorMessage}
                        errors={errors}
                        onChange={e => choices.map(
                          (choice, idx) => e.target[e.target.selectedIndex].value === choice.text
                              && dropdownChanged(choice.text, i, idx),
                        )
                        }
                      />
                    </Flex>
                  ) : (
                    <>
                      {title && (
                        <Flex>
                        {template !== 11 && template !== 12 && (
                          <FormQuestion
                            question={title}
                            questionTemplate={template}
                            errors={errors}
                            data={question}
                            helperText={general.helperText}
                          />
                        )}
                      </Flex>
                      )}

                      <CustomFlex flexDirection={template === 9 ? 'row' : 'column'}>
                        {template === 7 && errors && errors[`single-choice-${uid}`] && (
                          <ErrorMessage className="error-message">
                            {`${general.errorMessage || 'This field is required'}`}
                          </ErrorMessage>
                        )}
                        {template === 8 && errors && errors[`multiple-choice-${uid}-0`] && (
                          <ErrorMessage className="error-message">
                            {`${general.errorMessage || 'This field is required'}`}
                          </ErrorMessage>
                        )}
                        {template === 9 && errors && errors[`button-answer-${uid}`] && (
                          <ErrorMessage className="error-message">
                            {`${general.errorMessage || 'This field is required'}`}
                          </ErrorMessage>
                        )}

                        {choices.map((choice, index) => {
                          const {
                            text,
                            label,
                            type,
                            locked,
                            value,
                            choicesSelect,
                            isCountry,
                            general: choiceGeneral,
                            uid,
                            isFirstField
                          } = choice;
                          const choicesSelectList = choicesSelect
                            ? choicesSelect.map(option => option.text)
                            : [];

                          return (
                            !locked
                            && (template === 1 && arrType[type] === 'select' && isCountry ? (
                              <InputAnswer
                                key={uid}
                                label={label}
                                type="search"
                                className="address--no-autocomplete"
                                autoComplete="off"
                                isAddress
                                defaultValue={watch(uid) === '' ? watch(uid) : watch(uid) || value}
                                // defaultValue={dataQuestions.questions[i].choices[0].value}
                                onChange={(e) => {
                                  answerChanged(e.suggestion.value, i, index, 'country');
                                }}
                                options={{
                                  appId: 'plCZD21OAEFL',
                                  apiKey: 'f47907e615cfbb4d0d7565be8e12546d',
                                  type: 'country',
                                }}
                                name={uid}
                                validatorRegister={register}
                                validatorConditions={{ required: choice.required }}
                                errorMessage={choiceGeneral.errorMessage}
                                errors={errors}
                                helperText={choiceGeneral.helperText}
                                onSuggestions={(e) => {
                                  address[i] = address[i]
                                    ? { suggestion: e.suggestion }
                                    : { name: e.query };
                                  answerChanged(e.query, i, index, 'country');
                                }}
                                placeholder={text}
                              />
                            ) : template === 1 && arrType[type] === 'select' ? (
                              <Flex justifyContent="center" key={uid} pb={10}>
                                <DropdownList
                                  label={label}
                                  options={choicesSelectList}
                                  name={`input-answer-${uid}`}
                                  validatorRegister={register}
                                  validatorConditions={{ required: choice.required }}
                                  errorMessage={choiceGeneral.errorMessage}
                                  errors={errors}
                                  defaultOption={text}
                                  helperText={choiceGeneral.helperText}
                                  optionSelected={(list.length > 0 && list[index]) ? list[index].value : ''}
                                  onChange={e => dropdownChanged(e.target.value, i, index)}
                                />
                              </Flex>
                            ) : template === 1 ? (
                              arrType[type] === 'phone' ? (
                                <InputAnswer
                                  key={uid}
                                  label={label}
                                  helperText={choiceGeneral.helperText}
                                  name={uid}
                                  validatorRegister={register}
                                  validatorConditions={{ required: choice.required }}
                                  errorMessage={choiceGeneral.errorMessage}
                                  errors={errors}
                                  type={handleInputType(type)}
                                  placeholder={text}
                                  maxLength={choiceGeneral.maxLength}
                                  minLength={choiceGeneral.minLength}
                                  defaultCountry="us"
                                  onChange={(phone) => {
                                    phoneChanged(phone, i, index);
                                  }}
                                  value={value || dataQuestions.questions[i].choices[index].value}
                                  isPhone={arrType[type] === 'phone'}
                                  isDate={arrType[type] === 'date'}
                                  isNumber={arrType[type] === 'number'}
                                />
                              ) : (
                                <InputAnswer
                                  key={uid}
                                  label={label}
                                  helperText={choiceGeneral.helperText}
                                  name={uid}
                                  validatorRegister={register}
                                  validatorConditions={{ required: choice.required }}
                                  errorMessage={choiceGeneral.errorMessage}
                                  errors={errors}
                                  type={handleInputType(type)}
                                  placeholder={text}
                                  maxLength={choiceGeneral.maxLength}
                                  minLength={choiceGeneral.minLength}
                                  defaultCountry="us"
                                  onChange={e => {
                                    if (arrType[type] === 'date') {
                                      dateChanged(e, i, index);
                                      return;
                                    }
                                    answerChanged(e, i, index);
                                  }}
                                  value={watch(uid) === '' ? watch(uid) : watch(uid) || value}
                                  isPhone={arrType[type] === 'phone'}
                                  isDate={arrType[type] === 'date'}
                                  isNumber={arrType[type] === 'number'}
                                />
                              )
                            ) : template === 3 ? arrType[type] === 'phone' ? (
                              <ShortAnswer
                                key={question.uid}
                                label={label}
                                type={handleInputType(type)}
                                placeholder={text}
                                name={`short-answer-${question.uid}`}
                                validatorRegister={register}
                                validatorConditions={{ required: question.required }}
                                errorMessage={question.general.errorMessage}
                                errors={errors}
                                onChange={(phone) => {
                                  phoneChanged(phone, i, index);
                                }}
                                defaultCountry="us"
                                maxLength={choiceGeneral.maxLength}
                                minLength={choiceGeneral.minLength}
                                // defaultValue={value || dataSubGroup.items[i].choices[index].value}
                                value={value || dataQuestions.questions[i].choices[index].value}
                                isPhone={arrType[type] === 'phone'}
                                isNumber={arrType[type] === 'number'}
                                isDate={arrType[type] === 'date'}
                              />
                            ) : (
                              <ShortAnswer
                                key={uid}
                                label={label}
                                type={handleInputType(type)}
                                placeholder={text}
                                name={`short-answer-${question.uid}`}
                                validatorRegister={register}
                                validatorConditions={{ required: question.required }}
                                errorMessage={question.general.errorMessage}
                                errors={errors}
                                onChange={(e) => {
                                  if (arrType[type] === 'date') {
                                    dateChanged(e, i, index);
                                    return;
                                  }
                                  answerChanged(e, i, index);
                                }}
                                defaultCountry="us"
                                maxLength={choiceGeneral.maxLength}
                                minLength={choiceGeneral.minLength}
                                // defaultValue={value || dataSubGroup.items[i].choices[index].value}
                                value={value || dataQuestions.questions[i].choices[index].value}
                                isPhone={arrType[type] === 'phone'}
                                isNumber={arrType[type] === 'number'}
                                isDate={arrType[type] === 'date'} />
                            ) : template === 4 ? (
                              <LongAnswer
                                key={uid}
                                label={label}
                                type={handleInputType(type)}
                                name={`long-answer-${question.uid}`}
                                validatorRegister={register}
                                validatorConditions={{ required: question.required }}
                                errorMessage={question.general.errorMessage}
                                errors={errors}
                                maxLength={choiceGeneral.maxLength}
                                minLength={choiceGeneral.minLength}
                                placeholder={text}
                                onChange={e => answerChanged(e, i, index)}
                                defaultValue={value}
                              />
                            ) : template === 7 ? (
                              <SingleChoice
                                key={uid}
                                index={uid}
                                title={text}
                                onChange={e => radioChanged(e, i, index, true)}
                                value={text}
                                name={`single-choice-${question.uid}`}
                                validatorRegister={register}
                                validatorConditions={{ required: question.required }}
                                errors={errors}
                                helperText={choiceGeneral.helperText}
                                helperPosition="middle"
                                optionSelected={value}
                              />
                            ) : template === 8 ? (
                              <MultipleChoice
                                key={index}
                                label={text}
                                pb="12px"
                                className="multiple-choice"
                                name={`multiple-choice-${question.uid}-${index}`}
                                validatorRegister={register}
                                validatorConditions={{}}
                                errors={errors}
                                value={text}
                                onChange={e => checkboxChanged(e, i, index)}
                                helperText={choiceGeneral.helperText}
                                optionSelected={value}
                              />
                            ) : template === 9 ? (
                              <ButtonAnswer
                                key={uid}
                                index={uid}
                                option={text}
                                name={`button-answer-${question.uid}`}
                                validatorRegister={register}
                                validatorConditions={{ required: question.required }}
                                errors={errors}
                                onClick={e => radioChanged(e, i, index, true)}
                                value={text}
                                helperText={choiceGeneral.helperText}
                                optionSelected={value}
                              />
                            ) : (
                              template === 10 && isFirstField && (
                                <GroupedAnswer
                                  key={uid}
                                  index={uid}
                                  title={text}
                                  onChange={e => radioChanged(e, i, index)}
                                  name={`grouped-answers-${uid}`}
                                  validatorRegister={register}
                                  validatorConditions={{ required: choice.required }}
                                  errorMessage={choiceGeneral.errorMessage}
                                  errors={errors}
                                  helperText={choiceGeneral.helperText}
                                  optionSelected={value}
                                  required={required}
                                />
                              )
                            ))
                          );
                        })}
                        {template === 5 && (
                          <>
                            <InputAnswer
                              key={uid}
                              label="Street Number and Name"
                              type="search"
                              helperText={dataQuestions.questions[i].choices[0].general.helperText}
                              isAddress
                              defaultValue={dataQuestions.questions[i].choices[0].value}
                              onChange={(e) => {
                                if (e.suggestion.postcode) {
                                  e.suggestion.postcode = e.suggestion.postcode.replace(/\D+/g, '');
                                }

                                address[i] = { suggestion: e.suggestion };
                                setAddress(address);
                                addressChanged(e, i, 0);
                                setAddress([]);
                              }}
                              options={{
                                appId: 'plCZD21OAEFL',
                                apiKey: 'f47907e615cfbb4d0d7565be8e12546d',
                                type: 'address',
                                templates: {
                                  value: function(suggestion) {
                                    return suggestion.name;
                                  }
                                },
                              }}
                              name={`address-${dataQuestions.questions[i].choices[0].uid}`}
                              validatorRegister={register}
                              validatorConditions={{
                                required: dataQuestions.questions[i].choices[0].required,
                              }}
                              errorMessage={
                                dataQuestions.questions[i].choices[0].general.errorMessage
                              }
                              errors={errors}
                              onSuggestions={(e) => {
                                address[i] = address[i]
                                  ? { suggestion: e.suggestion }
                                  : { name: e.query };
                                setAddress(address);
                                addressChanged(e, i, 0);
                                setAddress([]);
                              }}
                              onClear={() => {
                                address[i] = { suggestion: {} };
                                setAddress(address);
                              }}
                              placeholder=""
                              className="address--no-autocomplete"
                            />
                            <InputGroup pt={2} pb={12} mb={10}>
                              <SingleChoice
                                name={`type_${dataQuestions.questions[i].uid}`}
                                value="Apartment"
                                title="Apartment"
                                onChange={(e) => {
                                  addressChanged(e, i, 1, 'radio');
                                }}
                                customClass="address-radio"
                                optionSelected={dataQuestions.questions[i].choices[1].value}
                                helperText={
                                  dataQuestions.questions[i].choices[1].general.helperText
                                }
                                helperPosition="end"
                              />
                              <SingleChoice
                                name={`type_${dataQuestions.questions[i].uid}`}
                                value="Suite"
                                title="Suite"
                                onChange={(e) => {
                                  addressChanged(e, i, 2, 'radio');
                                }}
                                helperText={
                                  dataQuestions.questions[i].choices[2].general.helperText
                                }
                                helperPosition="end"
                                customClass="address-radio"
                                optionSelected={dataQuestions.questions[i].choices[2].value}
                              />
                              <SingleChoice
                                name={`type_${dataQuestions.questions[i].uid}`}
                                value="Floor"
                                title="Floor"
                                customClass="address-radio"
                                onChange={(e) => {
                                  addressChanged(e, i, 3, 'radio');
                                }}
                                helperText={
                                  dataQuestions.questions[i].choices[3].general.helperText
                                }
                                helperPosition="end"
                                optionSelected={dataQuestions.questions[i].choices[3].value}
                              />
                            </InputGroup>
                            <InputAnswer
                              label="Unit"
                              type="text"
                              name={`address-${dataQuestions.questions[i].choices[4].uid}`}
                              validatorRegister={register}
                              validatorConditions={{
                                required: dataQuestions.questions[i].choices[4].required,
                              }}
                              errorMessage={
                                dataQuestions.questions[i].choices[4].general.errorMessage
                              }
                              errors={errors}
                              value={dataQuestions.questions[i].choices[4].value}
                              helperText={dataQuestions.questions[i].choices[4].general.helperText}
                              onChange={(e) => {
                                addressChanged(e, i, 4);
                              }}
                            />
                            <InputAnswer
                              label="City or Town"
                              type="text"
                              name={`address-${dataQuestions.questions[i].choices[5].uid}`}
                              validatorRegister={register}
                              validatorConditions={{
                                required: dataQuestions.questions[i].choices[5].required,
                              }}
                              errorMessage={
                                dataQuestions.questions[i].choices[5].general.errorMessage
                              }
                              errors={errors}
                              value={dataQuestions.questions[i].choices[5].value}
                              helperText={dataQuestions.questions[i].choices[5].general.helperText}
                              onChange={(e) => {
                                addressChanged(e, i, 5);
                              }}
                            />
                            <InputAnswer
                              label="County"
                              type="text"
                              name={`address-${dataQuestions.questions[i].choices[6].uid}`}
                              validatorRegister={register}
                              validatorConditions={{
                                required: dataQuestions.questions[i].choices[6].required,
                              }}
                              errorMessage={
                                dataQuestions.questions[i].choices[6].general.errorMessage
                              }
                              errors={errors}
                              value={dataQuestions.questions[i].choices[6].value}
                              helperText={dataQuestions.questions[i].choices[6].general.helperText}
                              onChange={(e) => {
                                addressChanged(e, i, 6);
                              }}
                            />
                            <InputAnswer
                              label="Province or Region"
                              type="text"
                              name={`address-${dataQuestions.questions[i].choices[7].uid}`}
                              validatorRegister={register}
                              validatorConditions={{
                                required: dataQuestions.questions[i].choices[7].required,
                              }}
                              errorMessage={
                                dataQuestions.questions[i].choices[7].general.errorMessage
                              }
                              errors={errors}
                              value={dataQuestions.questions[i].choices[7].value}
                              helperText={dataQuestions.questions[i].choices[7].general.helperText}
                              onChange={(e) => {
                                addressChanged(e, i, 7);
                              }}
                            />
                            <InputAnswer
                              label="Postal Code"
                              type="text"
                              name={`address-${dataQuestions.questions[i].choices[8].uid}`}
                              validatorRegister={register}
                              validatorConditions={{
                                required: dataQuestions.questions[i].choices[8].required,
                              }}
                              errorMessage={
                                dataQuestions.questions[i].choices[8].general.errorMessage
                              }
                              errors={errors}
                              value={dataQuestions.questions[i].choices[8].value}
                              helperText={dataQuestions.questions[i].choices[8].general.helperText}
                              onChange={(e) => {
                                addressChanged(e, i, 8);
                              }}
                            />
                            <InputAnswer
                              label="Country"
                              type="text"
                              name={`address-${dataQuestions.questions[i].choices[9].uid}`}
                              validatorRegister={register}
                              validatorConditions={{
                                required: dataQuestions.questions[i].choices[9].required,
                              }}
                              errorMessage={
                                dataQuestions.questions[i].choices[9].general.errorMessage
                              }
                              errors={errors}
                              value={dataQuestions.questions[i].choices[9].value}
                              helperText={dataQuestions.questions[i].choices[9].general.helperText}
                              onChange={(e) => {
                                addressChanged(e, i, 9);
                              }}
                            />
                          </>
                        )}
                        {template === 6 && (
                          <>
                            <InputAnswer
                              key={uid}
                              label="Street Number and Name"
                              type="search"
                              name={uid}
                              validatorRegister={register}
                              validatorConditions={{
                                required: dataQuestions.questions[i].choices[0].required,
                              }}
                              errorMessage={
                                dataQuestions.questions[i].choices[0].general.errorMessage
                              }
                              errors={errors}
                              isAddress
                              defaultValue={dataQuestions.questions[i].choices[0].value}
                              helperText={dataQuestions.questions[i].choices[0].general.helperText}
                              onChange={(e) => {
                                if (e.suggestion.postcode) {
                                  e.suggestion.postcode = e.suggestion.postcode.replace(/\D+/g, '');
                                }

                                usAddress[i] = { suggestion: e.suggestion };
                                setUsAddress(usAddress);
                                addressChanged(e, i, 0);
                                setUsAddress([]);
                              }}
                              options={{
                                appId: 'plCZD21OAEFL',
                                apiKey: 'f47907e615cfbb4d0d7565be8e12546d',
                                countries: ['us'],
                                type: 'address',
                                templates: {
                                  value: function(suggestion) {
                                    return suggestion.name;
                                  }
                                },
                              }}
                              onSuggestions={(e) => {
                                usAddress[i] = usAddress[i]
                                  ? { suggestion: e.suggestion }
                                  : { name: e.query };
                                setUsAddress(usAddress);
                                addressChanged(e, i, 0);
                                setUsAddress([]);
                              }}
                              onClear={() => {
                                usAddress[i] = { suggestion: {} };
                                setUsAddress(usAddress);
                              }}
                              placeholder=""
                              className="address--no-autocomplete"
                            />
                            <InputGroup pt={2} pb={12} mb={10}>
                              <SingleChoice
                                name={`type_${dataQuestions.questions[i].uid}`}
                                value="apartment"
                                title="Apartment"
                                helperText={
                                  dataQuestions.questions[i].choices[1].general.helperText
                                }
                                onChange={(e) => {
                                  addressChanged(e, i, 1, 'radio');
                                }}
                                customClass="address-radio"
                                optionSelected={dataQuestions.questions[i].choices[1].value}
                                helperPosition="end"
                              />
                              <SingleChoice
                                name={`type_${dataQuestions.questions[i].uid}`}
                                value="suite"
                                title="Suite"
                                helperText={
                                  dataQuestions.questions[i].choices[2].general.helperText
                                }
                                helperPosition="end"
                                onChange={(e) => {
                                  addressChanged(e, i, 2, 'radio');
                                }}
                                customClass="address-radio"
                                optionSelected={dataQuestions.questions[i].choices[2].value}
                              />
                              <SingleChoice
                                name={`type_${dataQuestions.questions[i].uid}`}
                                value="floor"
                                helperText={
                                  dataQuestions.questions[i].choices[3].general.helperText
                                }
                                helperPosition="end"
                                title="Floor"
                                onChange={(e) => {
                                  addressChanged(e, i, 3, 'radio');
                                }}
                                customClass="address-radio"
                                optionSelected={dataQuestions.questions[i].choices[3].value}
                              />
                            </InputGroup>

                            <InputAnswer
                              label="Unit"
                              type="text"
                              name={uid}
                              validatorRegister={register}
                              validatorConditions={{
                                required: dataQuestions.questions[i].choices[4].required,
                              }}
                              errorMessage={
                                dataQuestions.questions[i].choices[4].general.errorMessage
                              }
                              errors={errors}
                              value={dataQuestions.questions[i].choices[4].value}
                              helperText={dataQuestions.questions[i].choices[4].general.helperText}
                              onChange={(e) => {
                                addressChanged(e, i, 4);
                              }}
                            />
                            <InputAnswer
                              label="City or Town"
                              type="text"
                              name={`address-${dataQuestions.questions[i].choices[5].uid}`}
                              validatorRegister={register}
                              validatorConditions={{
                                required: dataQuestions.questions[i].choices[5].required,
                              }}
                              errorMessage={
                                dataQuestions.questions[i].choices[5].general.errorMessage
                              }
                              errors={errors}
                              value={dataQuestions.questions[i].choices[5].value}
                              helperText={dataQuestions.questions[i].choices[5].general.helperText}
                              onChange={(e) => {
                                addressChanged(e, i, 5);
                              }}
                            />

                            <InputAnswer
                              label="County"
                              type="text"
                              name={`address-${dataQuestions.questions[i].choices[6].uid}`}
                              validatorRegister={register}
                              validatorConditions={{
                                required: dataQuestions.questions[i].choices[6].required,
                              }}
                              errorMessage={
                                dataQuestions.questions[i].choices[6].general.errorMessage
                              }
                              errors={errors}
                              value={dataQuestions.questions[i].choices[6].value}
                              helperText={dataQuestions.questions[i].choices[6].general.helperText}
                              onChange={(e) => {
                                addressChanged(e, i, 6);
                              }}
                            />
                            <InputAnswer
                              label="State"
                              type="text"
                              name={`state-${dataQuestions.questions[i].choices[7].uid}`}
                              validatorRegister={register}
                              validatorConditions={{
                                required: dataQuestions.questions[i].choices[7].required,
                              }}
                              errorMessage={
                                dataQuestions.questions[i].choices[7].general.errorMessage
                              }
                              errors={errors}
                              value={dataQuestions.questions[i].choices[7].value}
                              helperText={dataQuestions.questions[i].choices[7].general.helperText}
                              onChange={(e) => {
                                addressChanged(e, i, 7);
                              }}
                              isUsState
                            />
                            <Container
                              name={`address-${dataQuestions.questions[i].choices[9].uid}`}
                              errors={errors}
                            >
                              <Flex
                                width={[1, '151px']}
                                justifyContent="space-between"
                                marginRight="auto"
                              >
                                <Label>
                                  Zip Code +4
                                  {dataQuestions.questions[i].choices[9].general.helperText && (
                                    <HelperText
                                      customClass="helper-text-container"
                                      text={
                                        dataQuestions.questions[i].choices[9].general.helperText
                                      }
                                    />
                                  )}
                                </Label>
                                <Border />
                              </Flex>
                              <Flex
                                alignItems="center"
                                ml={[0, 0, 11]}
                                width={['auto', '100%', 'auto']}
                              >
                                <FormInputYellow
                                  ref={ref => (zipFirstInputRef[i] = ref)}
                                  type="text"
                                  value={
                                    dataQuestions.questions[i].choices[8].value
                                    && dataQuestions.questions[i].choices[8].value
                                      .replace(/\D+/g, '')
                                      .slice(0, 5)
                                  }
                                  onChange={e => zipChanged(e, i, 8, 'first')}
                                  // maxLength="5"
                                />
                                <Divider />
                                <FormInputYellowSmall
                                  type="text"
                                  name={`address-${dataQuestions.questions[i].choices[9].uid}`}
                                  ref={(inputRef) => {
                                    register(inputRef && inputRef, {
                                      required: dataQuestions.questions[i].choices[9].required,
                                    });
                                    zipSecondInputRef[i] = inputRef;
                                  }}
                                  value={dataQuestions.questions[i].choices[9].value}
                                  onChange={e => zipChanged(e, i, 9, 'second')}
                                  maxLength="4"
                                />
                              </Flex>
                              {errors
                                && errors[`address-${dataQuestions.questions[i].choices[9].uid}`] && (
                                  <ErrorMessage className="error-message">
                                    {`${errorMessage || 'This field is required'}`}
                                  </ErrorMessage>
                              )}
                            </Container>
                          </>
                        )}
                      </CustomFlex>
                    </>
                  )}
                </Box>
              )
            );
          })}
          <Box width="100%" className='buttons-container'>
            <Flex justifyContent="space-between">
              <Button
                value="Go Back"
                onClick={() => {
                  setAddress([]);
                  setUsAddress([]);
                  // dispatch({type: 'setGoBack', goBack: })
                  history.goBack();
                }}
                extraClass="secondary"
                arrowLeft
                margin="0"
              />
              <Button
                value="Continue"
                type="submit"
                onClick={() => {
                  setTimeout(() => {
                    validateMultipleChoice();
                  });
                }}
                arrowRight
                margin="0"
                extraClass={`${isLastSubGroup && isPreview && '--disabled'}`}
              />
            </Flex>
            <Flex mt={40}>{errorMessage && <Error>{errorMessage}</Error>}</Flex>
          </Box>
        </form>
      </ContainerApp>
    </>
  ) : (
    ''
  );
};

export default SubGroupPage;
