import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/atoms/Icon';
import { host } from 'modules/utils';
import { Container, ContainerIcon, Title } from './style';

const PageTitle = (props) => {
  const {
    title, icon, iconWidth, iconHeight, isImage,
  } = props;

  return (
    <Container>
      {icon && (
        <ContainerIcon>
          {isImage ? (
            <img
              src={`${host().admin}/uploads/groups/${icon}`}
              width={50}
              height={50}
              alt={title}
            />
          ) : (
            <Icon name={icon} width={iconWidth || 60} height={iconHeight || 60} />
          )}
        </ContainerIcon>
      )}
      <Title>{title}</Title>
    </Container>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
};

export default PageTitle;
