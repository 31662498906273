import gql from 'graphql-tag';

export const SIGN_UP = gql`
  mutation signup($user: SignupInput!) {
    signup(user: $user)
  }
`;

export const SIGN_IN = gql`
  mutation login($credentials: LoginInput!) {
    login(credentials: $credentials)
  }
`;

export const REQUEST_PASSWORD = gql`
  mutation login($email: String!) {
    requestResetPassword(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($resetToken: String!, $password: String!) {
    resetPassword(resetToken: $resetToken, password: $password)
  }
`;

export const ACTIVATE_ACCOUNT = gql`
  mutation activateAccount($activateAccountToken: String!) {
    activateAccount(activateAccountToken: $activateAccountToken) {
      name
      email
      active
    }
  }
`;

export const RESEND_TOKEN = gql`
  mutation resendActivateAccountToken {
    resendActivateAccountToken
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $user: UserUpdateInput!) {
    updateUser(id: $id, user: $user) {
      name
      email
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($user: UserChangePasswordInput) {
    updatePassword(user: $user)
  }
`;
