import React, { useState, useEffect } from 'react';

import { useQuery, useMutation } from 'react-apollo-hooks';
import { GET_ME } from 'graphql/user/queries';
import { ACTIVATE_ACCOUNT, RESEND_TOKEN } from 'graphql/user/mutations';
import {
  Container,
  Title,
  SubTitle,
  Description,
  Email,
  CodeContainer,
  CodeItem,
  CodeInput,
  SmallText,
} from './style';

const ActivationCode = () => {
  const [codes, setCodes] = useState({
    code1: '',
    code2: '',
    code3: '',
    code4: '',
  });
  const [activate, setActivate] = useState(false);
  const [justResend, setJustResend] = useState(false);

  const { data } = useQuery(GET_ME);

  const [activateAccount, { error }] = useMutation(ACTIVATE_ACCOUNT, {
    update: (cache, { data }) => {
      setActivate(true);
    },
  });

  const [resendActivateAccountToken] = useMutation(RESEND_TOKEN, {
    update: (cache, { data }) => {
      setJustResend(true);
      setTimeout(() => setJustResend(false), 50000);
    },
  });

  function changeCode(key, target) {
    if (!target.value) return false;

    const value = target.value.split('').pop();
    const keyNumber = key.replace('code', '');
    const elements = document.querySelectorAll('.codeNumber');

    setCodes(prevState => ({ ...prevState, [key]: value }));

    if (keyNumber < elements.length) {
      elements[keyNumber].focus();
    }
  }

  function resendCode() {
    setCodes({
      code1: '',
      code2: '',
      code3: '',
      code4: '',
    });
    resendActivateAccountToken();
  }

  useEffect(() => {
    const {
      code1, code2, code3, code4,
    } = codes;

    if (code1 && code2 && code3 && code4) {
      const fullCode = `${code1}${code2}${code3}${code4}`;
      activateAccount({ variables: { activateAccountToken: fullCode } });
    }
  }, [codes, activateAccount]);

  useEffect(() => {
    if (error) {
      setCodes({
        code1: '',
        code2: '',
        code3: '',
        code4: '',
      });
      document.querySelector('.codeNumber').focus();
    }

    if (!error) {
      if (activate || (data && data.me.active)) {
        window.location.href = '/your-account';
      }
    }
  }, [error, activate, data]);

  return (
    <Container>
      {error && (
        <SubTitle>
          The code you entered is expired or does not match the code we sent you. Please check the
          code in your email and try again.
        </SubTitle>
      )}

      {!error && (
        <>
          {justResend ? (
            <Title>Your new code has been sent.</Title>
          ) : (
            <Title>Great, let's activate your account.</Title>
          )}
          <Description>
            Please check your email for your 4-digit code. Your account activation code has been
            sent to:
          </Description>
          {data && data.me && <Email>{data.me.email}</Email>}
        </>
      )}

      <CodeContainer>
        <CodeItem>
          <CodeInput
            type="text"
            className="codeNumber"
            value={codes.code1}
            onChange={({ target }) => changeCode('code1', target)}
          />
        </CodeItem>
        <CodeItem>
          <CodeInput
            type="text"
            className="codeNumber"
            value={codes.code2}
            onChange={({ target }) => changeCode('code2', target)}
          />
        </CodeItem>
        <CodeItem>
          <CodeInput
            type="text"
            className="codeNumber"
            value={codes.code3}
            onChange={({ target }) => changeCode('code3', target)}
          />
        </CodeItem>
        <CodeItem>
          <CodeInput
            type="text"
            className="codeNumber"
            value={codes.code4}
            onChange={({ target }) => changeCode('code4', target)}
          />
        </CodeItem>
      </CodeContainer>

      <SmallText>
        Your activation link may take up to 5 minutes to arrive. Still not seeing your activation
        linkin your inbox? Try checking your Spam and Junk folders.
      </SmallText>

      {!justResend && (
        <SmallText>
          Didn’t get the email?
          {' '}
          <span onClick={resendCode}>Resend it by clicking here</span>
        </SmallText>
      )}
    </Container>
  );
};

export default ActivationCode;
