import styled from "styled-components";
import colors from "modules/colors";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid ${() => colors.grey};
  margin-bottom: 23px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: ${() => colors.blueDark};
  width: 114px;
  padding: 0 9px;
  border-right: 1px solid ${() => colors.blueDark};
`;

export const Info = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: ${() => colors.black};
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap; 
  overflow: hidden;
  padding: 0 9px;
`;
