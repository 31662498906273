import styled from 'styled-components';
import colors from 'modules/colors';

export const Navbar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 70px;
  padding: 0 20px;
  color: ${() => colors.white};
  background-color: ${() => colors.blueAliro};
`;

export const Link = styled.a`
  color: #fff;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
`;

export const Container = styled.span`
  position: relative;
  display: flex;
  align-items: center;
`;

export const TextLink = styled(Link)`
  display: inline-block;
  margin-right: 13px;
  font-size: 13px;
  font-weight: 500;
  color: ${() => colors.white};
  text-align: left;
  line-height: 70px;
`;