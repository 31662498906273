import React from 'react';
import Icon from 'components/atoms/Icon';
import { Container, HiddenInput, IconContainer } from './style';

const CustomCheckbox = (props) => {
  const {
    checked, validatorRegister, validatorConditions, name, errors,
  } = props;
  let finalName = name;

  if (finalName && finalName.includes('multiple-choice-')) {
    const splitName = finalName.split('-');
    finalName = `${splitName[0]}-${splitName[1]}-${splitName[2]}-0`;
  }

  return (
    <Container>
      <HiddenInput {...props} ref={validatorRegister && validatorRegister(validatorConditions)} />
      <IconContainer checked={checked} name={finalName} errors={errors}>
        <Icon width={8} height={10} name="checkbox" className="icon-checkbox" />
      </IconContainer>
    </Container>
  );
};

export default CustomCheckbox;
