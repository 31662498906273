import styled from 'styled-components';
import colors from 'modules/colors';
import { Link } from 'react-router-dom';
import { Flex } from '@rebass/grid';

// import Icon from "components/atoms/Icon";

export const ModalBlue = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${() => colors.white};
  background-color: ${() => colors.blueAliro};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  text-align: center;
  width: 90%;
  max-width: 560px;

  @media (max-width: 51.99em) {
    &.modal--sign {
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      transform: none;
      margin-bottom: 0;
    }

    .modal__h100 {
      height: 100%;
    }
  }

  @media (max-height: 39.99em) {
    transform: none;
    top: 5%;
    left: 5%;
    margin-bottom: 5%;
    height: auto !important;
    min-height: 100%;
  }
`;

export const ModalBlueTitle = styled.p`
  font-family: Open Sans;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 37px;
    margin-bottom: 20px;
  }
`;

export const ModalBlueSubtitle = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 20px;
  }
`;

export const ModalBlueSubtitleLink = styled.a`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 55px;
  margin-top: 18px;
  color: ${() => colors.blackLight};

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 20px;
  }
`;

export const Button = styled.button`
  padding: 9px 28.5px;
  background-color: ${() => colors.yellow};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: ${() => colors.black};
  border: none;
  margin-top: 50px;
  transition: all 0.4s;

  &:hover {
    box-shadow: none;
  }
`;

export const ModalWhite = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${() => colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  text-align: center;
  width: 90%;
  max-width: 580px;

  @media (max-height: 780px) {
    transform: none;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 5%;
  }
`;

export const ModalWhiteName = styled.p`
  width: 100%;
  max-width: 290px;
  border-bottom: 1px solid ${() => colors.blueLight};
  color: ${() => colors.blueAliro};
  padding-bottom: 11.5px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
`;

export const ModalWhiteTitle = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 3px;
  color: ${() => colors.blueAliro};
`;

export const ModalWhiteDesc = styled.p`
  font-size: 16px;
  line-height: 20px;
`;

export const Pointer = styled.div`
  cursor: pointer;
`;

export const CloseSign = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 10px;
`;

export const Form = styled.form`
  margin-top: 29px;
`;

export const FormGroup = styled.div`
  position: relative;
  margin-bottom: 12px;
`;

export const Input = styled.input`
  font-family: Helvetica, sans-serif;
  padding: 6.5px 52.5px;
  border: 1px solid ${() => colors.grey};
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  color: ${() => colors.greyDark};
`;

export const Key = styled.div`
  position: absolute;
  left: 11.5px;
  top: 5px;
`;

export const Email = styled(Key)`
  top: 7.5px;
`;

export const Eye = styled(Pointer)`
  position: absolute;
  right: 11.5px;
  top: 5.5px;
`;

export const TextLink = styled(Link)`
  text-align: right;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: ${() => colors.blue};
  display: inherit;
`;

export const ModalWhiteButton = styled(Button)`
  padding-left: 10px;
  padding-right: 10px;
  width: 153px;
  margin-top: 37px;
  color: ${() => colors.blackLight};
`;

export const ButtonAttorney = styled(ModalWhiteButton)`
  margin-top: 18px;
`;

export const ModalWhiteElegibleTitle = styled.p`
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  color: ${() => colors.blueAliro};
  margin-bottom: 30px;

  @media (max-width: 39.99em) {
    font-size: 24px;
    line-height: 31px;
  }
`;

export const Flag = styled.div`
  svg {
    border-radius: 100%;
    overflow: hidden;
  }
`;

export const ModalWhiteElegibleDesc = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.blueAliro};
  margin-top: 30px;
`;

export const ModalWhiteNotElegibleTitle = styled.p`
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: ${() => colors.blueAliro};
  margin-bottom: 11px;

  @media (max-width: 39.99em) {
    font-size: 26px;
    line-height: 38px;
  }
`;

export const ModalWhiteNotElegibleDesc = styled.p`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${() => colors.blackLight};

  @media (max-width: 39.99em) {
    font-size: 15px;
    line-height: 19px;
  }
`;

export const ModalWhiteNotElegibleBox = styled(Flex)`
  border: 1px solid rgba(27, 27, 27, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 42px 25px 30px;

  @media (max-width: 39.99em) {
    flex-direction: column;
  }
`;

export const ModalWhiteNotElegibleBoxDesc = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${() => colors.blackLight};
  margin-bottom: 2px;
`;

export const Label = styled.label`
  font-size: 18px;
  line-height: 21px;
  color: ${() => colors.dark};
  padding: 11px 0;
  text-align: left;
`;

export const InputContact = styled.input`
  border: 1px solid rgba(84, 84, 84, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 14px 31px;
  font-size: 18px;
  line-height: 22px;
  color: ${() => colors.darkLight};
  margin-bottom: 13px;
`;

export const TextareaContact = styled.textarea`
  resize: none;
  height: 187px;
  border: 1px solid rgba(84, 84, 84, 0.2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 14px 31px;
  font-size: 18px;
  line-height: 22px;
  color: ${() => colors.darkLight};
`;

export const ModalWhiteButtonContact = styled(ModalWhiteButton)`
  background: ${() => colors.yellow};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:disabled {
    background-color: ${() => colors.grey};
    box-shadow: none;
  }
`;

export const ModalWhiteTextContact = styled.p`
  color: ${() => colors.black};
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;

export const ButtonWhite = styled.button`
  border: 1px solid ${() => colors.blueDark};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${() => colors.darkLight};
  padding: 10px;
  width: 153px;
  margin-top: 31px;
  margin-bottom: 27px;
`;

export const ModalWhiteAttorneyDesc = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${() => colors.blueDark};
`;

export const ModalWhiteAttorneyBox = styled(ModalWhiteNotElegibleBox)`
  padding: 30px 25px;
`;

export const ModalWhiteButtonResume = styled(ModalWhiteButton)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 0;
  margin-bottom: 20px;

  &:disabled {
    background-color: ${() => colors.grey};
    cursor: default;
  }
`;

export const ModalWhiteTitleBorder = styled(ModalBlueSubtitle)`
  border-bottom: 2px solid ${() => colors.blueLight};
  padding: 0 0 9px;
`;

export const Cancel = styled.button`
  background-color: ${() => colors.white};
  border: 1px solid ${() => colors.grey};
  border-radius: 5px;
  width: 49%;
  max-width: 176px;
  padding: 10.5px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${() => colors.blueDark};
  font-weight: 600;
`;

export const Update = styled(Cancel)`
  background-color: ${() => colors.blueAliro};
  border: none;
  color: ${() => colors.white};
`;

export const ModalWhiteDescPacket = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin: 53px auto 19px;
`;

export const ModalWhiteEmail = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${() => colors.blueAliro};
  margin-bottom: 35px;
`;

export const ModalWhiteDescDownload = styled(ModalWhiteDescPacket)`
  margin: 32px auto;
`;

export const ButtonDownload = styled.a`
  background-color: ${() => colors.yellow};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: ${() => colors.fontColor};
  display: inline-block;
  width: 153px;
  padding: 10px;
  margin-top: 31px;
`;
