import React from 'react';
import NewPassword from 'components/organisms/NewPassword';
import { Flex } from '@rebass/grid';
import { Container, ContainerBox } from './style';

const LoginRegister = () => (
  <Container>
    <Flex>
      <ContainerBox>
        <NewPassword />
      </ContainerBox>
    </Flex>
  </Container>
);

export default LoginRegister;
