import { normalize } from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';

const globalStyle = createGlobalStyle`
  ${normalize}

  @font-face {
    src: url('../static/fonts/montserrat/montserrat-bold.eot');
    src: local('Montserrat Bold'), local('montserrat-bold'),
        url('../static/fonts/montserrat/montserrat-bold.eot?#iefix') format('embedded-opentype'),
        url('../static/fonts/montserrat/montserrat-bold.woff2') format('woff2'),
        url('../static/fonts/montserrat/montserrat-bold.woff') format('woff'),
        url('../static/fonts/montserrat/montserrat-bold.ttf') format('truetype'),
        url('../static/fonts/montserrat/montserrat-bold.svg#montserrat-bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-family: 'Montserrat';
  }
  @font-face {
    src: url('../static/fonts/montserrat/montserrat-semiBold.eot');
    src: local('Montserrat SemiBold'), local('montserrat-semiBold'),
        url('../static/fonts/montserrat/montserrat-semiBold.eot?#iefix') format('embedded-opentype'),
        url('../static/fonts/montserrat/montserrat-semiBold.woff2') format('woff2'),
        url('../static/fonts/montserrat/montserrat-semiBold.woff') format('woff'),
        url('../static/fonts/montserrat/montserrat-semiBold.ttf') format('truetype'),
        url('../static/fonts/montserrat/montserrat-semiBold.svg#montserrat-semiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-family: 'Montserrat';
  }
  @font-face {
    src: url('../static/fonts/montserrat/montserrat-medium.eot');
    src: local('Montserrat Medium'), local('montserrat-medium'),
        url('../static/fonts/montserrat/montserrat-medium.eot?#iefix') format('embedded-opentype'),
        url('../static/fonts/montserrat/montserrat-medium.woff2') format('woff2'),
        url('../static/fonts/montserrat/montserrat-medium.woff') format('woff'),
        url('../static/fonts/montserrat/montserrat-medium.ttf') format('truetype'),
        url('../static/fonts/montserrat/montserrat-medium.svg#montserrat-medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-family: 'Montserrat';
  }
  @font-face {
    src: url('${'/static/fonts/montserrat/montserrat-regular.eot'}');
    src: local('Montserrat Regular'), local('montserrat-regular'),
        url('${'/static/fonts/montserrat/montserrat-regular.eot?#iefix'}') format('embedded-opentype'),
        url('${'/static/fonts/montserrat/montserrat-regular.woff2'}') format('woff2'),
        url('${'/static/fonts/montserrat/montserrat-regular.woff'}') format('woff'),
        url('${'/static/fonts/montserrat/montserrat-regular.ttf'}') format('truetype'),
        url('${'/static/fonts/montserrat/montserrat-regular.svg#montserrat-regular'}') format('svg');
    font-weight: 400;
    font-style: normal;
    font-family: 'Montserrat';
  }

  @font-face {
    src: url('../static/fonts/opensans/opensans-bold.eot');
    src: local('OpenSans Bold'), local('OpenSans'),
        url('../static/fonts/opensans/opensans-bold.eot?#iefix') format('embedded-opentype'),
        url('../static/fonts/opensans/opensans-bold.woff2') format('woff2'),
        url('../static/fonts/opensans/opensans-bold.woff') format('woff'),
        url('../static/fonts/opensans/opensans-bold.ttf') format('truetype'),
        url('../static/fonts/opensans/opensans-bold.svg#OpenSans') format('svg');
    font-weight: 700;
    font-style: normal;
    font-family: 'OpenSans';
  }

  * {
    box-sizing: border-box;
    outline: none;
  }

  html, body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }

  #root {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  button {
    border: none;
    cursor: pointer;
    outline: none;
    transition: background .4s, box-shadow .4s;

    &:hover {
      box-shadow: none;
    }
  }

  p {
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  input[type="search"] {
    appearance: none;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      appearance: none;
    }
  }

  img {
    object-fit: contain;
  }
  
  .h100 {
    height: 100%;
  }

  @media (max-width: 39.99em) {
    .hideLogin {
      display: none;
    }

    .showLogin {
      display: block;
    }
  }
`;

export default globalStyle;
